<section>
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="card mt-4">
        <div class="card-header bottom-shadow-light">
          <div class="bar-success">
            <h4 class="card-title mb-3 ng-star-inserted">Change Password</h4>
          </div>
        </div>
        <div class="card-body mt-1">
          <form class="form" novalidate [formGroup]="changePasswordForm">
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label for="username">Username</label>
                  <input class="form-control" id="username" type="text" formControlName="username">
                  <small *ngIf="submitted && changePasswordForm.get('username').hasError('required')"
                         class="text-danger">
                    Field is required.
                  </small>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label for="fullName">Full Name</label>
                  <input class="form-control" id="fullName" type="text" formControlName="fullName">
                  <small *ngIf="submitted && changePasswordForm.get('fullName').hasError('required')"
                         class="text-danger">
                    Field is required.
                  </small>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label for="old_password">Old Password</label>
                  <input class="form-control" id="old_password" type="password" formControlName="old_password">
                  <small *ngIf="submitted && changePasswordForm.get('old_password').hasError('required')"
                         class="text-danger">
                    Field is required.
                  </small>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label for="password">New Password</label>
                  <input class="form-control" id="password" type="password" formControlName="password">
                  <small *ngIf="submitted && changePasswordForm.get('password').hasError('required')"
                         class="text-danger">
                    Field is required.
                  </small>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label for="confirm_password">Confirm Password</label>
                  <input class="form-control" id="confirm_password" type="password" formControlName="confirm_password">
                  <small *ngIf="submitted && changePasswordForm.get('confirm_password').hasError('required')"
                         class="text-danger">
                    Field is required.
                  </small>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="card-footer upper-shadow-light pb-3px">
          <button class="btn btn-danger color-white mb-2" (click)="back()">
            <i class="fas fa-window-close mr-1"></i>Cancel
          </button>
          <button class="btn btn-success color-white mb-2 pull-right" [disabled]="submitting" (click)="update()">
            <i class="fas fa-save mr-1" *ngIf="!submitting"></i>
            <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>Update
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
