import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {OfficialAnnouncementsService} from '../../shared/services/official-announcements.service';

@Component({
  selector: 'app-official-announcements',
  templateUrl: './official-announcements.component.html',
  styleUrls: ['./official-announcements.component.scss']
})
export class OfficialAnnouncementsComponent implements OnInit {
  submitting = false;
  officialAnnouncements = [];

  constructor(public officialAnnouncementsService: OfficialAnnouncementsService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    this.get();
  }

  get(params: any = {}) {
    this.submitting = true;
    this.officialAnnouncements = [];
    this.officialAnnouncementsService.get(params).subscribe(data => {
      if (data && data.length > 0) {
        this.officialAnnouncements = data;
        this.triggerScroll();
      } else {
        this.officialAnnouncements = [];
      }
      this.submitting = false;
    }, error => {
      this.submitting = false;
    });
  }

  triggerScroll() {
    window.scrollTo(500, 500);
    window.scrollTo(500, 0);
  }

  navigateTo(data) {
    let url = 'users/official-announcement/' + data.id;
    this.router.navigateByUrl(url);
    //window.open(data['receipt_url'], '_blank');
  }
}
