import {Component, OnInit} from '@angular/core';
import {DirectReferralsService} from '../../../shared/services/direct-referrals.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';

@Component({
  selector: 'app-direct-referrals',
  templateUrl: './direct-referrals.component.html',
  styleUrls: ['./direct-referrals.component.scss']
})
export class DirectReferralsComponent implements OnInit {
  submitting = false;
  directReferrals = [];
  showSearch: any = false;
  searchForm: FormGroup;
  bsConfig: Partial<BsDatepickerConfig>;
  pagination = {
    page: 1,
    total: null,
    perpage: 15,
    pages: null
  };

  constructor(public directReferralsService: DirectReferralsService,
              private fb: FormBuilder) {
    this.bsConfig = Object.assign({}, {containerClass: 'theme-dark-blue', dateInputFormat: 'YYYY-MM-DD'}, {showWeekNumbers: false});
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    this.searchForm = this.fb.group({
      'user_id': [''],
      'username': [''],
      'package': [''],
      'registration_date': ['']
    });
    this.get();
  }

  get(params: any = {}) {
    params.page = this.pagination.page;
    params.perpage = this.pagination.perpage;
    this.submitting = true;
    this.directReferrals = [];
    this.directReferralsService.get(params).subscribe(data => {
      if (data && data['data'] && data['data'].length > 0) {
        this.directReferrals = data['data'];
        this.pagination = {
          page: data['pagination'].page,
          total: data['pagination'].total,
          perpage: data['pagination'].perpage,
          pages: data['pagination'].pages,
        };
        this.triggerScroll();
      } else {
        this.directReferrals = [];
      }
      this.submitting = false;
    }, error => {
      this.submitting = false;
    });
  }

  triggerScroll() {
    window.scrollTo(500, 500);
    window.scrollTo(500, 0);
  }

  onPageChange(page) {
    this.pagination.page = page;
    this.get();
  }

  search() {
    this.pagination = {
      page: 1,
      total: null,
      perpage: 15,
      pages: null
    };
    let params = {
      'user_id': (this.searchForm && this.searchForm.value) ? this.searchForm.value.user_id : '',
      'username': (this.searchForm && this.searchForm.value) ? this.searchForm.value.username : '',
      'package': (this.searchForm && this.searchForm.value) ? this.searchForm.value.package : '',
      'registration_date': (this.searchForm && this.searchForm.value) ? moment(this.searchForm.value.registration_date).format('YYYY-MM-DD') : ''
    };
    this.get(params);
  }
}
