<section>
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="card mt-4">
        <div class="card-header bottom-shadow-light right-shadow-light">
          <div class="bar-success">
            <h4 class="card-title mb-3 ng-star-inserted">KYC Documents</h4>
          </div>
        </div>
        <div class="card-body mt-1">
          <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <div class="row mb-3 bottom-shadow-light">
                <div class="col-12">
                  <h5>Manage PAN Details</h5>
                </div>
              </div>
              <form class="row form" novalidate [formGroup]="panCardForm"
                    [ngClass]="{'display-hidden': pan && pan['pancard_number']}">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <h6 class="mt-2">PAN</h6>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <input class="form-control" id="pan" type="text" formControlName="pan"/>
                  <small *ngIf="submitted && panCardForm.get('pan').hasError('required')"
                         class="text-danger">
                    Field is required.
                  </small>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <h6 class="mt-3">Upload PAN</h6>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 overflow-hidden">
                  <button class="btn btn-primary color-white mt-2" [disabled]="submitting" (click)="panUpload.click()">
                    <i class="fas fa-save mr-1" *ngIf="!submitting"></i>
                    <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>Update Image
                  </button>
                  <small>{{panFileName}}</small>
                  <input class="form-control display-hidden" id="panFile" type="file"
                         (change)="onFileChange('PAN', $event)" #panUpload>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <button class="btn btn-primary pull-right" (click)="submitPan()">Submit</button>
                </div>
              </form>
              <div class="row mt-2">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <h6>PAN</h6>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <h6>{{pan?.pancard_number}}</h6>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <h6 class="mt-2">PAN Image</h6>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <button class="btn btn-primary bg-light-primary" (click)="view(pan?.pancard_image)"
                          *ngIf="pan && pan['pancard_image']">
                    View
                  </button>
                  <button class="btn btn-secondary bg-light-primary disabled" *ngIf="!pan || !pan['pancard_image']">
                    View
                  </button>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <h6>Self Approval</h6>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <h6>{{((pan && (pan.id_checkbox == false)) || !pan) ? 'Not Approved' : 'Approved'}}</h6>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <h6>Admin Approval</h6>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <h6>{{(pan && pan['admin_remark'] == 'CLEAR') ? 'Approved' : 'Not Approved'}}</h6>
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <form class="row form" novalidate [formGroup]="bankDetailsForm"
                    [ngClass]="{'display-hidden': bank && bank['account_name']}">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <h6 class="mt-2">Account Name</h6>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <input class="form-control" id="accountName" type="text" formControlName="accountName"/>
                  <small *ngIf="submitted && bankDetailsForm.get('accountName').hasError('required')"
                         class="text-danger">
                    Field is required.
                  </small>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <h6 class="mt-2">Account Number</h6>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <input class="form-control" id="accountNumber" type="text" formControlName="accountNumber"/>
                  <small *ngIf="submitted && bankDetailsForm.get('accountNumber').hasError('required')"
                         class="text-danger">
                    Field is required.
                  </small>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <h6 class="mt-2">Bank</h6>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <input class="form-control" id="bankName" type="text" formControlName="bankName"/>
                  <small *ngIf="submitted && bankDetailsForm.get('bankName').hasError('required')"
                         class="text-danger">
                    Field is required.
                  </small>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <h6 class="mt-2">IFSC / Swift Code</h6>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <input class="form-control" id="swiftCode" type="text" formControlName="swiftCode"/>
                  <small *ngIf="submitted && bankDetailsForm.get('swiftCode').hasError('required')"
                         class="text-danger">
                    Field is required.
                  </small>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <h6 class="mt-2">Branch Name</h6>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <input class="form-control" id="branchName" type="text" formControlName="branchName"/>
                  <small *ngIf="submitted && bankDetailsForm.get('branchName').hasError('required')"
                         class="text-danger">
                    Field is required.
                  </small>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <h6 class="mt-3">Upload Bank Passbook / Cancelled Cheque</h6>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 overflow-hidden">
                  <button class="btn btn-primary color-white mt-2" [disabled]="submitting"
                          (click)="bankFileUpload.click()">
                    <i class="fas fa-save mr-1" *ngIf="!submitting"></i>
                    <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>Update Image
                  </button>
                  <small>{{bankFileName}}</small>
                  <input class="form-control display-hidden" id="bankFile" type="file"
                         (change)="onFileChange('BANK', $event)" #bankFileUpload>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <button class="btn btn-primary pull-right" (click)="submitBank()">Submit</button>
                </div>
              </form>
              <div class="row mb-3 bottom-shadow-light">
                <div class="col-12">
                  <h5>Manage Bank Details Proof</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <h6>Account Holder</h6>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <h6>{{bank?.account_name}}</h6>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <h6>Account Number</h6>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <h6>{{bank?.account_number}}</h6>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <h6>IFSC</h6>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <h6>{{bank?.swift_code}}</h6>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <h6>Bank</h6>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <h6>{{bank?.bank_name}}</h6>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <h6>Branch</h6>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <h6>{{bank?.branch_name}}</h6>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <h6 class="mt-2">Account Proof</h6>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <button class="btn btn-primary bg-light-primary" (click)="view(bank?.bank_proof_image)"
                          *ngIf="bank && bank['bank_proof_image']">
                    View
                  </button>
                  <button class="btn btn-secondary bg-light-primary disabled"
                          *ngIf="!bank || !bank['bank_proof_image']">
                    View
                  </button>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <h6>Self Approval</h6>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <h6>{{((bank && (bank.id_checkbox == false)) || !bank) ? 'Not Approved' : 'Approved'}}</h6>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <h6>Admin Approval</h6>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <h6>{{(pan && pan['admin_remark'] == 'CLEAR') ? 'Approved' : 'Not Approved'}}</h6>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <div class="row mb-3 bottom-shadow-light">
                <div class="col-12">
                  <h5>Manage Aadhar Details</h5>
                </div>
              </div>
              <form class="row form" novalidate [formGroup]="aadharForm"
                    [ngClass]="{'display-hidden': address && address['aadhar_number']}">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <h6 class="mt-2">Aadhar</h6>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <input class="form-control" id="aadhar" type="text" formControlName="aadhar"/>
                  <small *ngIf="submitted && aadharForm.get('aadhar').hasError('required')"
                         class="text-danger">
                    Field is required.
                  </small>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <h6 class="mt-3">Upload Aadhar</h6>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 overflow-hidden">
                  <button class="btn btn-primary color-white mt-2" [disabled]="submitting"
                          (click)="aadharUpload.click()">
                    <i class="fas fa-save mr-1" *ngIf="!submitting"></i>
                    <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>Update Image
                  </button>
                  <small>{{aadharFileName}}</small>
                  <input class="form-control display-hidden" id="aadharFile" type="file"
                         (change)="onFileChange('AADHAR', $event)" #aadharUpload>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <button class="btn btn-primary pull-right" (click)="submitAadhar()">Submit</button>
                </div>
              </form>
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <h6>Aadhar Number</h6>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <h6>{{address?.aadhar_number}}</h6>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <h6 class="mt-2">Aadhar Image</h6>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <button class="btn btn-primary bg-light-primary" (click)="view(address?.aadhar_image)"
                          *ngIf="address && address['aadhar_image']">
                    View
                  </button>
                  <button class="btn btn-secondary bg-light-primary disabled"
                          *ngIf="!address || !address['aadhar_image']">
                    View
                  </button>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <h6>Self Approval</h6>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <h6>{{((bank && (bank.id_checkbox == false)) || !bank) ? 'Not Approved' : 'Approved'}}</h6>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <h6>Admin Approval</h6>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <h6>{{(pan && pan['admin_remark'] == 'CLEAR') ? 'Approved' : 'Not Approved'}}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
