import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertService} from '../../../shared/services/alert.service';
import {Location} from '@angular/common';
import {KycService} from '../../../shared/services/kyc.service';

@Component({
  selector: 'app-kyc',
  templateUrl: './kyc.component.html',
  styleUrls: ['./kyc.component.scss']
})
export class KycComponent implements OnInit {
  pan: any;
  address: any;
  bank: any;
  panCardForm: FormGroup;
  aadharForm: FormGroup;
  bankDetailsForm: FormGroup;
  submitted: any = false;
  submitting: any = false;
  onFileChanged: any;
  fileSelected: any;
  panFileName: any;
  aadharFileName: any;
  bankFileName: any;

  constructor(private router: Router,
              private fb: FormBuilder,
              public activatedRoute: ActivatedRoute,
              private alertService: AlertService,
              private _location: Location,
              private kycService: KycService) {
  }

  ngOnInit() {
    this.refresh();
  }

  refresh() {
    this.panCardForm = this.fb.group({
      'pan': ['']
    });
    this.aadharForm = this.fb.group({
      'aadhar': ['']
    });
    this.bankDetailsForm = this.fb.group({
      'accountName': [''],
      'accountNumber': [''],
      'bankName': [''],
      'swiftCode': [''],
      'branchName': [''],
    });
    this.getPan();
    this.getBank();
    this.getAddress();
  }

  getPan(params: any = {}) {
    this.kycService.getPan(params).subscribe(data => {
      this.pan = data;
      this.triggerScroll();
    });
  }

  getBank(params: any = {}) {
    this.kycService.getBank(params).subscribe(data => {
      this.bank = data;
      this.triggerScroll();
    });
  }

  getAddress(params: any = {}) {
    this.kycService.getAddress(params).subscribe(data => {
      this.address = data;
      this.triggerScroll();
    });
  }

  onFileChange(type, e) {
    this.onFileChanged = e;
    this.fileSelected = true;
    this.uploadImage(type, e);
  }

  uploadImage(type, imageFile) {
    if (type === 'PAN') {
      this.submitting = true;
      this.kycService.userDocUpload({'fileType': 'NORMAL'}, imageFile, 'PAN').subscribe(data => {
        this.panFileName = data['data'].filename;
        this.submitting = false;
        this.submitted = false;
        this.triggerScroll();
      }, error => {
        this.submitting = false;
        this.submitted = false;
      });
    } else if (type === 'AADHAR') {
      this.submitting = true;
      this.kycService.userDocUpload({'fileType': 'NORMAL'}, imageFile, 'AADHAR').subscribe(data => {
        this.aadharFileName = data['data'].filename;
        this.submitting = false;
        this.submitted = false;
        this.triggerScroll();
      }, error => {
        this.submitting = false;
        this.submitted = false;
      });
    } else if (type === 'BANK') {
      this.submitting = true;
      this.kycService.userDocUpload({'fileType': 'NORMAL'}, imageFile, 'BANK').subscribe(data => {
        this.bankFileName = data['data'].filename;
        this.submitting = false;
        this.submitted = false;
        this.triggerScroll();
      }, error => {
        this.submitting = false;
        this.submitted = false;
      });
    }
  }

  submitPan() {
    let params = {
      'pan': (this.panCardForm && this.panCardForm.value) ? this.panCardForm.value.pan : '',
      'filename': this.panFileName,
      'selfApproved': 1
    };
    this.kycService.updatePanDetail(params).subscribe(data => {
      this.submitting = false;
      this.submitted = false;
      this.triggerScroll();
    });
  }

  submitAadhar() {
    let params = {
      'aadhar': (this.aadharForm && this.aadharForm.value) ? this.aadharForm.value.aadhar : '',
      'filename': this.aadharFileName,
      'selfApproved': 1
    };
    this.kycService.updateAadharDetail(params).subscribe(data => {
      this.submitting = false;
      this.submitted = false;
      this.triggerScroll();
    });
  }

  submitBank() {
    let params = {
      'accountName': (this.bankDetailsForm && this.bankDetailsForm.value) ? this.bankDetailsForm.value.accountName : '',
      'accountNumber': (this.bankDetailsForm && this.bankDetailsForm.value) ? this.bankDetailsForm.value.accountNumber : '',
      'bankName': (this.bankDetailsForm && this.bankDetailsForm.value) ? this.bankDetailsForm.value.bankName : '',
      'swiftCode': (this.bankDetailsForm && this.bankDetailsForm.value) ? this.bankDetailsForm.value.swiftCode : '',
      'branchName': (this.bankDetailsForm && this.bankDetailsForm.value) ? this.bankDetailsForm.value.branchName : '',
      'filename': this.bankFileName,
      'selfApproved': 1
    };
    this.kycService.updateBankDetail(params).subscribe(data => {
      this.submitting = false;
      this.submitted = false;
      this.triggerScroll();
    });
  }

  back() {
    this._location.back();
  }

  view(url) {
    window.open(url, '_blank');
  }

  triggerScroll() {
    window.scrollTo(500, 500);
    window.scrollTo(500, 0);
  }
}
