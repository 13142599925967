import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {AppUrl} from '../constants/app-url';
import {UploadService} from './upload.service';

@Injectable({
  providedIn: 'root'
})
export class EshopService extends BaseService {
  constructor(public http: HttpClient,
              public alertService: AlertService,
              public globalService: GlobalService,
              public fileUploadService: UploadService) {
    super(http, alertService, globalService);
  }

  getStockist(data): Observable<any> {
    return this.getRequest(AppUrl.STOCKISTS(), data);
  }

  getStockistPackages(data): Observable<any> {
    return this.postRequest(AppUrl.STOCKIST_PACKAGES(), data);
  }

  getProducts(data): Observable<any> {
    return this.postRequest(AppUrl.PRODUCTS(), data);
  }
}
