import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertService} from '../../../shared/services/alert.service';
import {Location} from '@angular/common';
import {BeneficieryDetailsService} from '../../../shared/services/beneficiery-details.service';

@Component({
  selector: 'app-beneficiery-details',
  templateUrl: './beneficiery-details.component.html',
  styleUrls: ['./beneficiery-details.component.scss']
})
export class BeneficieryDetailsComponent implements OnInit {
  beneficieryInfoForm: FormGroup;
  submitted: any = false;
  submitting: any = false;

  constructor(private router: Router,
              private fb: FormBuilder,
              public activatedRoute: ActivatedRoute,
              private alertService: AlertService,
              private _location: Location,
              private beneficeryDetailsService: BeneficieryDetailsService) {
  }

  ngOnInit() {
    this.refresh();
  }

  refresh() {
    this.beneficieryInfoForm = this.fb.group({
      'ben_fullname': [''],
      'ben_bank': [''],
      'ben_acc_no': [''],
      'ben_acc_pin': ['']
    });
    this.get();
  }

  get(params: any = {}) {
    this.submitting = true;
    this.beneficeryDetailsService.get(params).subscribe(data => {
      this.patchValues(data);
      this.submitting = false;
    }, error => {
      this.submitting = false;
    });
  }

  patchValues(data) {
    this.beneficieryInfoForm.patchValue({
      'ben_fullname': (data && data.ben_fullname) ? data.ben_fullname : '',
      'ben_bank': (data && data.ben_bank) ? data.ben_bank : '',
      'ben_acc_no': (data && data.ben_acc_no) ? data.ben_acc_no : '',
      'ben_acc_pin': (data && data.ben_acc_pin) ? data.ben_acc_pin : '',
    });
  }

  back() {
    this._location.back();
  }

  update() {
    this.submitted = true;
    if (!this.beneficieryInfoForm.valid) {
      return;
    }
    if (this.submitted) {
      const params = {
        'ben_fullname': (this.beneficieryInfoForm.value && this.beneficieryInfoForm.value.ben_fullname) ? this.beneficieryInfoForm.value.ben_fullname : '',
        'ben_bank': (this.beneficieryInfoForm.value && this.beneficieryInfoForm.value.ben_bank) ? this.beneficieryInfoForm.value.ben_bank : '',
        'ben_acc_no': (this.beneficieryInfoForm.value && this.beneficieryInfoForm.value.ben_acc_no) ? this.beneficieryInfoForm.value.ben_acc_no : '',
        'ben_acc_pin': (this.beneficieryInfoForm.value && this.beneficieryInfoForm.value.ben_acc_pin) ? this.beneficieryInfoForm.value.ben_acc_pin : ''
      };
      this.submitting = true;
      this.beneficeryDetailsService.update(params).subscribe(data => {
        this.submitting = false;
        this.submitted = false;
        this.triggerScroll();
      }, error => {
        this.submitting = false;
        this.submitted = false;
      });
    }
  }

  triggerScroll() {
    window.scrollTo(500, 500);
    window.scrollTo(500, 0);
  }
}
