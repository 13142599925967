<section>
  <ng-template #content>
    <div id="modal">
      <div class="modal-header font-weight-bold">
        <h4 class="modal-title" *ngIf="productDetail">Product Details</h4>
        <h4 class="modal-title" *ngIf="productStockInfo">{{productStockInfo['data']?.product_name}}</h4>
        <button class="close btn-close-modal" (click)="modalService.dismissAll('')">
          <span aria-hidden="true" class="close-modal-cross">x</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row" *ngIf="productDetail">
          <div class="col-12">
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                <img src="https://eshopping.axicoayurveda.com/cmsadmin/product_images/{{productDetail.actual_image}}"
                     width="200" height="200"/>
              </div>
            </div>
            <div class="row mt-3 p-2 shadow-light">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                Product Name
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                {{productDetail?.product_name}}
              </div>
            </div>
            <div class="row p-2 shadow-light">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                Price
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                &#8377; {{productDetail?.price}}
              </div>
            </div>
            <div class="row p-2 shadow-light">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                BV's
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                {{productDetail?.points}}
              </div>
            </div>
            <div class="row p-2">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                Details
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                {{productDetail?.description}}
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="productStockInfo">
          <div class="col-12">
            <div class="row mt-1 p-2">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                {{productStockInfo?.info}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary color-white pull-right" (click)="this.modalService.dismissAll('')">
          Close
        </button>
      </div>
    </div>
  </ng-template>
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="row">
        <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
          <h4 class="mt-3 ml-1">Products</h4>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
          <button class="btn btn-primary pull-right color-white mt-2 color-white users-btn pull-right"
                  (click)="showSearch = !showSearch">
            <i aria-hidden="true" class="fa fa-search mr-2"></i>Search
          </button>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="row mt-2 mb--10px" *ngIf="showSearch">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <form class="form background-white" novalidate [formGroup]="searchForm">
                <div class="row">
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                    <div class="form-group ml-2 pt-3 pb-1 mt--5px">
                      <input class="form-control" id="product_name" type="text" formControlName="product_name"
                             placeholder="Product Name">
                    </div>
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                    <div class="form-group ml-2 pt-3 pb-1 mt--5px">
                      <input class="form-control" id="price" type="text" formControlName="price"
                             placeholder="Price">
                    </div>
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                    <div class="form-group ml-2 pt-3 pb-1 mt--5px">
                      <input class="form-control" id="product_bv" type="text" formControlName="product_bv"
                             placeholder="Product BV">
                    </div>
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 text-center">
                    <button class="btn btn-primary btn-block btn-raised color-white mt-16px ml--10px" (click)="search()">
                      Search
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="card mt-4">
        <!--<div class="card-header bottom-shadow-light">
          <div class="bar-success">
            <h4 class="card-title mb-3 ng-star-inserted">Products</h4>
          </div>
        </div>-->
        <div class="card-body mt-1">
          <div class="row mb-2 text-center" *ngIf="products && products.length > 0; else noDataToShow">
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12" *ngFor="let product of products; let i = index">
              <div class="row p-3 shadow-light shadow-hover cursor-pointer">
                <div class="col-12">
                  <div class="row">
                    <div class="col-12">
                      <img src="https://eshopping.axicoayurveda.com/cmsadmin/product_images/{{product.actual_image}}"
                           width="300" height="300"/>
                    </div>
                  </div>
                  <div class="row card-ellipsis">
                    <div class="col-12">
                      <h6 [title]="product.product_name">{{product.product_name}}</h6>
                    </div>
                  </div>
                  <div class="row bottom-shadow-light">
                    <div class="col-12">
                      <h6>&#8377; {{product.price}}</h6>
                    </div>
                  </div>
                  <div class="row mt-3 mb-2">
                    <div class="col-12">
                      <button class="btn btn-primary" (click)="addToCart(content, product)"
                              *ngIf="!product?.addedToCart">
                        <i class="fas fa-cart-plus mr-2"></i> Add to cart
                      </button>
                      <div role="group" class="btn-group" *ngIf="product?.addedToCart">
                        <button type="button" class="btn btn-outline-primary" (click)="removeItem(product)">
                          <i class="fas fa-minus-circle"></i>
                        </button>
                        <button type="button" class="btn btn-primary disabled">
                          {{product?.qty}}
                        </button>
                        <button type="button" class="btn btn-outline-primary" (click)="addItem(content, product)">
                          <i class="fas fa-plus-circle"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-12">
                      <h6 class="text-info" (click)="details(content, product)">
                        <i class="fas fa-info-circle mr-2"></i>View Product Details
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ng-template #noDataToShow>
            <div class="row">
              <div class="col-12 text-center">
                <h4 class="p-5">No data to show</h4>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</section>
