import {Component, OnInit} from '@angular/core';
import {PurchaseEshopInvoicesService} from '../../../shared/services/purchase-eshop-invoices.service';
import {FormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-purchase-eshop-invoices',
  templateUrl: './purchase-eshop-invoices.component.html',
  styleUrls: ['./purchase-eshop-invoices.component.scss']
})
export class PurchaseEshopInvoicesComponent implements OnInit {
  submitting = false;
  purchaseEshopInvoices = [];
  total: any = 0;
  showSearch: any = false;
  searchForm: FormGroup;
  pagination = {
    page: 1,
    total: null,
    perpage: 15,
    pages: null
  };

  constructor(public purchaseEshopInvoicesService: PurchaseEshopInvoicesService,
              private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    this.searchForm = this.fb.group({
      'receipt_no': [''],
      'status': ['']
    });
    this.get();
  }

  get(params: any = {}) {
    params.page = this.pagination.page;
    params.perpage = this.pagination.perpage;
    this.submitting = true;
    this.purchaseEshopInvoices = [];
    this.purchaseEshopInvoicesService.get(params).subscribe(data => {
      if (data && data['data'] && data['data'].length > 0) {
        this.purchaseEshopInvoices = data['data'];
        this.pagination = {
          page: data['pagination'].page,
          total: data['pagination'].total,
          perpage: data['pagination'].perpage,
          pages: data['pagination'].pages,
        };
        this.total = data.total_commission;
        this.triggerScroll();
      } else {
        this.purchaseEshopInvoices = [];
      }
      this.submitting = false;
    }, error => {
      this.submitting = false;
    });
  }

  triggerScroll() {
    window.scrollTo(500, 500);
    window.scrollTo(500, 0);
  }

  view(data) {
    window.open(data['receipt_url'], '_blank');
  }

  onPageChange(page) {
    this.pagination.page = page;
    this.get();
  }

  search() {
    this.pagination = {
      page: 1,
      total: null,
      perpage: 15,
      pages: null
    };
    let params = {
      'receipt_no': (this.searchForm && this.searchForm.value) ? this.searchForm.value.receipt_no : '',
      'status': (this.searchForm && this.searchForm.value) ? this.searchForm.value.status : ''
    };
    this.get(params);
  }
}
