import {Component, OnInit} from '@angular/core';
import {MatchingTreeService} from '../../../shared/services/matching-tree.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CountryService} from '../../../shared/services/country.service';
import {StateService} from '../../../shared/services/state.service';
import {StorageService} from '../../../shared/services/storage.service';
import {AuthService} from '../../../shared/services/auth.service';
import {AlertService} from '../../../shared/services/alert.service';

@Component({
  selector: 'app-matching-tree',
  templateUrl: './matching-tree.component.html',
  styleUrls: ['./matching-tree.component.scss']
})
export class MatchingTreeComponent implements OnInit {
  submitting = false;
  matchingTree = [];
  showTooltip1 = false;
  showTooltip2 = false;
  showTooltip3 = false;
  showTooltip4 = false;
  showTooltip5 = false;
  showTooltip6 = false;
  showTooltip7 = false;
  showTooltip8 = false;
  showTooltip9 = false;
  showTooltip10 = false;
  showTooltip11 = false;
  showTooltip12 = false;
  showTooltip13 = false;
  showTooltip14 = false;
  showTooltip15 = false;
  registrationForm: FormGroup;
  submitted = false;
  countries = [];
  states = [];
  selectedSponsor: any;
  user: any;

  constructor(public matchingTreeService: MatchingTreeService,
              public modalService: NgbModal,
              private fb: FormBuilder,
              public countryService: CountryService,
              public stateService: StateService,
              public authService: AuthService,
              public alertService: AlertService) {
  }

  ngOnInit(): void {
    this.user = StorageService.getItem('self');
    this.refresh();
  }

  refresh() {
    this.registrationForm = this.fb.group({
      'sponsor_id': [{'value': '', disabled: true}],
      'nomination_id': [{'value': '', disabled: true}],
      'type': [{'value': '', disabled: true}],
      'username': ['', Validators.required],
      'password': ['', Validators.required],
      'confirm_password': ['', Validators.required],
      'first_name': ['', Validators.required],
      'last_name': ['', Validators.required],
      'telephone': ['', Validators.required],
      'country': [{'value': '', disabled: true}],
      'email': ['', Validators.compose([Validators.required, Validators.pattern('^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')])],
      'address': ['', Validators.required],
      'city': ['', Validators.required],
      'state': ['', Validators.required],
      'zip_code': ['', Validators.required]
    });
    this.getCountries();
  }

  getCountries(params: any = {}) {
    this.countryService.get(params).subscribe(data => {
      this.countries = data;
      this.submitting = false;
      this.getStates();
    }, error => {
      this.submitting = false;
    });
  }

  getStates() {
    this.submitting = true;
    this.stateService.get({'country_id': 101}).subscribe(data => {
      this.states = data;
      this.submitting = false;
      this.getSelfMatchingTree();
    }, error => {
      this.submitting = false;
    });
  }

  getSelfMatchingTree(params: any = {}) {
    this.submitting = true;
    this.matchingTree = [];
    this.matchingTreeService.getSelfTree(params).subscribe(data => {
      if (data && data && data.length > 0) {
        this.matchingTree = data;
        this.triggerScroll();
      } else {
        this.matchingTree = [];
      }
      this.submitting = false;
    }, error => {
      this.submitting = false;
    });
  }

  getUserTree(content, user, sponsor) {
    if (user && user['user_id']) {
      this.submitting = true;
      this.matchingTree = [];
      this.matchingTreeService.getUserMatchingTree({'id': user['user_id']}).subscribe(data => {
        if (data && data && data.length > 0) {
          this.matchingTree = data;
          this.hideAllTooltips();
          this.triggerScroll();
        } else {
          this.matchingTree = [];
        }
        this.submitting = false;
      }, error => {
        this.submitting = false;
      });
    } else {
      this.selectedSponsor = sponsor;
      this.registrationForm.patchValue({
        'sponsor_id': (this.user && this.user['userId']) ? this.user['userId'] : '',
        'nomination_id': (this.selectedSponsor && this.selectedSponsor.user_id) ? this.selectedSponsor.user_id : '',
        'type': (user && user.type) ? user.type : '',
        'country': 'India',
      });
      this.modalService.open(content, {'size': 'lg'});
    }
  }

  register() {
    this.submitted = true;
    if (!this.registrationForm.valid) {
      return;
    }
    if (this.submitted) {
      const params = {
        'sponser_id': (this.registrationForm.value && this.registrationForm.getRawValue().sponsor_id) ? this.registrationForm.getRawValue().sponsor_id : '',
        'nomination_id': (this.registrationForm.value && this.registrationForm.getRawValue().nomination_id) ? this.registrationForm.getRawValue().nomination_id : '',
        'type': (this.registrationForm.value && this.registrationForm.getRawValue().type) ? this.registrationForm.getRawValue().type.toLowerCase() : '',
        'username': (this.registrationForm.value && this.registrationForm.getRawValue().username) ? this.registrationForm.getRawValue().username : '',
        'password': (this.registrationForm.value && this.registrationForm.getRawValue().password) ? this.registrationForm.getRawValue().password : '',
        'first_name': (this.registrationForm.value && this.registrationForm.getRawValue().first_name) ? this.registrationForm.getRawValue().first_name : '',
        'last_name': (this.registrationForm.value && this.registrationForm.getRawValue().last_name) ? this.registrationForm.getRawValue().last_name : '',
        'telephone': (this.registrationForm.value && this.registrationForm.getRawValue().telephone) ? this.registrationForm.getRawValue().telephone : '',
        'country': (this.registrationForm.value && this.registrationForm.getRawValue().country) ? this.registrationForm.getRawValue().country : '',
        'email': (this.registrationForm.value && this.registrationForm.getRawValue().email) ? this.registrationForm.getRawValue().email : '',
        'address': (this.registrationForm.value && this.registrationForm.getRawValue().address) ? this.registrationForm.getRawValue().address : '',
        'city': (this.registrationForm.value && this.registrationForm.getRawValue().city) ? this.registrationForm.getRawValue().city : '',
        'state': (this.registrationForm.value && this.registrationForm.getRawValue().state) ? this.registrationForm.getRawValue().state : '',
        'zip_code': (this.registrationForm.value && this.registrationForm.getRawValue().zip_code) ? this.registrationForm.getRawValue().zip_code : ''
      };
      this.authService.register(params).subscribe(data => {
        this.submitted = false;
        if (data && data.status === 'success') {
          this.alertService.showSuccess({title: 'Success', message: 'User registered successfully'});
          this.modalService.dismissAll('');
          this.getSelfMatchingTree();
        }
      }, error => {
        this.submitted = false;
      });
    }
  }

  hideAllTooltips() {
    this.showTooltip1 = false;
    this.showTooltip2 = false;
    this.showTooltip3 = false;
    this.showTooltip4 = false;
    this.showTooltip5 = false;
    this.showTooltip6 = false;
    this.showTooltip7 = false;
    this.showTooltip8 = false;
    this.showTooltip9 = false;
    this.showTooltip10 = false;
    this.showTooltip11 = false;
    this.showTooltip12 = false;
    this.showTooltip13 = false;
    this.showTooltip14 = false;
    this.showTooltip15 = false;
  }

  triggerScroll() {
    window.scrollTo(500, 500);
    window.scrollTo(500, 0);
  }
}
