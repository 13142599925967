import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {CommonModule} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
//import {AngularFireModule} from '@angular/fire';
//import {AngularFireAuthModule} from '@angular/fire/auth';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AgmCoreModule} from '@agm/core';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {NgxSpinnerModule} from 'ngx-spinner';
import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';
import {AppRoutingModule} from './app-routing.module';
import {SharedModule} from './shared/shared.module';
import {AppComponent} from './app.component';
import {ContentLayoutComponent} from './layouts/content/content-layout.component';
import {FullLayoutComponent} from './layouts/full/full-layout.component';
import {AuthService} from './shared/auth/auth.service';
import {AuthGuard} from './shared/auth/auth-guard.service';
import {WINDOW_PROVIDERS} from './shared/services/window.service';
import {MomentDatePipe} from './shared/pipes/moment-date.pipe';
import {LoginComponent} from './layouts/login/login.component';
import {AlertService} from './shared/services/alert.service';
import {DashboardComponent} from './users/dashboard/dashboard.component';
import {SnotifyModule, SnotifyService, ToastDefaults} from 'ng-snotify';
import {GlobalService, MomentService} from './shared/services/global.service';
import {HtpInterceptor} from './shared/services/interceptor.service';
import {ReactiveFormsModule} from '@angular/forms';
import {DirectReferralsComponent} from './users/my-network/direct-referrals/direct-referrals.component';
import {DownlineMembersComponent} from './users/my-network/downline-members/downline-members.component';
import {MyWalletComponent} from './users/wallet/my-wallet/my-wallet.component';
import {EwalletWithdrawalComponent} from './users/wallet/ewallet-withdrawal/ewallet-withdrawal.component';
import {PairingBonusComponent} from './users/earnings/pairing-bonus/pairing-bonus.component';
import {SelfRepurchaseBonusComponent} from './users/earnings/self-repurchase-bonus/self-repurchase-bonus.component';
import {PerformanceBonusComponent} from './users/earnings/performance-bonus/performance-bonus.component';
import {LeadershipBonusComponent} from './users/earnings/leadership-bonus/leadership-bonus.component';
import {DirectorBonusComponent} from './users/earnings/director-bonus/director-bonus.component';
import {CarFundBonusComponent} from './users/earnings/car-fund-bonus/car-fund-bonus.component';
import {HouseFundBonusComponent} from './users/earnings/house-fund-bonus/house-fund-bonus.component';
import {TourFundBonusComponent} from './users/earnings/tour-fund-bonus/tour-fund-bonus.component';
import {PerformanceBonusOverviewComponent} from './users/earnings/performance-bonus-overview/performance-bonus-overview.component';
import {DirectorSideLegRepurchaseReportComponent} from './users/earnings/director-side-leg-repurchase-report/director-side-leg-repurchase-report.component';
import {PurchaseEshopInvoicesComponent} from './users/invoices-section/purchase-eshop-invoices/purchase-eshop-invoices.component';
import {MyPackageInvoicesComponent} from './users/invoices-section/my-package-invoices/my-package-invoices.component';
import {ViewTicketResponseComponent} from './users/support-center/view-ticket-response/view-ticket-response.component';
import {TermsAndConditionsComponent} from './users/policy-section/terms-and-conditions/terms-and-conditions.component';
import {OfficialAnnouncementsComponent} from './users/official-announcements/official-announcements.component';
import {AnnouncementDetailComponent} from './users/official-announcements/announcement-detail/announcement-detail.component';
import {OpenTicketComponent} from './users/support-center/open-ticket/open-ticket.component';
import {ReferralLinksComponent} from './users/marketing-tools/referral-links/referral-links.component';
import {MyWalletTransactionHistoryComponent} from './users/wallet/my-wallet-transaction-history/my-wallet-transaction-history.component';
import {ManageProfileComponent} from './users/profile/manage-profile/manage-profile.component';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {BeneficieryDetailsComponent} from './users/profile/beneficiery-details/beneficiery-details.component';
import {ChangePasswordComponent} from './users/profile/change-password/change-password.component';
import {KycComponent} from './users/profile/kyc/kyc.component';
import {EshopComponent} from './users/eshop/eshop.component';
import {ProductsComponent} from './users/eshop/products/products.component';
import {CartComponent} from './users/eshop/cart/cart.component';
import {UpdateAddressComponent} from './users/eshop/update-address/update-address.component';
import {EshopSuccessComponent} from './users/eshop/eshop-success/eshop-success.component';
import {PackageUpgradeComponent} from './users/package-upgrade/package-upgrade.component';
import {MatchingTreeComponent} from './users/my-network/matching-tree/matching-tree.component';
import {StockistDashboardComponent} from './stockists/dashboard/dashboard.component';
import {StockistMySaleIncomeReportComponent} from './stockists/my-income-report/my-sale-income-report/my-sale-income-report.component';
import {StockistWalletTransactionHistoryComponent} from './stockists/my-income-report/wallet-transaction-history/wallet-transaction-history.component';
import {StockistMyStockListComponent} from './stockists/stock-management/my-stock-list/my-stock-list.component';
import {StockistPurchaseProductsHistoryComponent} from './stockists/stock-management/purchase-products-history/purchase-products-history.component';
import {StockistPendingInvoicesComponent} from './stockists/user-eshop-invoice-section/pending-invoices/pending-invoices.component';
import {StockistDeliveredInvoicesComponent} from './stockists/user-eshop-invoice-section/delivered-invoices/delivered-invoices.component';
import {StockistCancelledInvoicesComponent} from './stockists/user-eshop-invoice-section/cancelled-invoices/cancelled-invoices.component';
import {StockistUserPackagePendingInvoicesComponent} from './stockists/user-package-invoice-section/pending-invoices/pending-invoices.component';
import {StockistDeliveredInvoicesUserPackageComponent} from './stockists/user-package-invoice-section/delivered-invoices/delivered-invoices.component';
import {StockistCancelledUserPackageInvoicesComponent} from './stockists/user-package-invoice-section/cancelled-invoices/cancelled-invoices.component';
import {StockistTermsConditionsComponent} from './stockists/media-documents/terms-conditions/terms-conditions.component';
import {StockistPrivacyPoliciesComponent} from './stockists/media-documents/privacy-policies/privacy-policies.component';
import {StockistProfileComponent} from './stockists/my-account/profile/profile.component';
import {StockistPurchaseProductsComponent} from './stockists/stock-management/purchase-products/purchase-products.component';
import {StockistCartComponent} from './stockists/stock-management/cart/cart.component';
import {StockistShopSuccessComponent} from './stockists/stock-management/shop-success/shop-success.component';
import {StockistBillingOrdersComponent} from './stockists/billing-section/billing-orders/billing-orders.component';
import {StockistUserProductsComponent} from './stockists/billing-section/products/products.component';
import {StockistUserUpdateAddressComponent} from './stockists/billing-section/update-address/update-address.component';
import {StockistShopSuccessUserComponent} from './stockists/billing-section/shop-success/shop-success.component';

/*var firebaseConfig = {
  apiKey: 'YOUR_API_KEY', //YOUR_API_KEY
  authDomain: 'YOUR_AUTH_DOMAIN', //YOUR_AUTH_DOMAIN
  databaseURL: 'YOUR_DATABASE_URL', //YOUR_DATABASE_URL
  projectId: 'YOUR_PROJECT_ID', //YOUR_PROJECT_ID
  storageBucket: 'YOUR_STORAGE_BUCKET', //YOUR_STORAGE_BUCKET
  messagingSenderId: 'YOUR_MESSAGING_SENDER_ID', //YOUR_MESSAGING_SENDER_ID
  appId: 'YOUR_APP_ID', //YOUR_APP_ID
  measurementId: 'YOUR_MEASUREMENT_ID' //YOUR_MEASUREMENT_ID
};*/

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false
};

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    FullLayoutComponent,
    ContentLayoutComponent,
    MomentDatePipe,
    LoginComponent,
    DashboardComponent,
    DirectReferralsComponent,
    DownlineMembersComponent,
    MyWalletComponent,
    EwalletWithdrawalComponent,
    PairingBonusComponent,
    SelfRepurchaseBonusComponent,
    PerformanceBonusComponent,
    LeadershipBonusComponent,
    DirectorBonusComponent,
    CarFundBonusComponent,
    HouseFundBonusComponent,
    TourFundBonusComponent,
    PerformanceBonusOverviewComponent,
    DirectorSideLegRepurchaseReportComponent,
    PurchaseEshopInvoicesComponent,
    MyPackageInvoicesComponent,
    ViewTicketResponseComponent,
    TermsAndConditionsComponent,
    OfficialAnnouncementsComponent,
    AnnouncementDetailComponent,
    OpenTicketComponent,
    ReferralLinksComponent,
    MyWalletTransactionHistoryComponent,
    ManageProfileComponent,
    BeneficieryDetailsComponent,
    ChangePasswordComponent,
    KycComponent,
    EshopComponent,
    ProductsComponent,
    CartComponent,
    UpdateAddressComponent,
    EshopSuccessComponent,
    PackageUpgradeComponent,
    MatchingTreeComponent,
    StockistDashboardComponent,
    StockistMySaleIncomeReportComponent,
    StockistWalletTransactionHistoryComponent,
    StockistMyStockListComponent,
    StockistPurchaseProductsHistoryComponent,
    StockistPendingInvoicesComponent,
    StockistDeliveredInvoicesComponent,
    StockistCancelledInvoicesComponent,
    StockistUserPackagePendingInvoicesComponent,
    StockistDeliveredInvoicesUserPackageComponent,
    StockistCancelledUserPackageInvoicesComponent,
    StockistTermsConditionsComponent,
    StockistPrivacyPoliciesComponent,
    StockistProfileComponent,
    StockistPurchaseProductsComponent,
    StockistCartComponent,
    StockistShopSuccessComponent,
    StockistBillingOrdersComponent,
    StockistUserProductsComponent,
    StockistUserUpdateAddressComponent,
    StockistShopSuccessUserComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    /*AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule,*/
    NgbModule,
    SnotifyModule,
    NgxSpinnerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: 'YOUR_GOOGLE_MAP_API_KEY'
    }),
    PerfectScrollbarModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot()
  ],
  providers: [
    {provide: 'SnotifyToastConfig', useValue: ToastDefaults},
    {provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG},
    WINDOW_PROVIDERS,
    AuthService,
    AuthGuard,
    HtpInterceptor,
    GlobalService,
    AlertService,
    SnotifyService,
    MomentService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
