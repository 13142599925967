import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertService} from '../../../shared/services/alert.service';
import {Location} from '@angular/common';
import {OpenTicketService} from '../../../shared/services/open-ticket.service';

@Component({
  selector: 'app-open-ticket',
  templateUrl: './open-ticket.component.html',
  styleUrls: ['./open-ticket.component.scss']
})
export class OpenTicketComponent implements OnInit {
  raiseTicketForm: FormGroup;
  submitted: any = false;
  submitting: any = false;
  categories: any = [
    {
      'name': 'Financial',
      'value': 'Financial'
    },
    {
      'name': 'Technical',
      'value': 'Technical'
    },
    {
      'name': 'General',
      'value': 'General'
    },
    {
      'name': 'Product',
      'value': 'Product'
    },
    {
      'name': 'Others',
      'value': 'Others'
    },
  ];

  constructor(private router: Router,
              private fb: FormBuilder,
              public activatedRoute: ActivatedRoute,
              private alertService: AlertService,
              private _location: Location,
              private openTicketService: OpenTicketService,) {
  }

  ngOnInit() {
    this.refresh();
  }

  refresh() {
    this.raiseTicketForm = this.fb.group({
      'category': ['', Validators.required],
      'subject': ['', Validators.required],
      'message': ['', Validators.required]
    });
  }

  back() {
    this._location.back();
  }

  submit() {
    this.submitted = true;
    if (!this.raiseTicketForm.valid) {
      return;
    }
    if (this.submitted) {
      const params = {
        'category': (this.raiseTicketForm.value && this.raiseTicketForm.value.category) ? this.raiseTicketForm.value.category : '',
        'subject': (this.raiseTicketForm.value && this.raiseTicketForm.value.subject) ? this.raiseTicketForm.value.subject : '',
        'message': (this.raiseTicketForm.value && this.raiseTicketForm.value.message) ? this.raiseTicketForm.value.message : ''
      };
      this.submitting = true;
      this.openTicketService.create(params).subscribe(data => {
        this.submitting = false;
        this.submitted = false;
        this.back();
      }, error => {
        this.submitting = false;
        this.submitted = false;
      });
    }
  }
}
