import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Location} from '@angular/common';
import {StateService} from '../../../shared/services/state.service';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {ManageProfileStockistService} from '../../../shared/services/manage-profile-stockist.service';
import {AlertService} from '../../../shared/services/alert.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})

export class StockistProfileComponent implements OnInit {
  profileForm: FormGroup;
  bankInfoForm: FormGroup;
  submitted: any = false;
  submitting: any = false;
  states: any = [];
  genders: any = [
    {
      'name': 'Male',
      'value': 'Male'
    },
    {
      'name': 'Female',
      'value': 'Female'
    },
    {
      'name': 'Other',
      'value': 'Other'
    }
  ];
  maritalStatus: any = [
    {
      'name': 'Single',
      'value': 'Single'
    },
    {
      'name': 'Married',
      'value': 'Married'
    },
    {
      'name': 'Other',
      'value': 'Other'
    }
  ];
  bsConfig: Partial<BsDatepickerConfig>;
  profileImage: any;
  onFileChanged: any;
  fileSelected: any;

  constructor(private fb: FormBuilder,
              private _location: Location,
              private stateService: StateService,
              private manageProfileStockistService: ManageProfileStockistService,
              private alertService: AlertService) {
    this.bsConfig = Object.assign({}, {containerClass: 'theme-dark-blue', dateInputFormat: 'YYYY-MM-DD'}, {showWeekNumbers: false});
  }

  ngOnInit(): void {
    this.refresh();
    this.getStates();
  }

  refresh() {
    this.profileForm = this.fb.group({
      'first_name': [''],
      'last_name': [''],
      'email': [''],
      'dob': [''],
      'password': [''],
      'confirm_password': [''],
      't_code': [''],
      'confirm_t_code': [''],
      'merried_status': [''],
      'sex': [''],
      'telephone': [''],
      'lendmark': [''],
      'address': [''],
      'city': [''],
      'state': [''],
      'pan': ['']
    });

    this.bankInfoForm = this.fb.group({
      'acc_name': [''],
      'ac_no': [''],
      'bank_nm': [''],
      'branch_nm': [''],
      'swift_code': ['']
    });

    this.getProfile();
    this.getBankInfo();
    this.getProfileImage();
  }

  getStates() {
    this.submitting = true;
    this.stateService.get({'country_id': 101}).subscribe(data => {
      this.states = data;
      this.submitting = false;
    }, error => {
      this.submitting = false;
    });
  }

  getProfile(params: any = {}) {
    this.submitting = true;
    this.manageProfileStockistService.get(params).subscribe(data => {
      this.patchProfileValue(data);
      this.submitting = false;
    }, error => {
      this.submitting = false;
    });
  }

  patchProfileValue(data) {
    this.profileForm.patchValue({
      'first_name': (data && data.first_name) ? data.first_name : '',
      'last_name': (data && data.last_name) ? data.last_name : '',
      'user_id': (data && data.user_id) ? data.user_id : '',
      'username': (data && data.username) ? data.username : '',
      'email': (data && data.email) ? data.email : '',
      'dob': (data && data.dob) ? data.dob : '',
      'password': (data && data.password) ? data.password : '',
      'confirm_password': (data && data.password) ? data.password : '',
      't_code': (data && data.transcation_code) ? data.transcation_code : '',
      'confirm_t_code': (data && data.transcation_code) ? data.transcation_code : '',
      'sex': (data && data.sex) ? data.sex : '',
      'merried_status': (data && data.marital_status) ? data.marital_status : '',
      'telephone': (data && data.telephone) ? data.telephone : '',
      'lendmark': (data && data.landmark) ? data.landmark : '',
      'address': (data && data.address) ? data.address : '',
      'city': (data && data.city) ? data.city : '',
      'state': (data && data.state) ? data.state : '',
      'pan': (data && data.pan) ? data.pan : '',
    });
  }

  getBankInfo(params: any = {}) {
    this.submitting = true;
    this.manageProfileStockistService.getAccountDetail(params).subscribe(data => {
      this.patchAccountValue(data);
      this.submitting = false;
    }, error => {
      this.submitting = false;
    });
  }

  patchAccountValue(data) {
    this.bankInfoForm.patchValue({
      'acc_name': (data && data.account_name) ? data.account_name : '',
      'ac_no': (data && data.account_number) ? data.account_number : '',
      'bank_nm': (data && data.bank_name) ? data.bank_name : '',
      'branch_nm': (data && data.branch_name) ? data.branch_name : '',
      'swift_code': (data && data.ifsc_code) ? data.ifsc_code : ''
    });
  }

  back() {
    this._location.back();
  }

  updatePersonalInfo() {
    this.submitted = true;
    if (!this.profileForm.valid) {
      return;
    }

    if (this.profileForm.getRawValue().password !== this.profileForm.getRawValue().confirm_password) {
      this.alertService.showErrors('Password and Confirm password didn\'t match');
      return;
    }

    if (this.profileForm.getRawValue().t_code !== this.profileForm.getRawValue().confirm_t_code) {
      this.alertService.showErrors('Transaction password and Confirm transaction password didn\'t match');
      return;
    }

    if (this.submitted) {
      const params = {
        'first_name': (this.profileForm.getRawValue() && this.profileForm.getRawValue().first_name) ? this.profileForm.getRawValue().first_name : '',
        'last_name': (this.profileForm.getRawValue() && this.profileForm.getRawValue().last_name) ? this.profileForm.getRawValue().last_name : '',
        'user_id': (this.profileForm.getRawValue() && this.profileForm.getRawValue().user_id) ? this.profileForm.getRawValue().user_id : '',
        'username': (this.profileForm.getRawValue() && this.profileForm.getRawValue().username) ? this.profileForm.getRawValue().username : '',
        'email': (this.profileForm.getRawValue() && this.profileForm.getRawValue().email) ? this.profileForm.getRawValue().email : '',
        'dob': (this.profileForm.getRawValue() && this.profileForm.getRawValue().dob) ? this.profileForm.getRawValue().dob : '',
        'password': (this.profileForm.getRawValue() && this.profileForm.getRawValue().password) ? this.profileForm.getRawValue().password : '',
        't_code': (this.profileForm.getRawValue() && this.profileForm.getRawValue().transcation_code) ? this.profileForm.getRawValue().transcation_code : '',
        'sex': (this.profileForm.getRawValue() && this.profileForm.getRawValue().sex) ? this.profileForm.getRawValue().sex : '',
        'merried_status': (this.profileForm.getRawValue() && this.profileForm.getRawValue().merried_status) ? this.profileForm.getRawValue().merried_status : '',
        'telephone': (this.profileForm.getRawValue() && this.profileForm.getRawValue().telephone) ? this.profileForm.getRawValue().telephone : '',
        'lendmark': (this.profileForm.getRawValue() && this.profileForm.getRawValue().lendmark) ? this.profileForm.getRawValue().lendmark : '',
        'address': (this.profileForm.getRawValue() && this.profileForm.getRawValue().address) ? this.profileForm.getRawValue().address : '',
        'city': (this.profileForm.getRawValue() && this.profileForm.getRawValue().city) ? this.profileForm.getRawValue().city : '',
        'state': (this.profileForm.getRawValue() && this.profileForm.getRawValue().state) ? this.profileForm.getRawValue().state : '',
        'id_no': (this.profileForm.getRawValue() && this.profileForm.getRawValue().pan) ? this.profileForm.getRawValue().pan : ''
      };
      this.submitting = true;
      this.manageProfileStockistService.updatePersonalInfo(params).subscribe(data => {
        this.submitting = false;
        this.submitted = false;
        this.triggerScroll();
      }, error => {
        this.submitting = false;
        this.submitted = false;
      });
    }
  }

  updateBankInfo() {
    this.submitted = true;
    if (!this.bankInfoForm.valid) {
      return;
    }

    if (this.submitted) {
      const params = {
        'acc_name': (this.bankInfoForm.getRawValue() && this.bankInfoForm.getRawValue().acc_name) ? this.bankInfoForm.getRawValue().acc_name : '',
        'ac_no': (this.bankInfoForm.getRawValue() && this.bankInfoForm.getRawValue().ac_no) ? this.bankInfoForm.getRawValue().ac_no : '',
        'bank_nm': (this.bankInfoForm.getRawValue() && this.bankInfoForm.getRawValue().bank_nm) ? this.bankInfoForm.getRawValue().bank_nm : '',
        'branch_nm': (this.bankInfoForm.getRawValue() && this.bankInfoForm.getRawValue().branch_nm) ? this.bankInfoForm.getRawValue().branch_nm : '',
        'swift_code': (this.bankInfoForm.getRawValue() && this.bankInfoForm.getRawValue().swift_code) ? this.bankInfoForm.getRawValue().swift_code : ''
      };
      this.submitting = true;
      console.log('params', params);
      this.manageProfileStockistService.updateBankInfo(params).subscribe(data => {
        this.submitting = false;
        this.submitted = false;
        this.triggerScroll();
      }, error => {
        this.submitting = false;
        this.submitted = false;
      });
    }
  }

  onFileChange(e) {
    this.onFileChanged = e;
    this.fileSelected = true;
    this.uploadImage();
  }

  uploadImage() {
    this.submitting = true;
    this.manageProfileStockistService.stockistImageUpload({'fileType': 'NORMAL'}, this.onFileChanged).subscribe(data => {
      this.submitting = false;
      this.submitted = false;
      this.getProfileImage();
    }, error => {
      this.submitting = false;
      this.submitted = false;
    });
  }

  getProfileImage() {
    this.submitting = true;
    this.manageProfileStockistService.getProfileImage({}).subscribe(data => {
      if (data && data.image) {
        this.profileImage = data.image;
      }
      this.submitting = false;
    }, error => {
      this.submitting = false;
    });
  }

  triggerScroll() {
    window.scrollTo(500, 500);
    window.scrollTo(500, 0);
  }
}
