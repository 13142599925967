<section>
  <ng-template #content>
    <div id="modal">
      <div class="modal-header font-weight-bold">
        <h4 class="modal-title" *ngIf="paymentType">{{paymentType}}</h4>
        <button class="close btn-close-modal" (click)="modalService.dismissAll('')">
          <span aria-hidden="true" class="close-modal-cross">x</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row mt--14px">
          <div class="col-12">
            <div class="row p-2 background-light-grey">
              <div class="col-12 text-center">
                <div class="row">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-capitalize">
                    <h6>Stockist Id - {{selectedStockist?.user_id}}</h6>
                  </div>
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-capitalize">
                    <h6>Stockist Name
                      - {{selectedStockist?.first_name.toLowerCase()}} {{selectedStockist?.last_name.toLowerCase()}}</h6>
                  </div>
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-capitalize">
                    <h6>Address - {{selectedStockist?.address.toLowerCase()}}</h6>
                  </div>
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-capitalize">
                    <h6>Landmark - {{selectedStockist?.landmark.toLowerCase()}}</h6>
                  </div>
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-capitalize">
                    <h6>State - {{selectedStockist?.state.toLowerCase()}}</h6>
                  </div>
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-capitalize">
                    <h6>Country - {{selectedStockist?.country.toLowerCase()}}</h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-1 p-2">
              <div class="col-12" *ngIf="transactionType === 'WALLET'">
                <div class="form-group">
                  <label>Available Wallet Amount - <span class="font-weight-bold">&#8377;{{walletBalance | IndianNumber}}</span></label>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label>Total Amount - <span class="font-weight-bold">&#8377;{{totalData?.total}}</span></label>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label for="password" class="d-flex">
                    Enter Password
                    <i (click)="inputTypePassword = 'text'"
                       [ngClass]="{'display-hidden': (inputTypePassword === 'text')}"
                       class="fas fa-eye-slash d-flex align-items-center pl-1 cursor-pointer"></i>
                    <i (click)="inputTypePassword = 'password'"
                       [ngClass]="{'display-hidden': (inputTypePassword === 'password')}"
                       class="fas fa-eye d-flex align-items-center pl-1  cursor-pointer"></i>
                  </label>
                  <input class="form-control" id="password" [type]="inputTypePassword" #password>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-success color-white pull-right" (click)="proceed(password.value)">
          Proceed <i class="fas fa-chevron-circle-right ml-2"></i>
        </button>
      </div>
    </div>
  </ng-template>

  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="card mt-4">
        <div class="card-header bottom-shadow-light">
          <div class="bar-success">
            <h4 class="card-title mb-3 ng-star-inserted">Update Address</h4>
          </div>
        </div>
        <div class="card-body mt-1">
          <form class="form" novalidate [formGroup]="updateAddressForm">
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="row">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center mb-2">
                    <h6>Billing Address</h6>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div class="form-group">
                      <label for="first_name_billing">First Name</label>
                      <input class="form-control" id="first_name_billing" type="text"
                             formControlName="first_name_billing">
                      <small *ngIf="submitted && updateAddressForm.get('first_name_billing').hasError('required')"
                             class="text-danger">
                        Field is required.
                      </small>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div class="form-group">
                      <label for="last_name_billing">Last Name</label>
                      <input class="form-control" id="last_name_billing" type="text"
                             formControlName="last_name_billing">
                      <small *ngIf="submitted && updateAddressForm.get('last_name_billing').hasError('required')"
                             class="text-danger">
                        Field is required.
                      </small>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div class="form-group">
                      <label for="email_billing">Email Id</label>
                      <input class="form-control" id="email_billing" type="text" formControlName="email_billing">
                      <small *ngIf="submitted && updateAddressForm.get('email_billing').hasError('required')"
                             class="text-danger">
                        Field is required.
                      </small>
                      <small
                        *ngIf="submitted && updateAddressForm.controls['email_billing'].errors && updateAddressForm.controls['email_billing'].errors.pattern"
                        class="text-danger">
                        Invalid email format.
                      </small>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div class="form-group">
                      <label for="phone_billing">Mobile No.</label>
                      <input class="form-control" id="phone_billing" type="text" formControlName="phone_billing">
                      <small *ngIf="submitted && updateAddressForm.get('phone_billing').hasError('required')"
                             class="text-danger">
                        Field is required.
                      </small>
                      <small
                        *ngIf="submitted && updateAddressForm.controls['phone_billing'].errors && updateAddressForm.controls['phone_billing'].errors.pattern"
                        class="text-danger">
                        Invalid mobile no. format.
                      </small>
                    </div>
                  </div>
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="form-group">
                      <label for="address_billing">Address</label>
                      <input class="form-control" id="address_billing" type="text" formControlName="address_billing">
                      <small *ngIf="submitted && updateAddressForm.get('address_billing').hasError('required')"
                             class="text-danger">
                        Field is required.
                      </small>
                    </div>
                  </div>
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="form-group">
                      <label for="landmark_billing">Nearest Landmark</label>
                      <textarea class="form-control resize-none" id="landmark_billing"
                                formControlName="landmark_billing" rows="5"></textarea>
                      <small *ngIf="submitted && updateAddressForm.get('landmark_billing').hasError('required')"
                             class="text-danger">
                        Field is required.
                      </small>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div class="form-group">
                      <label for="country_billing">Country</label>
                      <input class="form-control" id="country_billing" type="text" formControlName="country_billing">
                      <small *ngIf="submitted && updateAddressForm.get('country_billing').hasError('required')"
                             class="text-danger">
                        Field is required.
                      </small>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div class="form-group">
                      <label for="state_billing">State</label>
                      <select class="form-control" id="state_billing" formControlName="state_billing">
                        <option value="">Select State</option>
                        <option [value]="state?.name" *ngFor="let state of states">{{state?.name}}</option>
                      </select>
                      <small *ngIf="submitted && updateAddressForm.get('state_billing').hasError('required')"
                             class="text-danger">
                        Field is required.
                      </small>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-3">
                    <div class="form-group">
                      <label for="city_billing">City</label>
                      <input class="form-control" id="city_billing" type="text" formControlName="city_billing">
                      <small *ngIf="submitted && updateAddressForm.get('city_billing').hasError('required')"
                             class="text-danger">
                        Field is required.
                      </small>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-2">
                    <div class="form-group">
                      <label for="pin_billing">Pin Code</label>
                      <input class="form-control" id="pin_billing" type="number" formControlName="pin_billing">
                      <small *ngIf="submitted && updateAddressForm.get('pin_billing').hasError('required')"
                             class="text-danger">
                        Field is required.
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="row">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2 text-center">
                    <h6>Shipping Address
                      <small class="ml-2">
                        <input type="checkbox" class="mr-1" #shippingCheck
                               (change)="sameShipping(shippingCheck.checked)">Same as Billing Address
                      </small>
                    </h6>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div class="form-group">
                      <label for="first_name_shipping">First Name</label>
                      <input class="form-control" id="first_name_shipping" type="text"
                             formControlName="first_name_shipping">
                      <small *ngIf="submitted && updateAddressForm.get('first_name_shipping').hasError('required')"
                             class="text-danger">
                        Field is required.
                      </small>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div class="form-group">
                      <label for="last_name_shipping">Last Name</label>
                      <input class="form-control" id="last_name_shipping" type="text"
                             formControlName="last_name_shipping">
                      <small *ngIf="submitted && updateAddressForm.get('last_name_shipping').hasError('required')"
                             class="text-danger">
                        Field is required.
                      </small>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div class="form-group">
                      <label for="email_shipping">Email Id</label>
                      <input class="form-control" id="email_shipping" type="text" formControlName="email_shipping">
                      <small *ngIf="submitted && updateAddressForm.get('email_shipping').hasError('required')"
                             class="text-danger">
                        Field is required.
                      </small>
                      <small
                        *ngIf="submitted && updateAddressForm.controls['email_shipping'].errors && updateAddressForm.controls['email_shipping'].errors.pattern"
                        class="text-danger">
                        Invalid email format.
                      </small>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div class="form-group">
                      <label for="phone_shipping">Mobile No.</label>
                      <input class="form-control" id="phone_shipping" type="text" formControlName="phone_shipping">
                      <small *ngIf="submitted && updateAddressForm.get('phone_shipping').hasError('required')"
                             class="text-danger">
                        Field is required.
                      </small>
                      <small
                        *ngIf="submitted && updateAddressForm.controls['phone_shipping'].errors && updateAddressForm.controls['phone_shipping'].errors.pattern"
                        class="text-danger">
                        Invalid mobile no. format.
                      </small>
                    </div>
                  </div>
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="form-group">
                      <label for="address_shipping">Address</label>
                      <input class="form-control" id="address_shipping" type="text" formControlName="address_shipping">
                      <small *ngIf="submitted && updateAddressForm.get('address_shipping').hasError('required')"
                             class="text-danger">
                        Field is required.
                      </small>
                    </div>
                  </div>
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="form-group">
                      <label for="landmark_shipping">Nearest Landmark</label>
                      <textarea class="form-control resize-none" id="landmark_shipping"
                                formControlName="landmark_shipping" rows="5"></textarea>
                      <small *ngIf="submitted && updateAddressForm.get('landmark_shipping').hasError('required')"
                             class="text-danger">
                        Field is required.
                      </small>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div class="form-group">
                      <label for="country_shipping">Country</label>
                      <input class="form-control" id="country_shipping" type="text" formControlName="country_shipping">
                      <small *ngIf="submitted && updateAddressForm.get('country_shipping').hasError('required')"
                             class="text-danger">
                        Field is required.
                      </small>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div class="form-group">
                      <label for="state_shipping">State</label>
                      <select class="form-control" id="state_shipping" formControlName="state_shipping">
                        <option value="">Select State</option>
                        <option [value]="state?.name" *ngFor="let state of states">{{state?.name}}</option>
                      </select>
                      <small *ngIf="submitted && updateAddressForm.get('state_shipping').hasError('required')"
                             class="text-danger">
                        Field is required.
                      </small>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div class="form-group">
                      <label for="city_shipping">City</label>
                      <input class="form-control" id="city_shipping" type="text" formControlName="city_shipping">
                      <small *ngIf="submitted && updateAddressForm.get('city_shipping').hasError('required')"
                             class="text-danger">
                        Field is required.
                      </small>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div class="form-group">
                      <label for="pin_shipping">Pin Code</label>
                      <input class="form-control" id="pin_shipping" type="number" formControlName="pin_shipping">
                      <small *ngIf="submitted && updateAddressForm.get('pin_shipping').hasError('required')"
                             class="text-danger">
                        Field is required.
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="card-footer upper-shadow-light pb-3px">
          <button class="btn btn-danger color-white mb-2" (click)="back()">
            <i class="fas fa-window-close mr-1"></i>Cancel
          </button>
          <button class="btn btn-primary mb-2 pull-right" (click)="update(content, 'ONLINE')">
            <i class="fas fa-wallet mr-2" *ngIf="!submittingOnline"></i>
            <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submittingOnline"></i>Online Payment
          </button>
          <button class="btn btn-primary mb-2 mr-2 pull-right" (click)="update(content, 'WALLET')">
            <i class="fas fa-wallet mr-2" *ngIf="!submittingWallet"></i>
            <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submittingWallet"></i>Activation Wallet
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
