import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from '../../shared/services/auth.service';
import {GlobalService} from 'app/shared/services/global.service';
import {StorageService} from '../../shared/services/storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginUserForm: FormGroup;
  isBusy = false;
  loginPressed = false;
  loginType = 'USER';

  constructor(private router: Router,
              private authService: AuthService,
              private fb: FormBuilder,
              private globalService: GlobalService) {
  }

  ngOnInit(): void {
    if (this.checkLoggedInStatus()) {
      this.setSelfData();
      this.router.navigateByUrl('');
    }
    this.refresh();
  }

  refresh() {
    this.loginUserForm = this.fb.group({
      'username': ['', Validators.required],
      'password': ['', Validators.required]
    });
  }

  loginUser(type) {
    if (this.isBusy) {
      return;
    }
    this.isBusy = true;
    this.loginPressed = true;
    if (this.checkLoggedInStatus()) {
      this.setSelfData();
      this.router.navigateByUrl('');
    } else {
      if (type === 'USER') {
        this.authService.login(this.loginUserForm.getRawValue()).subscribe(data => {
          if (data && data.user_id) {
            this.globalService.setAccessToken(data.token);
            this.globalService.setSelf({
              'first_name': data.first_name,
              'last_name': data.last_name,
              'userId': data.user_id,
              'username': data.username,
              'email': data.email,
              'image': data.image,
              'userType': data.user_type
            });
            this.router.navigateByUrl('users/dashboard');
            this.loginUserForm.reset();
          }
          this.isBusy = false;
          this.loginPressed = false;
        }, error => {
          this.isBusy = false;
          this.loginPressed = false;
        });
      } else {
        this.authService.stockistLogin(this.loginUserForm.getRawValue()).subscribe(data => {
          if (data && data.user_id) {
            this.globalService.setAccessToken(data.token);
            this.globalService.setSelf({
              'first_name': data.first_name,
              'last_name': data.last_name,
              'userId': data.user_id,
              'username': data.username,
              'email': data.email,
              'image': data.image,
              'userType': data.user_type
            });
            this.router.navigateByUrl('stockists/dashboard');
            this.loginUserForm.reset();
          }
          this.isBusy = false;
          this.loginPressed = false;
        }, error => {
          this.isBusy = false;
          this.loginPressed = false;
        });
      }
    }
  }

  checkLoggedInStatus() {
    const accessToken = StorageService.getItem('accessToken');
    return accessToken && accessToken !== 'null';
  }

  setSelfData() {
    this.authService.self({all: true}).subscribe((data) => {
      this.isBusy = false;
      if (data) {
        this.globalService.setSelf({
          'first_name': (data && data.first_name) ? data.first_name : '',
          'last_name': (data && data.last_name) ? data.last_name : '',
          'userId': (data && data.user_id) ? data.user_id : '',
          'username': (data && data.username) ? data.username : '',
          'email': (data && data.email) ? data.email : '',
          'image': (data && data.image) ? data.image : '',
          'userType': (data && data.user_type) ? data.user_type : '',
        });
      }
      return;
    }, e => {
      this.isBusy = false;
      StorageService.clearAll();
    });
  }
}
