<section>
  <div class="row mt-2 mb-2">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="content-header f_20 module-header ml-2 mt-18px">Official Announcements</div>
    </div>
  </div>
  <div class="row p-sm-2 d-none d-md-block overflow-x-scroll">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <table class="table table-list overflow-x-scroll">
        <thead>
        <tr>
          <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">S.No</h6></th>
          <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Title</h6></th>
          <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">View</h6></th>
        </tr>
        </thead>
        <tbody *ngIf="officialAnnouncements && officialAnnouncements.length > 0; else noDataToShowWeb">
        <tr *ngFor="let official of officialAnnouncements; let i = index">
          <td class="text-center">
            <h6 class="mt-1">{{i + 1}}.</h6>
          </td>
          <td class="text-center"><h6 class="mt-1">{{official?.title}}</h6></td>
          <td class="text-center">
            <h6 class="mt-1 text-center text-info cursor-pointer" (click)="navigateTo(official)">View</h6>
          </td>
        </tr>
        </tbody>
        <ng-template #noDataToShowWeb>
          <tbody>
          <tr>
            <td colspan="3" class="text-center"><h6>No data to show</h6></td>
          </tr>
          </tbody>
        </ng-template>
      </table>
    </div>
  </div>

  <div class="row d-block d-md-none"
       *ngIf="officialAnnouncements && officialAnnouncements.length > 0; else noDataToShowMobile">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="card" *ngFor="let official of officialAnnouncements; let i = index">
        <div class="card-header bottom-shadow-light pb-18px">
          <div class="row">
            <div class="col-12">
              <h4
                class="card-title text-uppercase card-ellipsis pb-3">{{i + 1}}. {{official?.title}}</h4>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row p-2">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
              View - <span class="text-info" (click)="navigateTo(official)">View</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #noDataToShowMobile>
    <div class="row d-block d-md-none">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card text-center">
          <h5 class="p-5">No data to show</h5>
        </div>
      </div>
    </div>
  </ng-template>
</section>
