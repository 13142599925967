import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {StorageService} from '../../../shared/services/storage.service';

@Component({
  selector: 'app-shop-success',
  templateUrl: './shop-success.component.html',
  styleUrls: ['./shop-success.component.scss']
})
export class StockistShopSuccessUserComponent implements OnInit {
  invoiceUrl: any;

  constructor(public router: Router,
              private _location: Location) {
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    this.invoiceUrl = StorageService.getItem('invoiceUrl');
    if (!this.invoiceUrl) {
      this.back();
    } else {
      StorageService.removeItem('cartItems');
      StorageService.removeItem('selectedUser');
    }
  }

  navigateTo() {
    window.open(this.invoiceUrl, '_blank');
  }

  back() {
    this._location.back();
  }
}
