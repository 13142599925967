import {Component, OnInit} from '@angular/core';
import {MyWalletTransactionHistoryService} from '../../../shared/services/my-wallet-transaction-history.service';

@Component({
  selector: 'app-my-wallet-transaction-history',
  templateUrl: './my-wallet-transaction-history.component.html',
  styleUrls: ['./my-wallet-transaction-history.component.scss']
})
export class MyWalletTransactionHistoryComponent implements OnInit {
  submitting = false;
  myWalletTransactionHistory = [];
  total: any = 0;
  pagination = {
    page: 1,
    total: null,
    perpage: 15,
    pages: null
  };

  constructor(public myWalletTransactionHistoryService: MyWalletTransactionHistoryService) {
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    this.get();
  }

  get(params: any = {}) {
    params.page = this.pagination.page;
    params.perpage = this.pagination.perpage;
    this.submitting = true;
    this.myWalletTransactionHistory = [];
    this.myWalletTransactionHistoryService.get(params).subscribe(data => {
      if (data && data['data'] && data['data'].length > 0) {
        this.myWalletTransactionHistory = data['data'];
        this.pagination = {
          page: data['pagination'].page,
          total: data['pagination'].total,
          perpage: data['pagination'].perpage,
          pages: data['pagination'].pages,
        };
        this.total = data.total_commission;
        this.triggerScroll();
      } else {
        this.myWalletTransactionHistory = [];
      }
      this.submitting = false;
    }, error => {
      this.submitting = false;
    });
  }

  triggerScroll() {
    window.scrollTo(500, 500);
    window.scrollTo(500, 0);
  }

  onPageChange(page) {
    this.pagination.page = page;
    this.get();
  }
}
