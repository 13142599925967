<section>
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="card mt-4">
        <div class="card-header bottom-shadow-light">
          <div class="bar-success">
            <h4 class="card-title mb-3 ng-star-inserted">Beneficiery Info</h4>
          </div>
        </div>
        <div class="card-body mt-1">
          <form class="form" novalidate [formGroup]="beneficieryInfoForm">
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label for="ben_fullname">Nominee Full Name</label>
                  <input class="form-control" id="ben_fullname" type="text" formControlName="ben_fullname">
                  <small *ngIf="submitted && beneficieryInfoForm.get('ben_fullname').hasError('required')"
                         class="text-danger">
                    Field is required.
                  </small>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label for="ben_bank">Nominee Bank Name</label>
                  <input class="form-control" id="ben_bank" type="text" formControlName="ben_bank">
                  <small *ngIf="submitted && beneficieryInfoForm.get('ben_bank').hasError('required')"
                         class="text-danger">
                    Field is required.
                  </small>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label for="ben_acc_no">Account Number</label>
                  <input class="form-control" id="ben_acc_no" type="text" formControlName="ben_acc_no">
                  <small *ngIf="submitted && beneficieryInfoForm.get('ben_acc_no').hasError('required')"
                         class="text-danger">
                    Field is required.
                  </small>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label for="ben_acc_pin">Swift Code</label>
                  <input class="form-control" id="ben_acc_pin" type="text" formControlName="ben_acc_pin">
                  <small *ngIf="submitted && beneficieryInfoForm.get('ben_acc_pin').hasError('required')"
                         class="text-danger">
                    Field is required.
                  </small>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="card-footer upper-shadow-light pb-3px">
          <button class="btn btn-danger color-white mb-2" (click)="back()">
            <i class="fas fa-window-close mr-1"></i>Cancel
          </button>
          <button class="btn btn-success color-white mb-2 pull-right" [disabled]="submitting" (click)="update()">
            <i class="fas fa-save mr-1" *ngIf="!submitting"></i>
            <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>Update
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
