<section>
  <div class="row mt-2 mb-2">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="content-header f_20 module-header ml-2 mt-18px">Privacy Policies</div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="card min-vh-50 p-5" [innerText]="privacyPolicies">
      </div>
    </div>
  </div>
</section>
