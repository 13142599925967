<section>
  <ng-template #content>
    <div id="modal">
      <div class="modal-header font-weight-bold">
        <h4 class="modal-title">Items in cart</h4>
        <button class="close btn-close-modal" (click)="modalService.dismissAll('')">
          <span aria-hidden="true" class="close-modal-cross">x</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row mt--14px">
          <div class="col-12">
            <div class="row p-2">
              <div class="col-12">
                <div class="row">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    There already exists few items in your cart which belongs to another stockist, performing this
                    action will change the stockist and cart will be reset. <br>Do you really want to proceed ?
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer text-left">
        <button class="btn btn-primary color-white pull-right" (click)="modalService.dismissAll('')">No</button>
        <button class="btn btn-danger color-white pull-right" (click)="yes()">Yes</button>
      </div>
    </div>
  </ng-template>

  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="container">
        <div class="card mt-4">
          <div class="card-header bottom-shadow-light">
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="bar-success">
                  <h4 class="card-title mb-3 ng-star-inserted">Stockist</h4>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <button class="btn btn-primary color-white pull-right mt--8px" (click)="navigateTo('users/eshop/products')"
                        *ngIf="cartItems && cartItems.length > 0">View Products
                </button>
              </div>
            </div>
          </div>
          <div class="card-body mt-1 bottom-shadow-light">
            <div class="row mb-2">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <select class="form-control text-capitalize" (change)="getProducts($event)">
                  <option value="">Select Stockist</option>
                  <option [value]="stockist.user_id"
                          *ngFor="let stockist of stockists"
                          class="text-capitalize">{{stockist.first_name.toLowerCase()}} {{stockist.last_name.toLowerCase()}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="card-footer" *ngIf="selectedStockist">
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="productCount > 0">
                <button class="btn btn-primary pull-right" (click)="proceed(content)">
                  Proceed <i class="fas fa-arrow-right ml-2"></i>
                </button>
              </div>
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center" *ngIf="productCount === 0">
                <h6>No product found, Please select another stockist</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
