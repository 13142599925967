import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AlertService} from '../../../shared/services/alert.service';
import {Location} from '@angular/common';
import {UpdateAdddressService} from '../../../shared/services/update-adddress.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {StateService} from '../../../shared/services/state.service';
import {WalletService} from '../../../shared/services/wallet.service';
import {StorageService} from '../../../shared/services/storage.service';
import {UpdateAddressStockistService} from '../../../shared/services/update-address-stockist.service';
import {GlobalService} from '../../../shared/services/global.service';

@Component({
  selector: 'app-update-address',
  templateUrl: './update-address.component.html',
  styleUrls: ['./update-address.component.scss']
})
export class StockistUserUpdateAddressComponent implements OnInit {
  updateAddressForm: FormGroup;
  submitted: any = false;
  submittingOnline: any = false;
  submittingWallet: any = false;
  states: any = [];
  paymentType: any;
  selectedUser: any;
  cartItems: any = [];
  totalData: any;
  walletBalance: any;
  inputTypePassword: any = 'password';
  transactionType: any;

  constructor(private router: Router,
              private fb: FormBuilder,
              private alertService: AlertService,
              private _location: Location,
              private updateAddressService: UpdateAdddressService,
              public modalService: NgbModal,
              public stateService: StateService,
              private walletService: WalletService,
              private globalService: GlobalService,
              private updateAddressStockistService: UpdateAddressStockistService) {
  }

  ngOnInit() {
    this.refresh();
  }

  refresh() {
    this.selectedUser = StorageService.getItem('selectedUser');
    this.cartItems = StorageService.getItem('cartItems');

    if (!this.selectedUser || !this.cartItems) {
      this.back();
    }

    this.updateAddressForm = this.fb.group({
      'first_name_billing': ['', Validators.required],
      'last_name_billing': ['', Validators.required],
      'email_billing': ['', Validators.compose([Validators.required, Validators.pattern('^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')])],
      'phone_billing': ['', Validators.compose([Validators.required, Validators.pattern('^[5-9]{1}[0-9]{9}$')])],
      'address_billing': ['', Validators.required],
      'landmark_billing': ['', Validators.required],
      'country_billing': [{value: 'India', 'disabled': true}],
      'state_billing': ['', Validators.required],
      'city_billing': ['', Validators.required],
      'pin_billing': ['', Validators.required],
      'first_name_shipping': ['', Validators.required],
      'last_name_shipping': ['', Validators.required],
      'email_shipping': ['', Validators.compose([Validators.required, Validators.pattern('^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')])],
      'phone_shipping': ['', Validators.compose([Validators.required, Validators.pattern('^[5-9]{1}[0-9]{9}$')])],
      'address_shipping': ['', Validators.required],
      'landmark_shipping': ['', Validators.required],
      'country_shipping': [{value: 'India', 'disabled': true}],
      'state_shipping': ['', Validators.required],
      'city_shipping': ['', Validators.required],
      'pin_shipping': ['', Validators.required],
    });
    this.getStates();
  }

  getStates() {
    this.stateService.get({'country_id': 101}).subscribe(data => {
      this.states = data;
    });
  }

  back() {
    this._location.back();
  }

  sameShipping(event) {
    this.updateAddressForm.patchValue({
      'first_name_shipping': (event && this.updateAddressForm.value && this.updateAddressForm.value.first_name_billing) ? this.updateAddressForm.value.first_name_billing : '',
      'last_name_shipping': (event && this.updateAddressForm.value && this.updateAddressForm.value.last_name_billing) ? this.updateAddressForm.value.last_name_billing : '',
      'email_shipping': (event && this.updateAddressForm.value && this.updateAddressForm.value.email_billing) ? this.updateAddressForm.value.email_billing : '',
      'phone_shipping': (event && this.updateAddressForm.value && this.updateAddressForm.value.phone_billing) ? this.updateAddressForm.value.phone_billing : '',
      'address_shipping': (event && this.updateAddressForm.value && this.updateAddressForm.value.address_billing) ? this.updateAddressForm.value.address_billing : '',
      'landmark_shipping': (event && this.updateAddressForm.value && this.updateAddressForm.value.landmark_billing) ? this.updateAddressForm.value.landmark_billing : '',
      'country_shipping': (this.updateAddressForm.value && this.updateAddressForm.getRawValue().country_billing) ? this.updateAddressForm.getRawValue().country_billing : '',
      'state_shipping': (event && this.updateAddressForm.value && this.updateAddressForm.value.state_billing) ? this.updateAddressForm.value.state_billing : '',
      'city_shipping': (event && this.updateAddressForm.value && this.updateAddressForm.value.city_billing) ? this.updateAddressForm.value.city_billing : '',
      'pin_shipping': (event && this.updateAddressForm.value && this.updateAddressForm.value.pin_billing) ? this.updateAddressForm.value.pin_billing : '',
    });
  }

  update(content, type) {
    this.submitted = true;
    if (!this.updateAddressForm.valid) {
      return;
    }
    if (this.submitted) {
      const params = {
        'selected_user_id': this.selectedUser.user_id,
        'first_name_billing': (this.updateAddressForm.value && this.updateAddressForm.getRawValue().first_name_billing) ? this.updateAddressForm.getRawValue().first_name_billing : '',
        'last_name_billing': (this.updateAddressForm.value && this.updateAddressForm.getRawValue().last_name_billing) ? this.updateAddressForm.getRawValue().last_name_billing : '',
        'email_billing': (this.updateAddressForm.value && this.updateAddressForm.getRawValue().email_billing) ? this.updateAddressForm.getRawValue().email_billing : '',
        'phone_billing': (this.updateAddressForm.value && this.updateAddressForm.getRawValue().phone_billing) ? this.updateAddressForm.getRawValue().phone_billing : '',
        'address_billing': (this.updateAddressForm.value && this.updateAddressForm.getRawValue().address_billing) ? this.updateAddressForm.getRawValue().address_billing : '',
        'landmark_billing': (this.updateAddressForm.value && this.updateAddressForm.getRawValue().landmark_billing) ? this.updateAddressForm.getRawValue().landmark_billing : '',
        'country_billing': (this.updateAddressForm.value && this.updateAddressForm.getRawValue().country_billing) ? this.updateAddressForm.getRawValue().country_billing : '',
        'state_billing': (this.updateAddressForm.value && this.updateAddressForm.getRawValue().state_billing) ? this.updateAddressForm.getRawValue().state_billing : '',
        'city_billing': (this.updateAddressForm.value && this.updateAddressForm.getRawValue().city_billing) ? this.updateAddressForm.getRawValue().city_billing : '',
        'pin_billing': (this.updateAddressForm.value && this.updateAddressForm.getRawValue().pin_billing) ? this.updateAddressForm.getRawValue().pin_billing : '',
        'first_name_shipping': (this.updateAddressForm.value && this.updateAddressForm.getRawValue().first_name_shipping) ? this.updateAddressForm.getRawValue().first_name_shipping : '',
        'last_name_shipping': (this.updateAddressForm.value && this.updateAddressForm.getRawValue().last_name_shipping) ? this.updateAddressForm.getRawValue().last_name_shipping : '',
        'email_shipping': (this.updateAddressForm.value && this.updateAddressForm.getRawValue().email_shipping) ? this.updateAddressForm.getRawValue().email_shipping : '',
        'phone_shipping': (this.updateAddressForm.value && this.updateAddressForm.getRawValue().phone_shipping) ? this.updateAddressForm.getRawValue().phone_shipping : '',
        'address_shipping': (this.updateAddressForm.value && this.updateAddressForm.getRawValue().address_shipping) ? this.updateAddressForm.getRawValue().address_shipping : '',
        'landmark_shipping': (this.updateAddressForm.value && this.updateAddressForm.getRawValue().landmark_shipping) ? this.updateAddressForm.getRawValue().landmark_shipping : '',
        'country_shipping': (this.updateAddressForm.value && this.updateAddressForm.getRawValue().country_shipping) ? this.updateAddressForm.getRawValue().country_shipping : '',
        'state_shipping': (this.updateAddressForm.value && this.updateAddressForm.getRawValue().state_shipping) ? this.updateAddressForm.getRawValue().state_shipping : '',
        'city_shipping': (this.updateAddressForm.value && this.updateAddressForm.getRawValue().city_shipping) ? this.updateAddressForm.getRawValue().city_shipping : '',
        'pin_shipping': (this.updateAddressForm.value && this.updateAddressForm.getRawValue().pin_shipping) ? this.updateAddressForm.getRawValue().pin_shipping : '',
      };
      this.submittingOnline = (type === 'ONLINE');
      this.submittingWallet = (type === 'WALLET');
      this.updateAddressStockistService.update(params).subscribe(data => {
        if (data && data.invoice_no) {
          StorageService.setItem('invoice_no', data.invoice_no)
        }
        this.submittingOnline = false;
        this.submittingWallet = false;
        this.submitted = false;
        this.makePayment(content, type);
        this.triggerScroll();
      }, error => {
        this.submittingOnline = false;
        this.submittingWallet = false;
        this.submitted = false;
      });
    }
  }

  makePayment(content, type) {
    if (type === 'ONLINE') {
      this.paymentType = 'Online Payment';
    } else if (type === 'WALLET') {
      this.paymentType = 'Fund Wallet Payment';
    }
    this.setTaxesAndTotal(content, type)
  }

  setTaxesAndTotal(content, type) {
    if (this.cartItems && this.cartItems.length > 0) {
      let gstArr = [];
      let subTotalArr = [];
      let totalArr = [];
      this.cartItems.forEach(cart => {
        cart['singleItemTax'] = cart['tax'];
        cart['gst'] = (parseFloat(cart['tax']) * parseInt(cart['qty'])).toFixed(2);
        cart['subTotal'] = (parseFloat(cart['price']) * parseInt(cart['qty'])).toFixed(2);
        cart['total'] = (parseFloat(cart['subTotal']) + parseFloat(cart['gst'])).toFixed(2);
        gstArr.push(cart['gst']);
        subTotalArr.push(cart['subTotal']);
        totalArr.push(cart['total']);
      });
      this.totalData = {'gstTotal': gstArr.reduce((a, b) => parseFloat(a) + parseFloat(b), 0).toFixed(2)};
      this.totalData['subTotal'] = subTotalArr.reduce((a, b) => parseFloat(a) + parseFloat(b), 0).toFixed(2)
      this.totalData['total'] = totalArr.reduce((a, b) => parseFloat(a) + parseFloat(b), 0).toFixed(2);

      if (type === 'WALLET') {
        this.transactionType = 'WALLET';
        this.fetchWalletBalance(content)
      } else {
        this.transactionType = 'ONLINE';
        this.modalService.open(content, {'size': 'lg'});
      }
    }
  }

  fetchWalletBalance(content) {
    this.walletService.getFundWalletAmount({}).subscribe(data => {
      if (data && data['wallet_balance']) {
        this.walletBalance = (data && data['wallet_balance']) ? data['wallet_balance'].replace(/,/g, '') : 0;
      }
      this.modalService.open(content, {'size': 'lg'});
    });
  }

  proceed(data) {
    this.walletService.verifyStockistPassword({'password': data}).subscribe(data => {
      if (data && data.status === 'success') {
        this.fundWalletPurchase();
      }
    });
  }

  fundWalletPurchase() {
    let cartItems = StorageService.getItem('cartItems');
    let invoice_no = StorageService.getItem('invoice_no');
    let selectedUser = StorageService.getItem('selectedUser');
    if (cartItems && cartItems.length > 0) {
      let products = [];
      cartItems.forEach(item => {
        products.push({
          'product_id': item?.product_id,
          'price': item?.price,
          'points': item?.points,
          'tax': item?.singleItemTax,
          'quantity': item?.qty
        })
      });
      let params = {
        'invoice_no': invoice_no,
        'selected_user_id': selectedUser['user_id'],
        'products': products,
        'sub_total': this.totalData?.subTotal,
        'tax': this.totalData?.gstTotal,
        'total': this.totalData?.total,
      };
      this.walletService.fundWalletUserPurchase(params).subscribe(data => {
        if (data && data.invoiceUrl) {
          let invoiceUrl = `https://eshopping.axicoayurveda.com/dashboard/${data.invoiceUrl}`;
          StorageService.setItem('invoiceUrl', invoiceUrl);
          this.globalService.changeCartCount([]);
          this.modalService.dismissAll('');
          this.router.navigateByUrl('/stockists/billing-orders/shop-success');
        }
      });
    }
  }

  triggerScroll() {
    window.scrollTo(500, 500);
    window.scrollTo(500, 0);
  }
}
