<section>
  <div class="row mt-2">
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
      <h5>User Id : {{dashboard?.user_id}}</h5>
      <h5>Username : {{dashboard?.username}}</h5>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
      <h5 class="pull-right mr-1 mt-2">Wallet Balance : &#8377;{{dashboard?.wallet_balance}}</h5>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
      <div class="card gradient-purple-love">
        <div class="card-content">
          <div class="card-body py-0">
            <div class="media pb-1">
              <div class="media-body white text-left text-capitalize">
                <h3 class="white mb-0" *ngIf="dashboard">
                  {{dashboard?.my_total_sale_pv | IndianNumber}}.00
                </h3>
              </div>
              <div class="media-right white text-right">
                <i class="fas fa-star font-large-1"></i>
              </div>
            </div>
          </div>
          <div class="row p-2">
            <div class="col-12 text-center">
              <small class="color-white">
                My Total Sale PV
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
      <div class="card gradient-ibiza-sunset">
        <div class="card-content">
          <div class="card-body py-0">
            <div class="media pb-1">
              <div class="media-body white text-left text-capitalize">
                <h3 class="white mb-0" *ngIf="dashboard">
                  {{dashboard?.my_total_bonus | IndianNumber}}.00
                </h3>
              </div>
              <div class="media-right white text-right">
                <i class="fas fa-plus-circle font-large-1"></i>
              </div>
            </div>
          </div>
          <div class="row p-2">
            <div class="col-12 text-center">
              <small class="color-white">
                My Total Bonus
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
      <div class="card gradient-mint">
        <div class="card-content">
          <div class="card-body py-0">
            <div class="media pb-1">
              <div class="media-body white text-left text-capitalize">
                <h3 class="white mb-0" *ngIf="dashboard">
                  {{dashboard?.total_paid_invoice | IndianNumber}}.00
                </h3>
              </div>
              <div class="media-right white text-right">
                <i class="fas fa-file-alt font-large-1"></i>
              </div>
            </div>
          </div>
          <div class="row p-2">
            <div class="col-12 text-center">
              <small class="color-white">
                Total Paid Invoice
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
      <div class="card gradient-king-yna">
        <div class="card-content">
          <div class="card-body py-0">
            <div class="media pb-1">
              <div class="media-body white text-left text-capitalize">
                <h3 class="white mb-0" *ngIf="dashboard">
                  {{dashboard?.total_pending_invoice | IndianNumber}}.00
                </h3>
              </div>
              <div class="media-right white text-right">
                <i class="fas fa-file font-large-1"></i>
              </div>
            </div>
          </div>
          <div class="row p-2">
            <div class="col-12 text-center">
              <small class="color-white">
                Total Pending Invoice
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12"></div>
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
      <div class="card">
        <div class="card-header bottom-shadow-light">
          <h4 class="mb-3">Personal Information</h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12 text-center">
              <img class="border-radius-50"
                   [src]="'https://eshopping.axicoayurveda.com/franchisepanel/images/'+((dashboard && dashboard['profile_image']) ? dashboard['profile_image'] : 'male.jpg')"
                   width="100" height="100"/>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12 text-center">
              {{dashboard?.name}}
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-12 text-center">
              @{{dashboard?.username}}
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-12 text-center">
              {{dashboard?.state}}, {{dashboard?.country}}
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-12 text-center">
              <i class="fas fa-circle mr-2"
                 [ngClass]="{'text-success': (dashboard && (dashboard['account_status'].toLowerCase() === 'active')), 'text-danger': (dashboard && (dashboard['account_status'].toLowerCase() !== 'active'))}"></i>
              <span>{{dashboard?.account_status}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12"></div>
  </div>
</section>
