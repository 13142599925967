import {Component, OnInit} from '@angular/core';
import {MyWalletService} from '../../../shared/services/my-wallet.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-my-wallet',
  templateUrl: './my-wallet.component.html',
  styleUrls: ['./my-wallet.component.scss']
})
export class MyWalletComponent implements OnInit {
  submitting = false;
  myWallets = [];

  constructor(public myWalletsService: MyWalletService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    this.get();
  }

  get(params: any = {}) {
    this.submitting = true;
    this.myWallets = [];
    this.myWalletsService.get(params).subscribe(data => {
      if (data && data && data.length > 0) {
        this.myWallets = data;
        this.triggerScroll();
      } else {
        this.myWallets = [];
      }
      this.submitting = false;
    }, error => {
      this.submitting = false;
    });
  }

  view(wallet) {
    console.log('wallet', wallet);
  }

  navigateTo(url) {
    this.router.navigateByUrl(url)
  }

  triggerScroll() {
    window.scrollTo(500, 500);
    window.scrollTo(500, 0);
  }
}
