import {Component, OnInit} from '@angular/core';
import {StockistDashboardService} from '../../shared/services/stockist-dashboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class StockistDashboardComponent implements OnInit {
  dashboard: any;

  constructor(private stockistDashboardService: StockistDashboardService) {
  }

  ngOnInit() {
    this.refresh();
  }

  refresh() {
    this.get();
  }

  get() {
    this.stockistDashboardService.get().subscribe(data => {
      if (data) {
        data['my_total_sale_pv'] = (data && data['my_total_sale_pv']) ? data['my_total_sale_pv'].replace(/,/g, '') : 0;
        data['my_total_bonus'] = (data && data['my_total_bonus']) ? data['my_total_bonus'].replace(/,/g, '') : 0;
        data['total_paid_invoice'] = (data && data['total_paid_invoice']) ? data['total_paid_invoice'] : 0;
        data['total_pending_invoice'] = (data && data['total_pending_invoice']) ? data['total_pending_invoice'] : 0;
        this.dashboard = data
      }
      this.triggerScroll();
    }, error => {
    });
  }

  triggerScroll() {
    window.scrollTo(500, 500);
    window.scrollTo(500, 0);
  }
}
