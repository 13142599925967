<section>
  <ng-template #content>
    <div id="modal">
      <div class="modal-header font-weight-bold">
        <h4 class="modal-title">New Registration</h4>
        <button class="close btn-close-modal" (click)="modalService.dismissAll('')">
          <span aria-hidden="true" class="close-modal-cross">x</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form" novalidate [formGroup]="registrationForm">
          <div class="row mt--14px">
            <div class="col-12">
              <div class="row p-2">
                <div class="col-12">
                  <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div class="form-group">
                        <label for="sponsor_id">Sponsor Id</label>
                        <input class="form-control" id="sponsor_id" type="text" formControlName="sponsor_id">
                        <input class="form-control" id="nomination_id" type="hidden" formControlName="nomination_id">
                        <small *ngIf="submitted && registrationForm.get('sponsor_id').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div class="form-group">
                        <label for="type">Type</label>
                        <select class="form-control" id="type" formControlName="type">
                          <option value="">Select Type</option>
                          <option value="LEFT">Left</option>
                          <option value="RIGHT">Right</option>
                        </select>
                        <small *ngIf="submitted && registrationForm.get('type').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div class="form-group">
                        <label for="username">Enter Username</label>
                        <input class="form-control" id="username" type="text" formControlName="username">
                        <small *ngIf="submitted && registrationForm.get('username').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div class="form-group">
                        <label for="password">Enter Password</label>
                        <input class="form-control" id="password" type="password" formControlName="password">
                        <small *ngIf="submitted && registrationForm.get('password').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div class="form-group">
                        <label for="confirm_password">Confirm Password</label>
                        <input class="form-control" id="confirm_password" type="password"
                               formControlName="confirm_password">
                        <small *ngIf="submitted && registrationForm.get('confirm_password').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div class="form-group">
                        <label for="first_name">Enter Member First Name</label>
                        <input class="form-control" id="first_name" type="text" formControlName="first_name">
                        <small *ngIf="submitted && registrationForm.get('first_name').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div class="form-group">
                        <label for="last_name">Enter Member Last Name</label>
                        <input class="form-control" id="last_name" type="text" formControlName="last_name">
                        <small *ngIf="submitted && registrationForm.get('last_name').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div class="form-group">
                        <label for="email">Enter Member Email Name</label>
                        <input class="form-control" id="email" type="text" formControlName="email">
                        <small *ngIf="submitted && registrationForm.get('email').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                        <small
                          *ngIf="submitted && registrationForm.controls['email'].errors && registrationForm.controls['email'].errors.pattern"
                          class="text-danger">
                          Invalid email format.
                        </small>
                      </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div class="form-group">
                        <label for="telephone">Enter Member Phone Number</label>
                        <input class="form-control" id="telephone" type="number" formControlName="telephone">
                        <small *ngIf="submitted && registrationForm.get('telephone').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div class="form-group">
                        <label for="country">Country</label>
                        <select class="form-control" id="country" formControlName="country">
                          <option value="">Select Country</option>
                          <option [value]="country.nicename"
                                  *ngFor="let country of countries">{{country?.nicename}}</option>
                        </select>
                        <small *ngIf="submitted && registrationForm.get('country').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div class="form-group">
                        <label for="state">State</label>
                        <select class="form-control" id="state" formControlName="state">
                          <option value="">Select State</option>
                          <option [value]="state?.name" *ngFor="let state of states">{{state?.name}}</option>
                        </select>
                        <small *ngIf="submitted && registrationForm.get('state').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div class="form-group">
                        <label for="address">Enter Member Address</label>
                        <textarea class="form-control resize-none" id="address" type="text" formControlName="address"
                                  rows="3"></textarea>
                        <small *ngIf="submitted && registrationForm.get('address').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div class="form-group">
                        <label for="city">Enter Member City</label>
                        <input class="form-control" id="city" type="text" formControlName="city"/>
                        <small *ngIf="submitted && registrationForm.get('city').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div class="form-group">
                        <label for="zip_code">Zip Code</label>
                        <input class="form-control" id="zip_code" type="text" formControlName="zip_code"/>
                        <small *ngIf="submitted && registrationForm.get('zip_code').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer text-left">
        <button class="btn btn-danger color-white" (click)="modalService.dismissAll('')">Cancel</button>
        <button class="btn btn-success color-white pull-right" (click)="register()">Register</button>
      </div>
    </div>
  </ng-template>

  <div class="row mt-2 mb-2">
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
      <div class="content-header f_20 module-header ml-2 mt-18px">Matching Tree</div>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
      <button class="btn btn-primary pull-right mt-2" (click)="getSelfMatchingTree()">Self Tree</button>
    </div>
  </div>

  <div class="row">
    <div class="col-12 text-center">
      <div class="tf-tree" *ngIf="matchingTree && matchingTree.length > 0">
        <ul>
          <li>
            <div class="mb-2 tree-tooltip p-1 tooltip-1" *ngIf="showTooltip1 && matchingTree[0]?.user_id">
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                  <h6>User Id</h6>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                  <h6>{{matchingTree[0]?.user_id}}</h6>
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                  <h6>Full Name</h6>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                  <h6 class="text-capitalize">{{matchingTree[0]?.name.toLowerCase()}}</h6>
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                  <h6>Country</h6>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                  <h6 class="text-capitalize">{{matchingTree[0]?.country.toLowerCase()}}</h6>
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                  <h6>Sponsor Id</h6>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                  <h6 class="text-capitalize">{{matchingTree[0]?.sponsor_id}}</h6>
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                  <h6>User Status</h6>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                  <h6 class="text-capitalize">{{matchingTree[0]?.user_status}}</h6>
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                  <h6>Total Users</h6>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                  <h6 class="text-capitalize">{{matchingTree[0]?.total_users}}</h6>
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                  <h6>Total Business Volume</h6>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                  <h6 class="text-capitalize">{{matchingTree[0]?.total_business_volume}}</h6>
                </div>
              </div>
            </div>
            <div class="tf-nc cursor-pointer" (mouseover)="showTooltip1 = true" (mouseout)="showTooltip1 = false"
                 (click)="getUserTree(content, matchingTree[0], null)">
              <div class="mb-2 bottom-shadow-light">
                <h6 class="p-1">{{matchingTree[0]?.package}}</h6>
              </div>
              <div class="pl-20px pr-20px">
                <img
                  [src]="'https://eshopping.axicoayurveda.com/dashboard/images/'+matchingTree[0].image"
                  width="70" height="100" *ngIf="matchingTree[0].image"/>
                <img src="https://eshopping.axicoayurveda.com/dashboard/images/member1.png" width="70" height="100"
                     *ngIf="!matchingTree[0].image"/>
              </div>
              <div class="p-1">
                <h6>{{matchingTree[0]?.username}}</h6>
                <h6>{{matchingTree[0]?.registration_date}}</h6>
              </div>
              <div class="mt--1 mb-1" *ngIf="!matchingTree[0].user_id">
                <button class="btn btn-sm btn-outline-primary">Register</button>
              </div>
            </div>
            <ul>
              <li>
                <div class="mb-2 tree-tooltip p-1 tooltip-2"
                     *ngIf="showTooltip2 && matchingTree[0]['childs'][0]?.user_id">
                  <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                      <h6>User Id</h6>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                      <h6>{{matchingTree[0]['childs'][0]?.user_id}}</h6>
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                      <h6>Full Name</h6>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                      <h6 class="text-capitalize">{{matchingTree[0]['childs'][0]?.name.toLowerCase()}}</h6>
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                      <h6>Country</h6>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                      <h6 class="text-capitalize">{{matchingTree[0]['childs'][0]?.country.toLowerCase()}}</h6>
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                      <h6>Sponsor Id</h6>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                      <h6 class="text-capitalize">{{matchingTree[0]['childs'][0]?.sponsor_id}}</h6>
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                      <h6>User Status</h6>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                      <h6 class="text-capitalize">{{matchingTree[0]['childs'][0]?.user_status}}</h6>
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                      <h6>Total Users</h6>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                      <h6 class="text-capitalize">{{matchingTree[0]['childs'][0]?.total_users}}</h6>
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                      <h6>Total Business Volume</h6>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                      <h6 class="text-capitalize">{{matchingTree[0]['childs'][0]?.total_business_volume}}</h6>
                    </div>
                  </div>
                </div>
                <div class="tf-nc cursor-pointer" (mouseover)="showTooltip2 = true" (mouseout)="showTooltip2 = false"
                     (click)="getUserTree(content, matchingTree[0]['childs'][0], matchingTree[0])">
                  <div class="mb-2 bottom-shadow-light">
                    <h6 class="p-1">{{matchingTree[0]['childs'][0]?.package}}</h6>
                  </div>
                  <div class="pl-20px pr-20px">
                    <img
                      [src]="'https://eshopping.axicoayurveda.com/dashboard/images/'+matchingTree[0]['childs'][0].image"
                      width="70" height="100" *ngIf="matchingTree[0]['childs'][0].image"/>
                    <img src="https://eshopping.axicoayurveda.com/dashboard/images/member1.png" width="70"
                         height="100" *ngIf="!matchingTree[0]['childs'][0].image"/>
                  </div>
                  <div class="p-1">
                    <h6>{{matchingTree[0]['childs'][0]?.username}}</h6>
                    <h6>{{matchingTree[0]['childs'][0]?.registration_date}}</h6>
                  </div>
                  <div class="mt--1 mb-1" *ngIf="!matchingTree[0]['childs'][0].user_id">
                    <button class="btn btn-sm btn-outline-primary">Register</button>
                  </div>
                </div>
                <ul>
                  <li>
                    <div class="mb-2 tree-tooltip p-1 tooltip-4"
                         *ngIf="showTooltip4 && matchingTree[0]['childs'][0]['childs'][0]?.user_id">
                      <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                          <h6>User Id</h6>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                          <h6>{{matchingTree[0]['childs'][0]['childs'][0]?.user_id}}</h6>
                        </div>
                      </div>
                      <div class="row mt-1">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                          <h6>Full Name</h6>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                          <h6
                            class="text-capitalize">{{matchingTree[0]['childs'][0]['childs'][0]?.name.toLowerCase()}}</h6>
                        </div>
                      </div>
                      <div class="row mt-1">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                          <h6>Country</h6>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                          <h6
                            class="text-capitalize">{{matchingTree[0]['childs'][0]['childs'][0]?.country.toLowerCase()}}</h6>
                        </div>
                      </div>
                      <div class="row mt-1">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                          <h6>Sponsor Id</h6>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                          <h6 class="text-capitalize">{{matchingTree[0]['childs'][0]['childs'][0]?.sponsor_id}}</h6>
                        </div>
                      </div>
                      <div class="row mt-1">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                          <h6>User Status</h6>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                          <h6 class="text-capitalize">{{matchingTree[0]['childs'][0]['childs'][0]?.user_status}}</h6>
                        </div>
                      </div>
                      <div class="row mt-1">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                          <h6>Total Users</h6>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                          <h6 class="text-capitalize">{{matchingTree[0]['childs'][0]['childs'][0]?.total_users}}</h6>
                        </div>
                      </div>
                      <div class="row mt-1">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                          <h6>Total Business Volume</h6>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                          <h6
                            class="text-capitalize">{{matchingTree[0]['childs'][0]['childs'][0]?.total_business_volume}}</h6>
                        </div>
                      </div>
                    </div>
                    <div class="tf-nc cursor-pointer" (mouseover)="showTooltip4 = true"
                         (mouseout)="showTooltip4 = false"
                         (click)="getUserTree(content, matchingTree[0]['childs'][0]['childs'][0], matchingTree[0]['childs'][0])">
                      <div class="mb-2 bottom-shadow-light">
                        <h6 class="p-1">{{matchingTree[0]['childs'][0]['childs'][0]?.package}}</h6>
                      </div>
                      <div class="pl-20px pr-20px">
                        <img
                          [src]="'https://eshopping.axicoayurveda.com/dashboard/images/'+matchingTree[0]['childs'][0]['childs'][0].image"
                          width="70" height="100" *ngIf="matchingTree[0]['childs'][0]['childs'][0].image"/>
                        <img src="https://eshopping.axicoayurveda.com/dashboard/images/member1.png" width="70"
                             height="100" *ngIf="!matchingTree[0]['childs'][0]['childs'][0].image"/>
                      </div>
                      <div class="p-1">
                        <h6>{{matchingTree[0]['childs'][0]['childs'][0]?.username}}</h6>
                        <h6>{{matchingTree[0]['childs'][0]['childs'][0]?.registration_date}}</h6>
                      </div>
                      <div class="mt--1 mb-1" *ngIf="!matchingTree[0]['childs'][0]['childs'][0].user_id">
                        <button class="btn btn-sm btn-outline-primary">Register</button>
                      </div>
                    </div>
                    <ul>
                      <li>
                        <div class="mb-2 tree-tooltip p-1 tooltip-8"
                             *ngIf="showTooltip8 && matchingTree[0]['childs'][0]['childs'][0]['childs'][0]?.user_id">
                          <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>User Id</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6>{{matchingTree[0]['childs'][0]['childs'][0]['childs'][0]?.user_id}}</h6>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>Full Name</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6
                                class="text-capitalize">{{matchingTree[0]['childs'][0]['childs'][0]['childs'][0]?.name.toLowerCase()}}</h6>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>Country</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6
                                class="text-capitalize">{{matchingTree[0]['childs'][0]['childs'][0]['childs'][0]?.country.toLowerCase()}}</h6>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>Sponsor Id</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6
                                class="text-capitalize">{{matchingTree[0]['childs'][0]['childs'][0]['childs'][0]?.sponsor_id}}</h6>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>User Status</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6
                                class="text-capitalize">{{matchingTree[0]['childs'][0]['childs'][0]['childs'][0]?.user_status}}</h6>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>Total Users</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6
                                class="text-capitalize">{{matchingTree[0]['childs'][0]['childs'][0]['childs'][0]?.total_users}}</h6>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>Total Business Volume</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6
                                class="text-capitalize">{{matchingTree[0]['childs'][0]['childs'][0]['childs'][0]?.total_business_volume}}</h6>
                            </div>
                          </div>
                        </div>
                        <div class="tf-nc cursor-pointer" (mouseover)="showTooltip8 = true"
                             (mouseout)="showTooltip8 = false"
                             (click)="getUserTree(content, matchingTree[0]['childs'][0]['childs'][0]['childs'][0], matchingTree[0]['childs'][0]['childs'][0])">
                          <div class="mb-2 bottom-shadow-light">
                            <h6 class="p-1">{{matchingTree[0]['childs'][0]['childs'][0]['childs'][0]?.package}}</h6>
                          </div>
                          <div class="pl-20px pr-20px">
                            <img
                              [src]="'https://eshopping.axicoayurveda.com/dashboard/images/'+matchingTree[0]['childs'][0]['childs'][0]['childs'][0].image"
                              width="70" height="100"
                              *ngIf="matchingTree[0]['childs'][0]['childs'][0]['childs'][0].image"/>
                            <img src="https://eshopping.axicoayurveda.com/dashboard/images/member1.png" width="70"
                                 height="100" *ngIf="!matchingTree[0]['childs'][0]['childs'][0]['childs'][0].image"/>
                          </div>
                          <div class="p-1">
                            <h6>{{matchingTree[0]['childs'][0]['childs'][0]['childs'][0]?.username}}</h6>
                            <h6>{{matchingTree[0]['childs'][0]['childs'][0]['childs'][0]?.registration_date}}</h6>
                          </div>
                          <div class="mt--1 mb-1"
                               *ngIf="!matchingTree[0]['childs'][0]['childs'][0]['childs'][0].user_id">
                            <button class="btn btn-sm btn-outline-primary">Register</button>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="mb-2 tree-tooltip p-1 tooltip-9"
                             *ngIf="showTooltip9 && matchingTree[0]['childs'][0]['childs'][0]['childs'][1].user_id">
                          <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>User Id</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6>{{matchingTree[0]['childs'][0]['childs'][0]['childs'][1]?.user_id}}</h6>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>Full Name</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6
                                class="text-capitalize">{{matchingTree[0]['childs'][0]['childs'][0]['childs'][1]?.name.toLowerCase()}}</h6>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>Country</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6
                                class="text-capitalize">{{matchingTree[0]['childs'][0]['childs'][0]['childs'][1]?.country.toLowerCase()}}</h6>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>Sponsor Id</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6
                                class="text-capitalize">{{matchingTree[0]['childs'][0]['childs'][0]['childs'][1]?.sponsor_id}}</h6>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>User Status</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6
                                class="text-capitalize">{{matchingTree[0]['childs'][0]['childs'][0]['childs'][1]?.user_status}}</h6>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>Total Users</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6
                                class="text-capitalize">{{matchingTree[0]['childs'][0]['childs'][0]['childs'][1]?.total_users}}</h6>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>Total Business Volume</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6
                                class="text-capitalize">{{matchingTree[0]['childs'][0]['childs'][0]['childs'][1]?.total_business_volume}}</h6>
                            </div>
                          </div>
                        </div>
                        <div class="tf-nc cursor-pointer" (mouseover)="showTooltip9 = true"
                             (mouseout)="showTooltip9 = false"
                             (click)="getUserTree(content, matchingTree[0]['childs'][0]['childs'][0]['childs'][1], matchingTree[0]['childs'][0]['childs'][0])">
                          <div class="mb-2 bottom-shadow-light">
                            <h6 class="p-1">{{matchingTree[0]['childs'][0]['childs'][0]['childs'][1]?.package}}</h6>
                          </div>
                          <div class="pl-20px pr-20px">
                            <img
                              [src]="'https://eshopping.axicoayurveda.com/dashboard/images/'+matchingTree[0]['childs'][0]['childs'][0]['childs'][1].image"
                              width="70" height="100"
                              *ngIf="matchingTree[0]['childs'][0]['childs'][0]['childs'][1].image"/>
                            <img src="https://eshopping.axicoayurveda.com/dashboard/images/member1.png" width="70"
                                 height="100" *ngIf="!matchingTree[0]['childs'][0]['childs'][0]['childs'][1].image"/>
                          </div>
                          <div class="p-1">
                            <h6>{{matchingTree[0]['childs'][0]['childs'][0]['childs'][1]?.username}}</h6>
                            <h6>{{matchingTree[0]['childs'][0]['childs'][0]['childs'][1]?.registration_date}}</h6>
                          </div>
                          <div class="mt--1 mb-1"
                               *ngIf="!matchingTree[0]['childs'][0]['childs'][0]['childs'][1].user_id">
                            <button class="btn btn-sm btn-outline-primary">Register</button>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <div class="mb-2 tree-tooltip p-1 tooltip-5"
                         *ngIf="showTooltip5 && matchingTree[0]['childs'][0]['childs'][1]?.user_id">
                      <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                          <h6>User Id</h6>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                          <h6>{{matchingTree[0]['childs'][0]['childs'][1]?.user_id}}</h6>
                        </div>
                      </div>
                      <div class="row mt-1">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                          <h6>Full Name</h6>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                          <h6
                            class="text-capitalize">{{matchingTree[0]['childs'][0]['childs'][1]?.name.toLowerCase()}}</h6>
                        </div>
                      </div>
                      <div class="row mt-1">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                          <h6>Country</h6>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                          <h6
                            class="text-capitalize">{{matchingTree[0]['childs'][0]['childs'][1]?.country.toLowerCase()}}</h6>
                        </div>
                      </div>
                      <div class="row mt-1">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                          <h6>Sponsor Id</h6>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                          <h6 class="text-capitalize">{{matchingTree[0]['childs'][0]['childs'][1]?.sponsor_id}}</h6>
                        </div>
                      </div>
                      <div class="row mt-1">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                          <h6>User Status</h6>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                          <h6 class="text-capitalize">{{matchingTree[0]['childs'][0]['childs'][1]?.user_status}}</h6>
                        </div>
                      </div>
                      <div class="row mt-1">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                          <h6>Total Users</h6>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                          <h6 class="text-capitalize">{{matchingTree[0]['childs'][0]['childs'][1]?.total_users}}</h6>
                        </div>
                      </div>
                      <div class="row mt-1">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                          <h6>Total Business Volume</h6>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                          <h6
                            class="text-capitalize">{{matchingTree[0]['childs'][0]['childs'][1]?.total_business_volume}}</h6>
                        </div>
                      </div>
                    </div>
                    <div class="tf-nc cursor-pointer" (mouseover)="showTooltip5 = true"
                         (mouseout)="showTooltip5 = false"
                         (click)="getUserTree(content, matchingTree[0]['childs'][0]['childs'][1], matchingTree[0]['childs'][0])">
                      <div class="mb-2 bottom-shadow-light">
                        <h6 class="p-1">{{matchingTree[0]['childs'][0]['childs'][1]?.package}}</h6>
                      </div>
                      <div class="pl-20px pr-20px">
                        <img
                          [src]="'https://eshopping.axicoayurveda.com/dashboard/images/'+matchingTree[0]['childs'][0]['childs'][1].image"
                          width="70" height="100" *ngIf="matchingTree[0]['childs'][0]['childs'][1].image"/>
                        <img src="https://eshopping.axicoayurveda.com/dashboard/images/member1.png" width="70"
                             height="100" *ngIf="!matchingTree[0]['childs'][0]['childs'][1].image"/>
                      </div>
                      <div class="p-1">
                        <h6>{{matchingTree[0]['childs'][0]['childs'][1]?.username}}</h6>
                        <h6>{{matchingTree[0]['childs'][0]['childs'][1]?.registration_date}}</h6>
                      </div>
                      <div class="mt--1 mb-1" *ngIf="!matchingTree[0]['childs'][0]['childs'][1].user_id">
                        <button class="btn btn-sm btn-outline-primary">Register</button>
                      </div>
                    </div>
                    <ul>
                      <li>
                        <div class="mb-2 tree-tooltip p-1 tooltip-10"
                             *ngIf="showTooltip10 && matchingTree[0]['childs'][0]['childs'][1]['childs'][0]?.user_id">
                          <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>User Id</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6>{{matchingTree[0]['childs'][0]['childs'][1]['childs'][0]?.user_id}}</h6>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>Full Name</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6
                                class="text-capitalize">{{matchingTree[0]['childs'][0]['childs'][1]['childs'][0]?.name.toLowerCase()}}</h6>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>Country</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6
                                class="text-capitalize">{{matchingTree[0]['childs'][0]['childs'][1]['childs'][0]?.country.toLowerCase()}}</h6>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>Sponsor Id</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6
                                class="text-capitalize">{{matchingTree[0]['childs'][0]['childs'][1]['childs'][0]?.sponsor_id}}</h6>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>User Status</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6
                                class="text-capitalize">{{matchingTree[0]['childs'][0]['childs'][1]['childs'][0]?.user_status}}</h6>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>Total Users</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6
                                class="text-capitalize">{{matchingTree[0]['childs'][0]['childs'][1]['childs'][0]?.total_users}}</h6>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>Total Business Volume</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6
                                class="text-capitalize">{{matchingTree[0]['childs'][0]['childs'][1]['childs'][0]?.total_business_volume}}</h6>
                            </div>
                          </div>
                        </div>
                        <div class="tf-nc cursor-pointer" (mouseover)="showTooltip10 = true"
                             (mouseout)="showTooltip10 = false"
                             (click)="getUserTree(content, matchingTree[0]['childs'][0]['childs'][1]['childs'][0], matchingTree[0]['childs'][0]['childs'][1])">
                          <div class="mb-2 bottom-shadow-light">
                            <h6 class="p-1">{{matchingTree[0]['childs'][0]['childs'][1]['childs'][0]?.package}}</h6>
                          </div>
                          <div class="pl-20px pr-20px">
                            <img
                              [src]="'https://eshopping.axicoayurveda.com/dashboard/images/'+matchingTree[0]['childs'][0]['childs'][1]['childs'][0].image"
                              width="70" height="100"
                              *ngIf="matchingTree[0]['childs'][0]['childs'][1]['childs'][0].image"/>
                            <img src="https://eshopping.axicoayurveda.com/dashboard/images/member1.png" width="70"
                                 height="100" *ngIf="!matchingTree[0]['childs'][0]['childs'][1]['childs'][0].image"/>
                          </div>
                          <div class="p-1">
                            <h6>{{matchingTree[0]['childs'][0]['childs'][1]['childs'][0]?.username}}</h6>
                            <h6>{{matchingTree[0]['childs'][0]['childs'][1]['childs'][0]?.registration_date}}</h6>
                          </div>
                          <div class="mt--1 mb-1"
                               *ngIf="!matchingTree[0]['childs'][0]['childs'][1]['childs'][0].user_id">
                            <button class="btn btn-sm btn-outline-primary">Register</button>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="mb-2 tree-tooltip p-1 tooltip-11"
                             *ngIf="showTooltip11 && matchingTree[0]['childs'][0]['childs'][1]['childs'][1]?.user_id">
                          <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>User Id</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6>{{matchingTree[0]['childs'][0]['childs'][1]['childs'][1]?.user_id}}</h6>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>Full Name</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6
                                class="text-capitalize">{{matchingTree[0]['childs'][0]['childs'][1]['childs'][1]?.name.toLowerCase()}}</h6>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>Country</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6
                                class="text-capitalize">{{matchingTree[0]['childs'][0]['childs'][1]['childs'][1]?.country.toLowerCase()}}</h6>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>Sponsor Id</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6
                                class="text-capitalize">{{matchingTree[0]['childs'][0]['childs'][1]['childs'][1]?.sponsor_id}}</h6>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>User Status</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6
                                class="text-capitalize">{{matchingTree[0]['childs'][0]['childs'][1]['childs'][1]?.user_status}}</h6>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>Total Users</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6
                                class="text-capitalize">{{matchingTree[0]['childs'][0]['childs'][1]['childs'][1]?.total_users}}</h6>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>Total Business Volume</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6
                                class="text-capitalize">{{matchingTree[0]['childs'][0]['childs'][1]['childs'][1]?.total_business_volume}}</h6>
                            </div>
                          </div>
                        </div>
                        <div class="tf-nc cursor-pointer" (mouseover)="showTooltip11 = true"
                             (mouseout)="showTooltip11 = false"
                             (click)="getUserTree(content, matchingTree[0]['childs'][0]['childs'][1]['childs'][1], matchingTree[0]['childs'][0]['childs'][1])">
                          <div class="mb-2 bottom-shadow-light">
                            <h6 class="p-1">{{matchingTree[0]['childs'][0]['childs'][1]['childs'][1]?.package}}</h6>
                          </div>
                          <div class="pl-20px pr-20px">
                            <img
                              [src]="'https://eshopping.axicoayurveda.com/dashboard/images/'+matchingTree[0]['childs'][0]['childs'][1]['childs'][1].image"
                              width="70" height="100"
                              *ngIf="matchingTree[0]['childs'][0]['childs'][1]['childs'][1].image"/>
                            <img src="https://eshopping.axicoayurveda.com/dashboard/images/member1.png" width="70"
                                 height="100" *ngIf="!matchingTree[0]['childs'][0]['childs'][1]['childs'][1].image"/>
                          </div>
                          <div class="p-1">
                            <h6>{{matchingTree[0]['childs'][0]['childs'][1]['childs'][1]?.username}}</h6>
                            <h6>{{matchingTree[0]['childs'][0]['childs'][1]['childs'][1]?.registration_date}}</h6>
                          </div>
                          <div class="mt--1 mb-1"
                               *ngIf="!matchingTree[0]['childs'][0]['childs'][1]['childs'][1].user_id">
                            <button class="btn btn-sm btn-outline-primary">Register</button>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <div class="mb-2 tree-tooltip p-1 tooltip-3"
                     *ngIf="showTooltip3 && matchingTree[0]['childs'][1]?.user_id">
                  <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                      <h6>User Id</h6>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                      <h6>{{matchingTree[0]['childs'][1]?.user_id}}</h6>
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                      <h6>Full Name</h6>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                      <h6 class="text-capitalize">{{matchingTree[0]['childs'][1]?.name.toLowerCase()}}</h6>
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                      <h6>Country</h6>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                      <h6 class="text-capitalize">{{matchingTree[0]['childs'][1]?.country.toLowerCase()}}</h6>
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                      <h6>Sponsor Id</h6>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                      <h6 class="text-capitalize">{{matchingTree[0]['childs'][1]?.sponsor_id}}</h6>
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                      <h6>User Status</h6>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                      <h6 class="text-capitalize">{{matchingTree[0]['childs'][1]?.user_status}}</h6>
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                      <h6>Total Users</h6>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                      <h6 class="text-capitalize">{{matchingTree[0]['childs'][1]?.total_users}}</h6>
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                      <h6>Total Business Volume</h6>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                      <h6 class="text-capitalize">{{matchingTree[0]['childs'][1]?.total_business_volume}}</h6>
                    </div>
                  </div>
                </div>
                <div class="tf-nc cursor-pointer" (mouseover)="showTooltip3 = true" (mouseout)="showTooltip3 = false"
                     (click)="getUserTree(content, matchingTree[0]['childs'][1], matchingTree[0])">
                  <div class="mb-2 bottom-shadow-light">
                    <h6 class="p-1">{{matchingTree[0]['childs'][1]?.package}}</h6>
                  </div>
                  <div class="pl-20px pr-20px">
                    <img
                      [src]="'https://eshopping.axicoayurveda.com/dashboard/images/'+matchingTree[0]['childs'][1].image"
                      width="70" height="100" *ngIf="matchingTree[0]['childs'][1].image"/>
                    <img src="https://eshopping.axicoayurveda.com/dashboard/images/member1.png" width="70"
                         height="100" *ngIf="!matchingTree[0]['childs'][1].image"/>
                  </div>
                  <div class="p-1">
                    <h6>{{matchingTree[0]['childs'][1]?.username}}</h6>
                    <h6>{{matchingTree[0]['childs'][1]?.registration_date}}</h6>
                  </div>
                  <div class="mt--1 mb-1" *ngIf="!matchingTree[0]['childs'][1].user_id">
                    <button class="btn btn-sm btn-outline-primary">Register</button>
                  </div>
                </div>
                <ul>
                  <li>
                    <div class="mb-2 tree-tooltip p-1 tooltip-6"
                         *ngIf="showTooltip6 && matchingTree[0]['childs'][1]['childs'][0]?.user_id">
                      <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                          <h6>User Id</h6>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                          <h6>{{matchingTree[0]['childs'][1]['childs'][0]?.user_id}}</h6>
                        </div>
                      </div>
                      <div class="row mt-1">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                          <h6>Full Name</h6>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                          <h6
                            class="text-capitalize">{{matchingTree[0]['childs'][1]['childs'][0]?.name.toLowerCase()}}</h6>
                        </div>
                      </div>
                      <div class="row mt-1">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                          <h6>Country</h6>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                          <h6
                            class="text-capitalize">{{matchingTree[0]['childs'][1]['childs'][0]?.country.toLowerCase()}}</h6>
                        </div>
                      </div>
                      <div class="row mt-1">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                          <h6>Sponsor Id</h6>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                          <h6 class="text-capitalize">{{matchingTree[0]['childs'][1]['childs'][0]?.sponsor_id}}</h6>
                        </div>
                      </div>
                      <div class="row mt-1">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                          <h6>User Status</h6>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                          <h6 class="text-capitalize">{{matchingTree[0]['childs'][1]['childs'][0]?.user_status}}</h6>
                        </div>
                      </div>
                      <div class="row mt-1">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                          <h6>Total Users</h6>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                          <h6 class="text-capitalize">{{matchingTree[0]['childs'][1]['childs'][0]?.total_users}}</h6>
                        </div>
                      </div>
                      <div class="row mt-1">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                          <h6>Total Business Volume</h6>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                          <h6
                            class="text-capitalize">{{matchingTree[0]['childs'][1]['childs'][0]?.total_business_volume}}</h6>
                        </div>
                      </div>
                    </div>
                    <div class="tf-nc cursor-pointer" (mouseover)="showTooltip6 = true"
                         (mouseout)="showTooltip6 = false"
                         (click)="getUserTree(content, matchingTree[0]['childs'][1]['childs'][0], matchingTree[0]['childs'][1])">
                      <div class="mb-2 bottom-shadow-light">
                        <h6 class="p-1">{{matchingTree[0]['childs'][1]['childs'][0]?.package}}</h6>
                      </div>
                      <div class="pl-20px pr-20px">
                        <img
                          [src]="'https://eshopping.axicoayurveda.com/dashboard/images/'+matchingTree[0]['childs'][1]['childs'][0].image"
                          width="70" height="100" *ngIf="matchingTree[0]['childs'][1]['childs'][0].image"/>
                        <img src="https://eshopping.axicoayurveda.com/dashboard/images/member1.png" width="70"
                             height="100" *ngIf="!matchingTree[0]['childs'][1]['childs'][0].image"/>
                      </div>
                      <div class="p-1">
                        <h6>{{matchingTree[0]['childs'][1]['childs'][0]?.username}}</h6>
                        <h6>{{matchingTree[0]['childs'][1]['childs'][0]?.registration_date}}</h6>
                      </div>
                      <div class="mt--1 mb-1" *ngIf="!matchingTree[0]['childs'][1]['childs'][0].user_id">
                        <button class="btn btn-sm btn-outline-primary">Register</button>
                      </div>
                    </div>
                    <ul>
                      <li>
                        <div class="mb-2 tree-tooltip p-1 tooltip-12"
                             *ngIf="showTooltip12 && matchingTree[0]['childs'][1]['childs'][0]['childs'][0]?.user_id">
                          <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>User Id</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6>{{matchingTree[0]['childs'][1]['childs'][0]['childs'][0]?.user_id}}</h6>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>Full Name</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6
                                class="text-capitalize">{{matchingTree[0]['childs'][1]['childs'][0]['childs'][0]?.name.toLowerCase()}}</h6>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>Country</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6
                                class="text-capitalize">{{matchingTree[0]['childs'][1]['childs'][0]['childs'][0]?.country.toLowerCase()}}</h6>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>Sponsor Id</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6
                                class="text-capitalize">{{matchingTree[0]['childs'][1]['childs'][0]['childs'][0]?.sponsor_id}}</h6>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>User Status</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6
                                class="text-capitalize">{{matchingTree[0]['childs'][1]['childs'][0]['childs'][0]?.user_status}}</h6>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>Total Users</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6
                                class="text-capitalize">{{matchingTree[0]['childs'][1]['childs'][0]['childs'][0]?.total_users}}</h6>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>Total Business Volume</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6
                                class="text-capitalize">{{matchingTree[0]['childs'][1]['childs'][0]['childs'][0]?.total_business_volume}}</h6>
                            </div>
                          </div>
                        </div>
                        <div class="tf-nc cursor-pointer" (mouseover)="showTooltip12 = true"
                             (mouseout)="showTooltip12 = false"
                             (click)="getUserTree(content, matchingTree[0]['childs'][1]['childs'][0]['childs'][0], matchingTree[0]['childs'][1]['childs'][0])">
                          <div class="mb-2 bottom-shadow-light">
                            <h6 class="p-1">{{matchingTree[0]['childs'][1]['childs'][0]['childs'][0]?.package}}</h6>
                          </div>
                          <div class="pl-20px pr-20px">
                            <img
                              [src]="'https://eshopping.axicoayurveda.com/dashboard/images/'+matchingTree[0]['childs'][1]['childs'][0]['childs'][0].image"
                              width="70" height="100"
                              *ngIf="matchingTree[0]['childs'][1]['childs'][0]['childs'][0].image"/>
                            <img src="https://eshopping.axicoayurveda.com/dashboard/images/member1.png" width="70"
                                 height="100" *ngIf="!matchingTree[0]['childs'][1]['childs'][0]['childs'][0].image"/>
                          </div>
                          <div class="p-1">
                            <h6>{{matchingTree[0]['childs'][1]['childs'][0]['childs'][0]?.username}}</h6>
                            <h6>{{matchingTree[0]['childs'][1]['childs'][0]['childs'][0]?.registration_date}}</h6>
                          </div>
                          <div class="mt--1 mb-1"
                               *ngIf="!matchingTree[0]['childs'][1]['childs'][0]['childs'][0].user_id">
                            <button class="btn btn-sm btn-outline-primary">Register</button>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="mb-2 tree-tooltip p-1 tooltip-13"
                             *ngIf="showTooltip13 && matchingTree[0]['childs'][1]['childs'][0]['childs'][1]?.user_id">
                          <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>User Id</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6>{{matchingTree[0]['childs'][1]['childs'][0]['childs'][1]?.user_id}}</h6>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>Full Name</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6
                                class="text-capitalize">{{matchingTree[0]['childs'][1]['childs'][0]['childs'][1]?.name.toLowerCase()}}</h6>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>Country</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6
                                class="text-capitalize">{{matchingTree[0]['childs'][1]['childs'][0]['childs'][1]?.country.toLowerCase()}}</h6>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>Sponsor Id</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6
                                class="text-capitalize">{{matchingTree[0]['childs'][1]['childs'][0]['childs'][1]?.sponsor_id}}</h6>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>User Status</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6
                                class="text-capitalize">{{matchingTree[0]['childs'][1]['childs'][0]['childs'][1]?.user_status}}</h6>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>Total Users</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6
                                class="text-capitalize">{{matchingTree[0]['childs'][1]['childs'][0]['childs'][1]?.total_users}}</h6>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>Total Business Volume</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6
                                class="text-capitalize">{{matchingTree[0]['childs'][1]['childs'][0]['childs'][1]?.total_business_volume}}</h6>
                            </div>
                          </div>
                        </div>
                        <div class="tf-nc cursor-pointer" (mouseover)="showTooltip13 = true"
                             (mouseout)="showTooltip13 = false"
                             (click)="getUserTree(content, matchingTree[0]['childs'][1]['childs'][0]['childs'][1], matchingTree[0]['childs'][1]['childs'][0])">
                          <div class="mb-2 bottom-shadow-light">
                            <h6 class="p-1">{{matchingTree[0]['childs'][1]['childs'][0]['childs'][1]?.package}}</h6>
                          </div>
                          <div class="pl-20px pr-20px">
                            <img
                              [src]="'https://eshopping.axicoayurveda.com/dashboard/images/'+matchingTree[0]['childs'][1]['childs'][0]['childs'][1].image"
                              width="70" height="100"
                              *ngIf="matchingTree[0]['childs'][1]['childs'][0]['childs'][1].image"/>
                            <img src="https://eshopping.axicoayurveda.com/dashboard/images/member1.png" width="70"
                                 height="100" *ngIf="!matchingTree[0]['childs'][1]['childs'][0]['childs'][1].image"/>
                          </div>
                          <div class="p-1">
                            <h6>{{matchingTree[0]['childs'][1]['childs'][0]['childs'][1]?.username}}</h6>
                            <h6>{{matchingTree[0]['childs'][1]['childs'][0]['childs'][1]?.registration_date}}</h6>
                          </div>
                          <div class="mt--1 mb-1"
                               *ngIf="!matchingTree[0]['childs'][1]['childs'][0]['childs'][1].user_id">
                            <button class="btn btn-sm btn-outline-primary">Register</button>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <div class="mb-2 tree-tooltip p-1 tooltip-7"
                         *ngIf="showTooltip7 && matchingTree[0]['childs'][1]['childs'][1]?.user_id">
                      <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                          <h6>User Id</h6>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                          <h6>{{matchingTree[0]['childs'][1]['childs'][1]?.user_id}}</h6>
                        </div>
                      </div>
                      <div class="row mt-1">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                          <h6>Full Name</h6>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                          <h6
                            class="text-capitalize">{{matchingTree[0]['childs'][1]['childs'][1]?.name.toLowerCase()}}</h6>
                        </div>
                      </div>
                      <div class="row mt-1">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                          <h6>Country</h6>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                          <h6
                            class="text-capitalize">{{matchingTree[0]['childs'][1]['childs'][1]?.country.toLowerCase()}}</h6>
                        </div>
                      </div>
                      <div class="row mt-1">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                          <h6>Sponsor Id</h6>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                          <h6 class="text-capitalize">{{matchingTree[0]['childs'][1]['childs'][1]?.sponsor_id}}</h6>
                        </div>
                      </div>
                      <div class="row mt-1">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                          <h6>User Status</h6>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                          <h6 class="text-capitalize">{{matchingTree[0]['childs'][1]['childs'][1]?.user_status}}</h6>
                        </div>
                      </div>
                      <div class="row mt-1">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                          <h6>Total Users</h6>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                          <h6 class="text-capitalize">{{matchingTree[0]['childs'][1]['childs'][1]?.total_users}}</h6>
                        </div>
                      </div>
                      <div class="row mt-1">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                          <h6>Total Business Volume</h6>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                          <h6
                            class="text-capitalize">{{matchingTree[0]['childs'][1]['childs'][1]?.total_business_volume}}</h6>
                        </div>
                      </div>
                    </div>
                    <div class="tf-nc cursor-pointer" (mouseover)="showTooltip7 = true"
                         (mouseout)="showTooltip7 = false"
                         (click)="getUserTree(content, matchingTree[0]['childs'][1]['childs'][1], matchingTree[0]['childs'][1])">
                      <div class="mb-2 bottom-shadow-light">
                        <h6 class="p-1">{{matchingTree[0]['childs'][1]['childs'][1]?.package}}</h6>
                      </div>
                      <div class="pl-20px pr-20px">
                        <img
                          [src]="'https://eshopping.axicoayurveda.com/dashboard/images/'+matchingTree[0]['childs'][1]['childs'][1].image"
                          width="70" height="100" *ngIf="matchingTree[0]['childs'][1]['childs'][1].image"/>
                        <img src="https://eshopping.axicoayurveda.com/dashboard/images/member1.png" width="70"
                             height="100" *ngIf="!matchingTree[0]['childs'][1]['childs'][1].image"/>
                      </div>
                      <div class="p-1">
                        <h6>{{matchingTree[0]['childs'][1]['childs'][1]?.username}}</h6>
                        <h6>{{matchingTree[0]['childs'][1]['childs'][1]?.registration_date}}</h6>
                      </div>
                      <div class="mt--1 mb-1" *ngIf="!matchingTree[0]['childs'][1]['childs'][1].user_id">
                        <button class="btn btn-sm btn-outline-primary">Register</button>
                      </div>
                    </div>
                    <ul>
                      <li>
                        <div class="mb-2 tree-tooltip p-1 tooltip-14"
                             *ngIf="showTooltip14 && matchingTree[0]['childs'][1]['childs'][1]['childs'][0]?.user_id">
                          <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>User Id</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6>{{matchingTree[0]['childs'][1]['childs'][1]['childs'][0]?.user_id}}</h6>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>Full Name</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6
                                class="text-capitalize">{{matchingTree[0]['childs'][1]['childs'][1]['childs'][0]?.name.toLowerCase()}}</h6>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>Country</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6
                                class="text-capitalize">{{matchingTree[0]['childs'][1]['childs'][1]['childs'][0]?.country.toLowerCase()}}</h6>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>Sponsor Id</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6
                                class="text-capitalize">{{matchingTree[0]['childs'][1]['childs'][1]['childs'][0]?.sponsor_id}}</h6>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>User Status</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6
                                class="text-capitalize">{{matchingTree[0]['childs'][1]['childs'][1]['childs'][0]?.user_status}}</h6>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>Total Users</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6
                                class="text-capitalize">{{matchingTree[0]['childs'][1]['childs'][1]['childs'][0]?.total_users}}</h6>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>Total Business Volume</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6
                                class="text-capitalize">{{matchingTree[0]['childs'][1]['childs'][1]['childs'][0]?.total_business_volume}}</h6>
                            </div>
                          </div>
                        </div>
                        <div class="tf-nc cursor-pointer" (mouseover)="showTooltip14 = true"
                             (mouseout)="showTooltip14 = false"
                             (click)="getUserTree(content, matchingTree[0]['childs'][1]['childs'][1]['childs'][0], matchingTree[0]['childs'][1]['childs'][1])">
                          <div class="mb-2 bottom-shadow-light">
                            <h6 class="p-1">{{matchingTree[0]['childs'][1]['childs'][1]['childs'][0]?.package}}</h6>
                          </div>
                          <div class="pl-20px pr-20px">
                            <img
                              [src]="'https://eshopping.axicoayurveda.com/dashboard/images/'+matchingTree[0]['childs'][1]['childs'][1]['childs'][0].image"
                              width="70" height="100"
                              *ngIf="matchingTree[0]['childs'][1]['childs'][1]['childs'][0].image"/>
                            <img src="https://eshopping.axicoayurveda.com/dashboard/images/member1.png" width="70"
                                 height="100" *ngIf="!matchingTree[0]['childs'][1]['childs'][1]['childs'][0].image"/>
                          </div>
                          <div class="p-1">
                            <h6>{{matchingTree[0]['childs'][1]['childs'][1]['childs'][0]?.username}}</h6>
                            <h6>{{matchingTree[0]['childs'][1]['childs'][1]['childs'][0]?.registration_date}}</h6>
                          </div>
                          <div class="mt--1 mb-1"
                               *ngIf="!matchingTree[0]['childs'][1]['childs'][1]['childs'][0].user_id">
                            <button class="btn btn-sm btn-outline-primary">Register</button>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="mb-2 tree-tooltip p-1 tooltip-15"
                             *ngIf="showTooltip15 && matchingTree[0]['childs'][1]['childs'][1]['childs'][1]?.user_id">
                          <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>User Id</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6>{{matchingTree[0]['childs'][1]['childs'][1]['childs'][1]?.user_id}}</h6>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>Full Name</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6
                                class="text-capitalize">{{matchingTree[0]['childs'][1]['childs'][1]['childs'][1]?.name.toLowerCase()}}</h6>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>Country</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6
                                class="text-capitalize">{{matchingTree[0]['childs'][1]['childs'][1]['childs'][1]?.country.toLowerCase()}}</h6>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>Sponsor Id</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6
                                class="text-capitalize">{{matchingTree[0]['childs'][1]['childs'][1]['childs'][1]?.sponsor_id}}</h6>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>User Status</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6
                                class="text-capitalize">{{matchingTree[0]['childs'][1]['childs'][1]['childs'][1]?.user_status}}</h6>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>Total Users</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6
                                class="text-capitalize">{{matchingTree[0]['childs'][1]['childs'][1]['childs'][1]?.total_users}}</h6>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left">
                              <h6>Total Business Volume</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                              <h6
                                class="text-capitalize">{{matchingTree[0]['childs'][1]['childs'][1]['childs'][1]?.total_business_volume}}</h6>
                            </div>
                          </div>
                        </div>
                        <div class="tf-nc cursor-pointer" (mouseover)="showTooltip15 = true"
                             (mouseout)="showTooltip15 = false"
                             (click)="getUserTree(content, matchingTree[0]['childs'][1]['childs'][1]['childs'][1], matchingTree[0]['childs'][1]['childs'][1])">
                          <div class="mb-2 bottom-shadow-light">
                            <h6 class="p-1">{{matchingTree[0]['childs'][1]['childs'][1]['childs'][1]?.package}}</h6>
                          </div>
                          <div class="pl-20px pr-20px">
                            <img
                              [src]="'https://eshopping.axicoayurveda.com/dashboard/images/'+matchingTree[0]['childs'][1]['childs'][1]['childs'][1].image"
                              width="70" height="100"
                              *ngIf="matchingTree[0]['childs'][1]['childs'][1]['childs'][1].image"/>
                            <img src="https://eshopping.axicoayurveda.com/dashboard/images/member1.png" width="70"
                                 height="100" *ngIf="!matchingTree[0]['childs'][1]['childs'][1]['childs'][1].image"/>
                          </div>
                          <div class="p-1">
                            <h6>{{matchingTree[0]['childs'][1]['childs'][1]['childs'][1]?.username}}</h6>
                            <h6>{{matchingTree[0]['childs'][1]['childs'][1]['childs'][1]?.registration_date}}</h6>
                          </div>
                          <div class="mt--1 mb-1"
                               *ngIf="!matchingTree[0]['childs'][1]['childs'][1]['childs'][1].user_id">
                            <button class="btn btn-sm btn-outline-primary">Register</button>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
