import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';

@Injectable()
export class AppUrl {

  static get API_URL(): string {
    return environment.appUrl + 'api/';
  }

  static get APP_URL(): string {
    return environment.appUrl + 'stockist/';
  }

  static get LOGIN(): string {
    return AppUrl.API_URL + 'authentication/login.php';
  }

  static get STOCKIST_LOGIN(): string {
    return AppUrl.APP_URL + 'authentication/login.php';
  }

  static get REGISTER(): string {
    return AppUrl.API_URL + 'authentication/register.php';
  }

  static get SELF(): string {
    return AppUrl.API_URL + 'authentication/self.php';
  }

  static LOGOUT(): string {
    return AppUrl.API_URL + 'authentication/logout.php';
  }

  static get VERIFY_USER(): string {
    return AppUrl.APP_URL + 'verify-user';
  }

  static DASHBOARD(): string {
    return AppUrl.API_URL + 'dashboard/get.php';
  }

  static DASHBOARD_UPDATE_RANK(): string {
    return AppUrl.API_URL + 'dashboard/updateRank.php';
  }

  static STOCKIST_DASHBOARD(): string {
    return AppUrl.APP_URL + 'dashboard/get.php';
  }

  static DIRECT_REFRRALS(): string {
    return AppUrl.API_URL + 'referrals/get.php';
  }

  static DOWNLINE_MEMBERS(): string {
    return AppUrl.API_URL + 'downline/getMembers.php';
  }

  static SELF_MATCHING_TREE(): string {
    return AppUrl.API_URL + 'matchings/getSelfMatchingTree.php';
  }

  static USER_MATCHING_TREE(): string {
    return AppUrl.API_URL + 'matchings/getUserMatchingTree.php';
  }

  static MY_WALLET(): string {
    return AppUrl.API_URL + 'wallets/getMyWallets.php';
  }

  static MY_WALLET_TRANSACTION_HISTORY(): string {
    return AppUrl.API_URL + 'wallets/getWalletTransactions.php';
  }

  static E_WALLET_WITHDRAWAL(): string {
    return AppUrl.API_URL + 'wallets/getWithdrawRequests.php';
  }

  static PAIRING_BONUS(): string {
    return AppUrl.API_URL + 'earnings/getParingBonus.php';
  }

  static SELF_REPURCHASE_BONUS(): string {
    return AppUrl.API_URL + 'earnings/getSelfRepurchaseBonus.php';
  }

  static PERFORMANCE_BONUS(): string {
    return AppUrl.API_URL + 'earnings/getPerformanceBonus.php';
  }

  static LEADERSHIP_BONUS(): string {
    return AppUrl.API_URL + 'earnings/getLeadershipBonus.php';
  }

  static DIRECTOR_BONUS(): string {
    return AppUrl.API_URL + 'earnings/getDirectorBonus.php';
  }

  static CAR_FUND_BONUS(): string {
    return AppUrl.API_URL + 'earnings/getCarFundBonus.php';
  }

  static HOUSE_FUND_BONUS(): string {
    return AppUrl.API_URL + 'earnings/getHouseFundBonus.php';
  }

  static TOUR_FUND_BONUS(): string {
    return AppUrl.API_URL + 'earnings/getTourFundBonus.php';
  }

  static PERFORMANCE_BONUS_OVERVIEW(): string {
    return AppUrl.API_URL + 'earnings/getPerformanceBonusOverview.php';
  }

  static DIRECTOR_SIDE_LEG_REPURCHASE_REPORT(): string {
    return AppUrl.API_URL + 'earnings/getDirectorSideLegRepurchase.php';
  }

  static PURCHASE_ESHOP_INVOICES(): string {
    return AppUrl.API_URL + 'invoices/getPurchaseInvoices.php';
  }

  static MY_PACKAGE_INVOICES(): string {
    return AppUrl.API_URL + 'invoices/getPackageInvoices.php';
  }

  static VIEW_TICKET_RESPONSES(): string {
    return AppUrl.API_URL + 'support/getTicketResponses.php';
  }

  static RAISE_TICKET_RESPONSES(): string {
    return AppUrl.API_URL + 'support/raiseTicket.php';
  }

  static TERMS_AND_CONDITIONS(): string {
    return AppUrl.API_URL + 'policies/getTermsConditions.php';
  }

  static OFFICIAL_ANNOUNCEMENTS(type?): string {
    if (type === 'DETAIL') {
      return AppUrl.API_URL + 'announcements/detail.php';
    } else {
      return AppUrl.API_URL + 'announcements/get.php';
    }
  }

  static OFFICIAL_LATEST_ANNOUNCEMENTS(): string {
    return AppUrl.API_URL + 'announcements/getLatestAnnouncement.php';
  }

  static REFERRAL_LINKS(): string {
    return AppUrl.API_URL + 'marketing/getReferralLink.php';
  }

  static STATES(): string {
    return AppUrl.API_URL + 'states/get.php';
  }

  static COUNTRIES(): string {
    return AppUrl.API_URL + 'countries/get.php';
  }

  static GET_USER_DETAILS(): string {
    return AppUrl.API_URL + 'profile/getUserdetail.php';
  }

  static UPDATE_USER_DETAIL(): string {
    return AppUrl.API_URL + 'profile/updateUserDetail.php';
  }

  static GET_PROFILE_IMAGE(): string {
    return AppUrl.API_URL + 'profile/getProfileImage.php';
  }

  static USER_IMAGE_UPLOAD(): string {
    return AppUrl.API_URL + 'profile/uploadProfileImage.php';
  }

  static GET_BENEFICIERY_DETAILS(): string {
    return AppUrl.API_URL + 'profile/getBeneficieryDetail.php';
  }

  static UPDATE_BENEFICIERY_DETAIL(): string {
    return AppUrl.API_URL + 'profile/updateBeneficieryDetail.php';
  }

  static UPDATE_PASSWORD(): string {
    return AppUrl.API_URL + 'profile/changePassword.php';
  }

  static GET_PAN_DETAIL(): string {
    return AppUrl.API_URL + 'kyc/getPanDetails.php';
  }

  static GET_ADDRESS_DETAIL(): string {
    return AppUrl.API_URL + 'kyc/getAddressDetails.php';
  }

  static GET_BANK_DETAIL(): string {
    return AppUrl.API_URL + 'kyc/getBankDetails.php';
  }

  static UPLOAD_KYC_PAN_WEB(): string {
    return AppUrl.API_URL + 'kyc/uploadKycPanWeb.php';
  }

  static UPLOAD_KYC_AADHAR_WEB(): string {
    return AppUrl.API_URL + 'kyc/uploadAadharWeb.php';
  }

  static UPLOAD_KYC_BANK_WEB(): string {
    return AppUrl.API_URL + 'kyc/uploadBankWeb.php';
  }

  static UPDATE_PAN_DETAIL(): string {
    return AppUrl.API_URL + 'kyc/updateKycPanWeb.php';
  }

  static UPDATE_AADHAR_DETAIL(): string {
    return AppUrl.API_URL + 'kyc/updateAadharWeb.php';
  }

  static UPDATE_BANK_DETAIL(): string {
    return AppUrl.API_URL + 'kyc/updateBankWeb.php';
  }

  static STOCKISTS(): string {
    return AppUrl.API_URL + 'stockists/get.php';
  }

  static STOCKIST_PACKAGES(): string {
    return AppUrl.API_URL + 'stockists/getStockistPackages.php';
  }

  static PRODUCTS(): string {
    return AppUrl.API_URL + 'products/get.php';
  }

  static UPDATE_ADDRESS(): string {
    return AppUrl.API_URL + 'buy/updateAddress.php';
  }

  static GET_WALLET_AMOUNT(): string {
    return AppUrl.API_URL + 'wallets/getEWalletBalance.php';
  }

  static VERIFY_PASSWORD(): string {
    return AppUrl.API_URL + 'profile/verifyPassword.php';
  }

  static VERIFY_STOCKIST_PASSWORD(): string {
    return AppUrl.APP_URL + 'profile/verifyPassword.php';
  }

  static ONLINE_PURCHASE(): string {
    return AppUrl.API_URL + 'buy/validatePurchase.php';
  }

  static WALLET_PURCHASE(): string {
    return AppUrl.API_URL + 'buy/eWalletPurchase.php';
  }

  static GET_AVAILABLE_PACKAGE_FOR_USER(): string {
    return AppUrl.API_URL + 'packages/available.php';
  }

  static UPGRADE_PACKAGE(): string {
    return AppUrl.API_URL + 'packages/upgrade.php';
  }

  static MY_SALE_INCOME_REPORT(): string {
    return AppUrl.APP_URL + 'myIncomeReport/mySaleIncomeReport.php';
  }

  static MY_WALLET_TRANSACTION_HISTORY_STOCKIST(): string {
    return AppUrl.APP_URL + 'myIncomeReport/walletTransactionHistory.php';
  }

  static MY_STOCK_LIST(): string {
    return AppUrl.APP_URL + 'stockManagement/myStockList.php';
  }

  static PURCHASE_PRODUCTS_HISTORY(): string {
    return AppUrl.APP_URL + 'stockManagement/purchaseProductsHistory.php';
  }

  static PENDING_INVOICES(): string {
    return AppUrl.APP_URL + 'userEshopInvoiceSection/pendingInvoices.php';
  }

  static UPDATE_USER_ESHOP_INVOICE_STATUS(): string {
    return AppUrl.APP_URL + 'userEshopInvoiceSection/updatePendingInvoiceStatus.php';
  }

  static UPDATE_USER_PACKAGE_INVOICE_STATUS(): string {
    return AppUrl.APP_URL + 'userPackageInvoiceSection/updatePackagePendingInvoice.php';
  }

  static DELIVERED_INVOICES(): string {
    return AppUrl.APP_URL + 'userEshopInvoiceSection/deliveredInvoices.php';
  }

  static CANCELLED_INVOICES(): string {
    return AppUrl.APP_URL + 'userEshopInvoiceSection/cancelledInvoices.php';
  }

  static PENDING_INVOICES_USER_PACKAGE(): string {
    return AppUrl.APP_URL + 'userPackageInvoiceSection/pendingInvoices.php';
  }

  static DELIVERED_INVOICES_USER_PACKAGE(): string {
    return AppUrl.APP_URL + 'userPackageInvoiceSection/deliveredInvoices.php';
  }

  static CANCELLED_INVOICES_USER_PACKAGE(): string {
    return AppUrl.APP_URL + 'userPackageInvoiceSection/cancelledInvoices.php';
  }

  static TERMS_AND_CONDITIONS_STOCKIST(): string {
    return AppUrl.APP_URL + 'mediaDocuments/termsConditions.php';
  }

  static PRIVACY_POLICIES_STOCKIST(): string {
    return AppUrl.APP_URL + 'mediaDocuments/privacyPolicy.php';
  }

  static GET_STOCKIST_DETAILS(): string {
    return AppUrl.APP_URL + 'profile/getStockistDetail.php';
  }

  static GET_STOCKIST_ACCOUNT_DETAILS(): string {
    return AppUrl.APP_URL + 'profile/getBankDetail.php';
  }

  static GET_STOCKIST_PROFILE_IMAGE(): string {
    return AppUrl.APP_URL + 'profile/getProfileImage.php';
  }

  static STOCKIST_IMAGE_UPLOAD(): string {
    return AppUrl.APP_URL + 'profile/uploadProfileImage.php';
  }

  static UPDATE_STOCKIST_DETAIL(): string {
    return AppUrl.APP_URL + 'profile/updateStockistDetail.php';
  }

  static UPDATE_STOCKIST_BANK_DETAIL(): string {
    return AppUrl.APP_URL + 'profile/updateBankDetail.php';
  }

  static STOCKIST_PRODUCTS(): string {
    return AppUrl.APP_URL + 'stockManagement/purchaseProducts.php';
  }

  static GET_FUND_WALLET_AMOUNT(): string {
    return AppUrl.APP_URL + 'stockManagement/fundWalletBalance.php';
  }

  static FUND_WALLET_PURCHASE(): string {
    return AppUrl.APP_URL + 'stockManagement/fundWalletPurchase.php';
  }

  static USER_SEARCH(): string {
    return AppUrl.APP_URL + 'billingSection/searchUser.php';
  }

  static UPDATE_ADDRESS_STOCKIST(): string {
    return AppUrl.APP_URL + 'billingSection/updateAddress.php';
  }

  static FUND_WALLET_USER_PURCHASE(): string {
    return AppUrl.APP_URL + 'billingSection/eWalletPurchase.php';
  }
}
