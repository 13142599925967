<section>
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <h4>Package Upgrade</h4>
    </div>
  </div>
  <div class="row" *ngIf="userAvailablePackages && userAvailablePackages.length > 0; else noPackages">
    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12"
         *ngFor="let userAvailablePackage of userAvailablePackages">
      <div class="card mt-4">
        <div class="card-header bottom-shadow-light">
          <div class="bar-success text-center mb-3">
            <h4 class="card-title">{{userAvailablePackage?.name}}</h4>
            <small>&#8377;{{userAvailablePackage?.amount}}</small>
          </div>
        </div>
        <div class="card-body mt-1">
          <div class="row mb-2">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
              <select class="form-control" #paymode>
                <option value="">Select Payment Mode</option>
                <option [value]="payment?.value" *ngFor="let payment of paymentMode">{{payment?.name}}</option>
              </select>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
              <select class="form-control text-capitalize"
                      (change)="getStockistPackage($event, userAvailablePackage.amount)" #stockist>
                <option value="">Select Stockist</option>
                <option [value]="stockist.user_id"
                        *ngFor="let stockist of stockists"
                        class="text-capitalize">{{stockist.user_id}}
                  - {{stockist.first_name.toLowerCase()}} {{stockist.last_name.toLowerCase()}}</option>
              </select>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
              <select class="form-control text-capitalize" #stockistPackage>
                <option value="">Select Package</option>
                <option [value]="stockistPackage.product_id"
                        *ngFor="let stockistPackage of stockistPackages"
                        class="text-capitalize">{{stockistPackage.product_name.toLowerCase()}}</option>
              </select>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
              <input type="password" class="form-control" placeholder="Enter Password" #password/>
            </div>
          </div>
        </div>
        <div class="card-footer text-center upper-shadow-light">
          <button class="btn btn-primary"
                  (click)="activate(userAvailablePackage.id, paymode.value, stockistPackage.value, stockist.value, password.value)">
            Activate
          </button>
        </div>
      </div>
    </div>
  </div>
  <ng-template #noPackages>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body text-center">
            <h4 class="p-5">No packages to show</h4>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</section>
