<section>
  <div class="row mt--21px">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="content-header f_20 module-header">Update Profile</div>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="card mt-4">
        <div class="card-header bottom-shadow-light">
          <div class="bar-success">
            <h4 class="card-title mb-3 ng-star-inserted">Profile Photo</h4>
          </div>
        </div>
        <div class="card-body mt-1">
          <div class="row mb-2">
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 text-center">
              <img [src]="(profileImage ? profileImage : 'https://eshopping.axicoayurveda.com/franchisepanel/images/male.jpg')" width="200" height="200" alt="Profile Image"/>
            </div>
            <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
              <button class="btn btn-primary color-white mt-5" [disabled]="submitting" (click)="fileUpload.click()">
                <i class="fas fa-save mr-1" *ngIf="!submitting"></i>
                <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>Update Image
              </button>
              <input class="form-control display-hidden" id="file" type="file" (change)="onFileChange($event)"
                     #fileUpload>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
      <div class="card mt-4">
        <div class="card-header bottom-shadow-light">
          <div class="bar-success">
            <h4 class="card-title mb-3 ng-star-inserted">Personal Information</h4>
          </div>
        </div>
        <div class="card-body mt-1">
          <form class="form" novalidate [formGroup]="profileForm">
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label for="first_name">First Name</label>
                  <input class="form-control" id="first_name" type="text" formControlName="first_name">
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label for="last_name">Last Name</label>
                  <input class="form-control" id="last_name" type="text" formControlName="last_name">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label for="email">Email</label>
                  <input class="form-control" id="email" type="text" formControlName="email">
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label for="dob">Date Of Birth</label>
                  <input class="form-control" id="dob" formControlName="dob" [bsConfig]="bsConfig" bsDatepicker>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label for="password">Password</label>
                  <input class="form-control" id="password" type="password" formControlName="password">
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label for="confirm_password">Confirm Password</label>
                  <input class="form-control" id="confirm_password" type="password" formControlName="confirm_password">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label for="t_code">Transaction Password</label>
                  <input class="form-control" id="t_code" type="password" formControlName="t_code">
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label for="confirm_t_code">Confirm Transaction Password</label>
                  <input class="form-control" id="confirm_t_code" type="password" formControlName="confirm_t_code">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label for="gender">Gender</label>
                  <select class="form-control" id="gender" formControlName="sex">
                    <option value="">Select Gender</option>
                    <option [value]="gender?.name" *ngFor="let gender of genders">{{gender?.value}}</option>
                  </select>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label for="merried_status">Marital Status</label>
                  <select class="form-control" id="merried_status" formControlName="merried_status">
                    <option value="">Select Marital Status</option>
                    <option [value]="marital?.name" *ngFor="let marital of maritalStatus">{{marital?.value}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="form-group">
                  <label for="address">Address</label>
                  <textarea class="form-control resize-none" id="address" type="text" formControlName="address"
                            rows="5">

                  </textarea>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="form-group">
                  <label for="landmark">Landmark</label>
                  <input class="form-control" id="landmark" type="text" formControlName="lendmark">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label for="city">City</label>
                  <input class="form-control" id="city" type="text" formControlName="city">
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label for="state">State</label>
                  <select class="form-control" id="state" formControlName="state">
                    <option value="">Select State</option>
                    <option [value]="state?.name" *ngFor="let state of states">{{state?.name}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="form-group">
                  <label for="pan">PAN</label>
                  <input class="form-control" id="pan" type="text" formControlName="pan">
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="card-footer upper-shadow-light pb-3px">
          <button class="btn btn-danger color-white mb-2" (click)="back()">
            <i class="fas fa-window-close mr-1"></i>Cancel
          </button>
          <button class="btn btn-success color-white mb-2 pull-right" [disabled]="submitting" (click)="updatePersonalInfo()">
            <i class="fas fa-save mr-1" *ngIf="!submitting"></i>
            <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>Update
          </button>
        </div>
      </div>
    </div>

    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
      <div class="card mt-4">
        <div class="card-header bottom-shadow-light">
          <div class="bar-success">
            <h4 class="card-title mb-3 ng-star-inserted">Bank Info</h4>
          </div>
        </div>
        <div class="card-body mt-1">
          <form class="form" novalidate [formGroup]="bankInfoForm">
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label for="acc_name">Account Name</label>
                  <input class="form-control" id="acc_name" type="text" formControlName="acc_name">
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label for="ac_no">Account Number</label>
                  <input class="form-control" id="ac_no" type="text" formControlName="ac_no">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label for="bank_nm">Bank Name</label>
                  <input class="form-control" id="bank_nm" type="text" formControlName="bank_nm">
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label for="branch_nm">Branch Name</label>
                  <input class="form-control" id="branch_nm" type="text" formControlName="branch_nm">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="form-group">
                  <label for="swift_code">IFSC</label>
                  <input class="form-control" id="swift_code" type="text" formControlName="swift_code">
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="card-footer upper-shadow-light pb-3px">
          <button class="btn btn-danger color-white mb-2" (click)="back()">
            <i class="fas fa-window-close mr-1"></i>Cancel
          </button>
          <button class="btn btn-success color-white mb-2 pull-right" [disabled]="submitting" (click)="updateBankInfo()">
            <i class="fas fa-save mr-1" *ngIf="!submitting"></i>
            <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>Update
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
