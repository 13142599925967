<section>
  <div class="row">
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="card mt-4">
        <div class="card-header bottom-shadow-light">
          <div class="bar-success">
            <h4 class="card-title mb-3 ng-star-inserted">Raise Ticket</h4>
          </div>
        </div>
        <div class="card-body mt-1">
          <form class="form" novalidate [formGroup]="raiseTicketForm">
            <div class="px-3 container">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label for="category">Category</label>
                    <select class="form-control" id="category" formControlName="category">
                      <option value="">Select Category</option>
                      <option [value]="category?.name" *ngFor="let category of categories">{{category?.value}}</option>
                    </select>
                    <small *ngIf="submitted && raiseTicketForm.get('category').hasError('required')" class="text-danger">
                      Field is required.
                    </small>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label for="subject">Subject</label>
                    <input class="form-control" id="subject" type="text" formControlName="subject">
                    <small *ngIf="submitted && raiseTicketForm.get('subject').hasError('required')" class="text-danger">
                      Field is required.
                    </small>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label for="message">Message</label>
                    <textarea class="form-control" id="message" type="text" formControlName="message" rows="5" style="resize: none"></textarea>
                    <small *ngIf="submitted && raiseTicketForm.get('message').hasError('required')"
                           class="text-danger">
                      Field is required.
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="card-footer upper-shadow-light pb-3px">
          <button class="btn btn-danger color-white mb-2" (click)="back()">
            <i class="fas fa-window-close mr-1"></i>Cancel
          </button>
          <button class="btn btn-success color-white mb-2 pull-right" [disabled]="submitting" (click)="submit()">
            <i class="fas fa-save mr-1" *ngIf="!submitting"></i>
            <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
