import {Component, OnInit} from '@angular/core';
import {MySaleIncomeReportService} from '../../../shared/services/my-sale-income-report.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';

@Component({
  selector: 'app-my-sale-income-report',
  templateUrl: './my-sale-income-report.component.html',
  styleUrls: ['./my-sale-income-report.component.scss']
})
export class StockistMySaleIncomeReportComponent implements OnInit {
  submitting = false;
  mySaleIncomeReport = [];
  showSearch: any = false;
  searchForm: FormGroup;
  bsConfig: Partial<BsDatepickerConfig>;
  pagination = {
    page: 1,
    total: null,
    perpage: 15,
    pages: null
  };

  constructor(public mySaleIncomeReportService: MySaleIncomeReportService,
              private fb: FormBuilder) {
    this.bsConfig = Object.assign({}, {containerClass: 'theme-dark-blue', dateInputFormat: 'YYYY-MM-DD'}, {showWeekNumbers: false});
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    this.searchForm = this.fb.group({
      'transaction_no': [''],
      'deduction': [''],
      'payout': [''],
      'remark': [''],
      'date': ['']
    });
    this.get();
  }

  get(params: any = {}) {
    params.page = this.pagination.page;
    params.perpage = this.pagination.perpage;
    this.submitting = true;
    this.mySaleIncomeReport = [];
    this.mySaleIncomeReportService.get(params).subscribe(data => {
      if (data && data['data'] && data['data'].length > 0) {
        this.mySaleIncomeReport = data['data'];
        this.pagination = {
          page: data['pagination'].page,
          total: data['pagination'].total,
          perpage: data['pagination'].perpage,
          pages: data['pagination'].pages,
        };
        this.triggerScroll();
      } else {
        this.mySaleIncomeReport = [];
      }
      this.submitting = false;
    }, error => {
      this.submitting = false;
    });
  }

  triggerScroll() {
    window.scrollTo(500, 500);
    window.scrollTo(500, 0);
  }

  onPageChange(page) {
    this.pagination.page = page;
    this.get();
  }

  search() {
    this.pagination = {
      page: 1,
      total: null,
      perpage: 15,
      pages: null
    };
    let params = {
      'transaction_no': (this.searchForm && this.searchForm.value) ? this.searchForm.value.transaction_no : '',
      'deduction': (this.searchForm && this.searchForm.value) ? this.searchForm.value.deduction : '',
      'payout': (this.searchForm && this.searchForm.value) ? this.searchForm.value.payout : '',
      'remark': (this.searchForm && this.searchForm.value) ? this.searchForm.value.remark : '',
      'date': (this.searchForm && this.searchForm.value) ? moment(this.searchForm.value.date).format('YYYY-MM-DD') : ''
    };
    this.get(params);
  }
}
