<section>
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="card mt-4">
        <div class="card-header bottom-shadow-light">
          <div class="bar-success">
            <h4 class="card-title mb-3 ng-star-inserted">Profile Photo</h4>
          </div>
        </div>
        <div class="card-body mt-1">
          <div class="row mb-2">
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 text-center">
              <img [src]="profileImage" width="200" height="200"/>
            </div>
            <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
              <button class="btn btn-primary color-white mt-5" [disabled]="submitting" (click)="fileUpload.click()">
                <i class="fas fa-save mr-1" *ngIf="!submitting"></i>
                <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>Update Image
              </button>
              <input class="form-control display-hidden" id="file" type="file" (change)="onFileChange($event)" #fileUpload>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="card mt-4">
        <div class="card-header bottom-shadow-light">
          <div class="bar-success">
            <h4 class="card-title mb-3 ng-star-inserted">Personal Information</h4>
          </div>
        </div>
        <div class="card-body mt-1">
          <form class="form" novalidate [formGroup]="personalInformationForm">
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label for="first_name">First Name</label>
                  <input class="form-control" id="first_name" type="text" formControlName="first_name">
                  <small *ngIf="submitted && personalInformationForm.get('first_name').hasError('required')"
                         class="text-danger">
                    Field is required.
                  </small>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label for="last_name">Last Name</label>
                  <input class="form-control" id="last_name" type="text" formControlName="last_name">
                  <small *ngIf="submitted && personalInformationForm.get('last_name').hasError('required')"
                         class="text-danger">
                    Field is required.
                  </small>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label for="username">Username</label>
                  <input class="form-control" id="username" type="text" formControlName="username">
                  <small *ngIf="submitted && personalInformationForm.get('username').hasError('required')"
                         class="text-danger">
                    Field is required.
                  </small>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label for="user_id">Account Id</label>
                  <input class="form-control" id="user_id" type="text" formControlName="user_id">
                  <small *ngIf="submitted && personalInformationForm.get('user_id').hasError('required')"
                         class="text-danger">
                    Field is required.
                  </small>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label for="email">Email</label>
                  <input class="form-control" id="email" type="text" formControlName="email">
                  <small *ngIf="submitted && personalInformationForm.get('email').hasError('required')" class="text-danger">
                    Field is required.
                  </small>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label for="dob">Date Of Birth</label>
                  <input class="form-control" id="dob" formControlName="dob" [bsConfig]="bsConfig" bsDatepicker>
                  <small *ngIf="submitted && personalInformationForm.get('dob').hasError('required')" class="text-danger">
                    Field is required.
                  </small>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label for="gender">Gender</label>
                  <select class="form-control" id="gender" formControlName="sex">
                    <option value="">Select Gender</option>
                    <option [value]="gender?.name" *ngFor="let gender of genders">{{gender?.value}}</option>
                  </select>
                  <small *ngIf="submitted && personalInformationForm.get('sex').hasError('required')" class="text-danger">
                    Field is required.
                  </small>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label for="mobile">Mobile</label>
                  <input class="form-control" id="mobile" type="number" formControlName="telephone">
                  <small *ngIf="submitted && personalInformationForm.get('telephone').hasError('required')"
                         class="text-danger">
                    Field is required.
                  </small>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label for="whatsapp">Whatsapp</label>
                  <input class="form-control" id="whatsapp" type="text" formControlName="whatsapp">
                  <small *ngIf="submitted && personalInformationForm.get('whatsapp').hasError('required')"
                         class="text-danger">
                    Field is required.
                  </small>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label for="telegram">Telegram</label>
                  <input class="form-control" id="telegram" type="text" formControlName="telegram">
                  <small *ngIf="submitted && personalInformationForm.get('telegram').hasError('required')"
                         class="text-danger">
                    Field is required.
                  </small>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="form-group">
                  <label for="address">Address</label>
                  <textarea class="form-control resize-none" id="address" type="text" formControlName="address"
                            rows="5">

                  </textarea>
                  <small *ngIf="submitted && personalInformationForm.get('address').hasError('required')"
                         class="text-danger">
                    Field is required.
                  </small>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label for="city">City</label>
                  <input class="form-control" id="city" type="text" formControlName="city">
                  <small *ngIf="submitted && personalInformationForm.get('city').hasError('required')" class="text-danger">
                    Field is required.
                  </small>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label for="state">State</label>
                  <select class="form-control" id="state" formControlName="state">
                    <option value="">Select State</option>
                    <option [value]="state?.name" *ngFor="let state of states">{{state?.name}}</option>
                  </select>
                  <small *ngIf="submitted && personalInformationForm.get('state').hasError('required')" class="text-danger">
                    Field is required.
                  </small>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label for="passport">Passport</label>
                  <input class="form-control" id="passport" type="text" formControlName="passport">
                  <small *ngIf="submitted && personalInformationForm.get('passport').hasError('required')"
                         class="text-danger">
                    Field is required.
                  </small>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label for="gst_no">GSTIN</label>
                  <input class="form-control" id="gst_no" type="text" formControlName="gst_no">
                  <small *ngIf="submitted && personalInformationForm.get('gst_no').hasError('required')"
                         class="text-danger">
                    Field is required.
                  </small>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="form-group">
                  <label for="aboutus">About Us</label>
                  <textarea class="form-control resize-none" id="aboutus" type="text" formControlName="aboutus"
                            rows="5">

                  </textarea>
                  <small *ngIf="submitted && personalInformationForm.get('aboutus').hasError('required')"
                         class="text-danger">
                    Field is required.
                  </small>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="card-footer upper-shadow-light pb-3px">
          <button class="btn btn-danger color-white mb-2" (click)="back()">
            <i class="fas fa-window-close mr-1"></i>Cancel
          </button>
          <button class="btn btn-success color-white mb-2 pull-right" [disabled]="submitting" (click)="update()">
            <i class="fas fa-save mr-1" *ngIf="!submitting"></i>
            <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>Update
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
