import {Component, OnInit} from '@angular/core';
import {TermsAndConditionsService} from '../../../shared/services/terms-and-conditions.service';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {
  submitting = false;
  termsAndConditions: any;
  total: any = 0;

  constructor(public termsAndConditionsService: TermsAndConditionsService) {
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    this.get();
  }

  get(params: any = {}) {
    this.submitting = true;
    this.termsAndConditionsService.get(params).subscribe(data => {
      if (data) {
        this.termsAndConditions = data;
        this.triggerScroll();
      }
      this.submitting = false;
    }, error => {
      this.submitting = false;
    });
  }

  triggerScroll() {
    window.scrollTo(500, 500);
    window.scrollTo(500, 0);
  }
}
