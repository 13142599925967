<section>
  <ng-template #content>
    <div id="modal">
      <div class="modal-header font-weight-bold">
        <h4 class="modal-title" *ngIf="productDetail">Product Details</h4>
        <h4 class="modal-title text-capitalize"
            *ngIf="productStockInfo">{{productStockInfo['data']?.product_name.toLowerCase()}}</h4>
        <button class="close btn-close-modal" (click)="modalService.dismissAll('')">
          <span aria-hidden="true" class="close-modal-cross">x</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row" *ngIf="productDetail">
          <div class="col-12">
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                <img src="https://eshopping.axicoayurveda.com/cmsadmin/product_images/{{productDetail.actual_image}}"
                     width="200" height="200"/>
              </div>
            </div>
            <div class="row mt-3 p-2 shadow-light">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                Product Name
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                {{productDetail?.product_name}}
              </div>
            </div>
            <div class="row p-2 shadow-light">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                Price
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                &#8377; {{productDetail?.price}}
              </div>
            </div>
            <div class="row p-2 shadow-light">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                BV's
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                {{productDetail?.points}}
              </div>
            </div>
            <div class="row p-2">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                Details
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                {{productDetail?.description}}
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="productStockInfo">
          <div class="col-12">
            <div class="row mt-1 p-2">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                {{productStockInfo?.info}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary color-white pull-right" (click)="this.modalService.dismissAll('')">
          Close
        </button>
      </div>
    </div>
  </ng-template>

  <div class="card mt-4" *ngIf="selectedStockist">
    <div class="card-body mt-1">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12 text-capitalize">
              <h6>Stockist Id - {{selectedStockist?.user_id}}</h6>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12 text-capitalize">
              <h6>Stockist Name
                - {{selectedStockist?.first_name.toLowerCase()}} {{selectedStockist?.last_name.toLowerCase()}}</h6>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12 text-capitalize">
              <h6>Address - {{selectedStockist?.address.toLowerCase()}}</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12 text-capitalize">
              <h6>Landmark - {{selectedStockist?.landmark.toLowerCase()}}</h6>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12 text-capitalize">
              <h6>State - {{selectedStockist?.state.toLowerCase()}}</h6>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12 text-capitalize">
              <h6>Country - {{selectedStockist?.country.toLowerCase()}}</h6>
            </div>
          </div>
        </div>
        <div class="col-12 text-center">
          <button type="button" class="btn btn-sm btn-outline-primary round mt-2" (click)="navigateTo('users/eshop')">
            <i class="fas fa-chevron-circle-left mr-2"></i>Choose Another Stockist
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-2 mb-2">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="content-header f_20 module-header ml-2 mt-18px">My Cart</div>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="row p-sm-2 d-none d-md-block overflow-x-scroll">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <table class="table table-list overflow-x-scroll">
            <thead>
            <tr>
              <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">S.No</h6></th>
              <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Name</h6></th>
              <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Image</h6></th>
              <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Price</h6></th>
              <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">BVs</h6></th>
              <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Quantity</h6></th>
              <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">GST</h6></th>
              <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Total</h6></th>
              <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Remove</h6></th>
            </tr>
            </thead>
            <tbody *ngIf="cartItems && cartItems.length > 0; else noDataToShowWeb">
            <tr *ngFor="let cart of cartItems; let i = index">
              <td class="text-center">
                <h6 class="mt-2">{{i + 1}}.</h6>
              </td>
              <td class="text-center"><h6 class="mt-1 text-capitalize cursor-pointer text-info mt-2"
                                          (click)="details(content, cart)">{{cart?.product_name.toLowerCase()}}</h6>
              </td>
              <td class="text-center cursor-pointer" (click)="details(content, cart)">
                <img src="https://eshopping.axicoayurveda.com/cmsadmin/product_images/{{cart?.actual_image}}" width="40"
                     height="40"/>
              </td>
              <td class="text-center"><h6 class="mt-2 text-capitalize">{{cart?.price}}</h6></td>
              <td class="text-center"><h6 class="mt-2">{{cart?.points}}</h6></td>
              <td class="text-center">
                <div role="group" class="btn-group" *ngIf="cart?.addedToCart">
                  <button type="button" class="btn btn-outline-primary" (click)="removeItem(cart)">
                    <i class="fas fa-minus-circle"></i>
                  </button>
                  <button type="button" class="btn btn-primary disabled">
                    {{cart?.quantity}}
                  </button>
                  <button type="button" class="btn btn-outline-primary" (click)="addItem(content, cart)">
                    <i class="fas fa-plus-circle"></i>
                  </button>
                </div>
              </td>
              <td class="text-center"><h6 class="mt-2">{{cart?.gst}} ({{cart?.tax}}%)</h6></td>
              <td class="text-center"><h6 class="mt-1">{{cart?.total}}</h6></td>
              <td class="text-center">
                <i class="fas fa-trash text-danger cursor-pointer mt-2" title="Remove Product"
                   (click)="removeAllItem(cart)"></i>
              </td>
            </tr>
            <tr>
              <td colspan="3" class="font-weight-bold text-center">Sub Total - &#8377;{{totalData?.subTotal}}</td>
              <td colspan="3" class="font-weight-bold text-center">GST - &#8377;{{totalData?.gstTotal}}</td>
              <td colspan="3" class="font-weight-bold text-center">Total - &#8377;{{totalData?.total}}</td>
            </tr>
            <tr>
              <td colspan="9" class="font-weight-bold text-center">
                <button class="btn btn-success color-white pull-right"
                        (click)="navigateTo('users/eshop/update-address')">
                  <i class="fas fa-credit-card mr-2"></i>Buy Now
                </button>
                <button class="btn btn-primary color-white pull-right mr-2"
                        (click)="navigateTo('users/eshop/products')">
                  <i class="fas fa-plus-circle mr-2"></i>More Items
                </button>
              </td>
            </tr>
            </tbody>
            <ng-template #noDataToShowWeb>
              <tbody>
              <tr>
                <td colspan="9" class="text-center"><h6>No data to show</h6></td>
              </tr>
              </tbody>
            </ng-template>
          </table>
        </div>
      </div>

      <div class="row d-block d-md-none" *ngIf="cartItems && cartItems.length > 0; else noDataToShowMobile">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="card" *ngFor="let cart of cartItems; let i = index">
            <div class="card-header bottom-shadow-light pb-18px">
              <div class="row">
                <div class="col-12">
                  <h4 class="card-title text-uppercase card-ellipsis pb-3">{{i + 1}}
                    . {{cart?.product_name.toLowerCase()}}</h4>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row p-2">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1 text-center">
                  <img (click)="details(content, cart)"
                       src="https://eshopping.axicoayurveda.com/cmsadmin/product_images/{{cart?.actual_image}}"
                       width="40"
                       height="40"/>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2">
                  <div class="row">
                    <div class="col-6">
                      Price
                    </div>
                    <div class="col-6">
                      &#8377;{{cart?.price}}
                    </div>
                  </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2">
                  <div class="row">
                    <div class="col-6">
                      BVs
                    </div>
                    <div class="col-6">
                      {{cart?.points}}
                    </div>
                  </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2">
                  <div class="row">
                    <div class="col-6">
                      Quantity
                    </div>
                    <div class="col-6">
                      <div role="group" class="btn-group" *ngIf="cart?.addedToCart">
                        <button type="button" class="btn btn-sm btn-outline-primary" (click)="removeItem(cart)">
                          <i class="fas fa-minus-circle"></i>
                        </button>
                        <button type="button" class="btn btn-sm btn-primary disabled">
                          {{cart?.quantity}}
                        </button>
                        <button type="button" class="btn btn-sm btn-outline-primary" (click)="addItem(content, cart)">
                          <i class="fas fa-plus-circle"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2">
                  <div class="row">
                    <div class="col-6">
                      GST
                    </div>
                    <div class="col-6">
                      &#8377;{{cart?.gst}} ({{cart?.tax}}%)
                    </div>
                  </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2">
                  <div class="row">
                    <div class="col-6">
                      Total
                    </div>
                    <div class="col-6">
                      &#8377;{{cart?.total}}
                    </div>
                  </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2">
                  <div class="row">
                    <div class="col-6">
                      Total
                    </div>
                    <div class="col-6">
                      &#8377;{{cart?.total}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div class="row">
                    <div class="col-6 font-weight-bold">
                      Sub Total
                    </div>
                    <div class="col-6 font-weight-bold">
                      &#8377;{{totalData?.subTotal}}
                    </div>
                  </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2">
                  <div class="row">
                    <div class="col-6 font-weight-bold">
                      GST
                    </div>
                    <div class="col-6 font-weight-bold">
                      &#8377;{{totalData?.gstTotal}}
                    </div>
                  </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2">
                  <div class="row">
                    <div class="col-6 font-weight-bold">
                      Total
                    </div>
                    <div class="col-6 font-weight-bold">
                      &#8377;{{totalData?.total}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                  <button class="btn btn-success color-white">
                    <i class="fas fa-credit-card mr-2"></i>Buy Now
                  </button>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1 text-center">
                  <button class="btn btn-primary color-white">
                    <i class="fas fa-plus-circle mr-2"></i>More Items
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ng-template #noDataToShowMobile>
        <div class="row d-block d-md-none">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="card text-center">
              <h5 class="p-5">No data to show</h5>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</section>
