import {Routes, RouterModule} from '@angular/router';
import {DashboardComponent} from 'app/users/dashboard/dashboard.component';
import {AuthGuard} from '../auth/auth-guard.service';
import {DirectReferralsComponent} from '../../users/my-network/direct-referrals/direct-referrals.component';
import {DownlineMembersComponent} from '../../users/my-network/downline-members/downline-members.component';
import {MyWalletComponent} from '../../users/wallet/my-wallet/my-wallet.component';
import {EwalletWithdrawalComponent} from '../../users/wallet/ewallet-withdrawal/ewallet-withdrawal.component';
import {PairingBonusComponent} from '../../users/earnings/pairing-bonus/pairing-bonus.component';
import {SelfRepurchaseBonusComponent} from '../../users/earnings/self-repurchase-bonus/self-repurchase-bonus.component';
import {PerformanceBonusComponent} from '../../users/earnings/performance-bonus/performance-bonus.component';
import {LeadershipBonusComponent} from '../../users/earnings/leadership-bonus/leadership-bonus.component';
import {DirectorBonusComponent} from '../../users/earnings/director-bonus/director-bonus.component';
import {CarFundBonusComponent} from '../../users/earnings/car-fund-bonus/car-fund-bonus.component';
import {HouseFundBonusComponent} from '../../users/earnings/house-fund-bonus/house-fund-bonus.component';
import {TourFundBonusComponent} from '../../users/earnings/tour-fund-bonus/tour-fund-bonus.component';
import {PerformanceBonusOverviewComponent} from '../../users/earnings/performance-bonus-overview/performance-bonus-overview.component';
import {DirectorSideLegRepurchaseReportComponent} from '../../users/earnings/director-side-leg-repurchase-report/director-side-leg-repurchase-report.component';
import {PurchaseEshopInvoicesComponent} from '../../users/invoices-section/purchase-eshop-invoices/purchase-eshop-invoices.component';
import {MyPackageInvoicesComponent} from '../../users/invoices-section/my-package-invoices/my-package-invoices.component';
import {ViewTicketResponseComponent} from '../../users/support-center/view-ticket-response/view-ticket-response.component';
import {TermsAndConditionsComponent} from '../../users/policy-section/terms-and-conditions/terms-and-conditions.component';
import {OfficialAnnouncementsComponent} from '../../users/official-announcements/official-announcements.component';
import {AnnouncementDetailComponent} from '../../users/official-announcements/announcement-detail/announcement-detail.component';
import {OpenTicketComponent} from '../../users/support-center/open-ticket/open-ticket.component';
import {ReferralLinksComponent} from '../../users/marketing-tools/referral-links/referral-links.component';
import {MyWalletTransactionHistoryComponent} from '../../users/wallet/my-wallet-transaction-history/my-wallet-transaction-history.component';
import {ManageProfileComponent} from '../../users/profile/manage-profile/manage-profile.component';
import {BeneficieryDetailsComponent} from '../../users/profile/beneficiery-details/beneficiery-details.component';
import {ChangePasswordComponent} from '../../users/profile/change-password/change-password.component';
import {KycComponent} from '../../users/profile/kyc/kyc.component';
import {EshopComponent} from '../../users/eshop/eshop.component';
import {ProductsComponent} from '../../users/eshop/products/products.component';
import {CartComponent} from '../../users/eshop/cart/cart.component';
import {UpdateAddressComponent} from '../../users/eshop/update-address/update-address.component';
import {EshopSuccessComponent} from '../../users/eshop/eshop-success/eshop-success.component';
import {PackageUpgradeComponent} from '../../users/package-upgrade/package-upgrade.component';
import {MatchingTreeComponent} from '../../users/my-network/matching-tree/matching-tree.component';
import {StockistDashboardComponent} from '../../stockists/dashboard/dashboard.component';
import {StockistMySaleIncomeReportComponent} from '../../stockists/my-income-report/my-sale-income-report/my-sale-income-report.component';
import {StockistWalletTransactionHistoryComponent} from '../../stockists/my-income-report/wallet-transaction-history/wallet-transaction-history.component';
import {StockistMyStockListComponent} from '../../stockists/stock-management/my-stock-list/my-stock-list.component';
import {StockistPurchaseProductsHistoryComponent} from '../../stockists/stock-management/purchase-products-history/purchase-products-history.component';
import {StockistPendingInvoicesComponent} from '../../stockists/user-eshop-invoice-section/pending-invoices/pending-invoices.component';
import {StockistDeliveredInvoicesComponent} from '../../stockists/user-eshop-invoice-section/delivered-invoices/delivered-invoices.component';
import {StockistCancelledInvoicesComponent} from '../../stockists/user-eshop-invoice-section/cancelled-invoices/cancelled-invoices.component';
import {StockistUserPackagePendingInvoicesComponent} from '../../stockists/user-package-invoice-section/pending-invoices/pending-invoices.component';
import {StockistDeliveredInvoicesUserPackageComponent} from '../../stockists/user-package-invoice-section/delivered-invoices/delivered-invoices.component';
import {StockistCancelledUserPackageInvoicesComponent} from '../../stockists/user-package-invoice-section/cancelled-invoices/cancelled-invoices.component';
import {StockistTermsConditionsComponent} from '../../stockists/media-documents/terms-conditions/terms-conditions.component';
import {StockistPrivacyPoliciesComponent} from '../../stockists/media-documents/privacy-policies/privacy-policies.component';
import {StockistProfileComponent} from '../../stockists/my-account/profile/profile.component';
import {StockistPurchaseProductsComponent} from '../../stockists/stock-management/purchase-products/purchase-products.component';
import {StockistCartComponent} from '../../stockists/stock-management/cart/cart.component';
import {StockistShopSuccessComponent} from '../../stockists/stock-management/shop-success/shop-success.component';
import {StockistBillingOrdersComponent} from '../../stockists/billing-section/billing-orders/billing-orders.component';
import {StockistUserProductsComponent} from '../../stockists/billing-section/products/products.component';
import {StockistUserUpdateAddressComponent} from '../../stockists/billing-section/update-address/update-address.component';
import {StockistShopSuccessUserComponent} from '../../stockists/billing-section/shop-success/shop-success.component';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  /*{
    path: 'users/page',
    loadChildren: () => import('../../page/page.module').then(m => m.PageModule)
  }*/
  {
    path: 'users/dashboard',
    component: DashboardComponent,
    data: {
      title: 'Dashboard'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'users/my-network/direct-referrals',
    component: DirectReferralsComponent,
    data: {
      title: 'Direct Referrals'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'users/my-network/downline-members',
    component: DownlineMembersComponent,
    data: {
      title: 'Downline Members'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'users/my-network/matching-tree',
    component: MatchingTreeComponent,
    data: {
      title: 'Matching Tree'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'users/wallet/my-wallet',
    component: MyWalletComponent,
    data: {
      title: 'My Wallet'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'users/wallet/my-wallet/transaction-history',
    component: MyWalletTransactionHistoryComponent,
    data: {
      title: 'My Wallet Transaction History'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'users/wallet/ewallet-withdrawal',
    component: EwalletWithdrawalComponent,
    data: {
      title: 'E - Wallet Withdrawal'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'users/earnings/pairing-bonus',
    component: PairingBonusComponent,
    data: {
      title: 'Pairing Bonus'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'users/earnings/self-repurchase-bonus',
    component: SelfRepurchaseBonusComponent,
    data: {
      title: 'Self Repurchase Bonus'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'users/earnings/performance-bonus',
    component: PerformanceBonusComponent,
    data: {
      title: 'Performance Bonus'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'users/earnings/leadership-bonus',
    component: LeadershipBonusComponent,
    data: {
      title: 'Leadership Bonus'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'users/earnings/director-bonus',
    component: DirectorBonusComponent,
    data: {
      title: 'Director Bonus'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'users/earnings/car-fund-bonus',
    component: CarFundBonusComponent,
    data: {
      title: 'Car Fund Bonus'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'users/earnings/house-fund-bonus',
    component: HouseFundBonusComponent,
    data: {
      title: 'House Fund Bonus'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'users/earnings/tour-fund-bonus',
    component: TourFundBonusComponent,
    data: {
      title: 'Tour Fund Bonus'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'users/earnings/performance-bonus-overview',
    component: PerformanceBonusOverviewComponent,
    data: {
      title: 'Performance Bonus Overview'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'users/earnings/directors-sideleg-repurcahse-report',
    component: DirectorSideLegRepurchaseReportComponent,
    data: {
      title: 'Director\'s Side Leg Repurchase Report'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'users/marketing-tools/referral-links',
    component: ReferralLinksComponent,
    data: {
      title: 'Referral Links'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'users/invoices-section/purchase-eshop-invoices',
    component: PurchaseEshopInvoicesComponent,
    data: {
      title: 'Purchase E - Shop Invoices'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'users/invoices-section/my-package-invoices',
    component: MyPackageInvoicesComponent,
    data: {
      title: 'My Package Invoices'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'users/support-center/view-ticket-responses',
    component: ViewTicketResponseComponent,
    data: {
      title: 'View Ticket Response'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'users/support-center/open-ticket',
    component: OpenTicketComponent,
    data: {
      title: 'Open Ticket'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'users/policy-section/terms-and-conditions',
    component: TermsAndConditionsComponent,
    data: {
      title: 'Terms and Conditions'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'users/official-announcements',
    component: OfficialAnnouncementsComponent,
    data: {
      title: 'Official Announcements'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'users/official-announcement/:id',
    component: AnnouncementDetailComponent,
    data: {
      title: 'Announcement Detail'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'users/profile/manage-profile',
    component: ManageProfileComponent,
    data: {
      title: 'Manage Profile'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'users/profile/beneficiery-details',
    component: BeneficieryDetailsComponent,
    data: {
      title: 'Beneficiery Details'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'users/profile/change-password',
    component: ChangePasswordComponent,
    data: {
      title: 'Change Password'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'users/profile/kyc',
    component: KycComponent,
    data: {
      title: 'KYC'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'users/eshop',
    component: EshopComponent,
    data: {
      title: 'E - Shop'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'users/eshop/products',
    component: ProductsComponent,
    data: {
      title: 'Products'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'users/eshop/cart',
    component: CartComponent,
    data: {
      title: 'Cart'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'users/eshop/update-address',
    component: UpdateAddressComponent,
    data: {
      title: 'Update Address'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'users/eshop/eshop-success',
    component: EshopSuccessComponent,
    data: {
      title: 'E - Shop Success'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'users/package-upgrade',
    component: PackageUpgradeComponent,
    data: {
      title: 'Package Upgrade'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'stockists/dashboard',
    component: StockistDashboardComponent,
    data: {
      title: 'Dashboard'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'stockists/my-account/profile',
    component: StockistProfileComponent,
    data: {
      title: 'Profile'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'stockists/my-income-report/my-sale-income-report',
    component: StockistMySaleIncomeReportComponent,
    data: {
      title: 'My Sale Income Report'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'stockists/my-income-report/wallet-transaction-history',
    component: StockistWalletTransactionHistoryComponent,
    data: {
      title: 'Wallet Transaction History'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'stockists/stock-management/my-stock-list',
    component: StockistMyStockListComponent,
    data: {
      title: 'My Stock List'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'stockists/stock-management/purchase-products',
    component: StockistPurchaseProductsComponent,
    data: {
      title: 'Purchase Products'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'stockists/stock-management/purchase-products-history',
    component: StockistPurchaseProductsHistoryComponent,
    data: {
      title: 'Purchase Product History'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'stockists/stock-management/cart',
    component: StockistCartComponent,
    data: {
      title: 'Cart'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'stockists/user-ehsop-invoice-section/pending-invoices',
    component: StockistPendingInvoicesComponent,
    data: {
      title: 'Pending Invoices'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'stockists/user-ehsop-invoice-section/delivered-invoices',
    component: StockistDeliveredInvoicesComponent,
    data: {
      title: 'Delivered Invoices'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'stockists/user-ehsop-invoice-section/cancelled-invoices',
    component: StockistCancelledInvoicesComponent,
    data: {
      title: 'Cancelled Invoices'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'stockists/user-package-invoice-section/pending-invoices',
    component: StockistUserPackagePendingInvoicesComponent,
    data: {
      title: 'Pending Invoices'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'stockists/user-package-invoice-section/delivered-invoices',
    component: StockistDeliveredInvoicesUserPackageComponent,
    data: {
      title: 'Delivered Invoices'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'stockists/user-package-invoice-section/cancelled-invoices',
    component: StockistCancelledUserPackageInvoicesComponent,
    data: {
      title: 'Cancelled Invoices'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'stockists/media-documents/terms-conditions',
    component: StockistTermsConditionsComponent,
    data: {
      title: 'Terms & Conditions'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'stockists/media-documents/privacy-policies',
    component: StockistPrivacyPoliciesComponent,
    data: {
      title: 'Privacy Policies'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'stockists/stock-management/shop-success',
    component: StockistShopSuccessComponent,
    data: {
      title: 'Shop Success'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'stockists/billing-section/billing-orders',
    component: StockistBillingOrdersComponent,
    data: {
      title: 'Billing Orders'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'stockists/billing-section/products',
    component: StockistUserProductsComponent,
    data: {
      title: 'Products'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'stockists/billing-orders/update-address',
    component: StockistUserUpdateAddressComponent,
    data: {
      title: 'Update Address'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'stockists/billing-orders/shop-success',
    component: StockistShopSuccessUserComponent,
    data: {
      title: 'Shop Success'
    },
    canActivate: [AuthGuard]
  },
];
