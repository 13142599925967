import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {AppUrl} from '../constants/app-url';

@Injectable({
  providedIn: 'root'
})
export class WalletService extends BaseService {
  constructor(public http: HttpClient,
              public alertService: AlertService,
              public globalService: GlobalService) {
    super(http, alertService, globalService);
  }

  getWalletAmount(data): Observable<any> {
    return this.getRequest(AppUrl.GET_WALLET_AMOUNT(), data);
  }

  getFundWalletAmount(data): Observable<any> {
    return this.getRequest(AppUrl.GET_FUND_WALLET_AMOUNT(), data);
  }

  verifyPassword(data): Observable<any> {
    return this.postRequest(AppUrl.VERIFY_PASSWORD(), data);
  }

  verifyStockistPassword(data): Observable<any> {
    return this.postRequest(AppUrl.VERIFY_STOCKIST_PASSWORD(), data);
  }

  onlinePurchase(data): Observable<any> {
    return this.postRequest(AppUrl.ONLINE_PURCHASE(), data);
  }

  walletPurchase(data): Observable<any> {
    return this.postRequest(AppUrl.WALLET_PURCHASE(), data);
  }

  fundWalletPurchase(data): Observable<any> {
    return this.postRequest(AppUrl.FUND_WALLET_PURCHASE(), data);
  }

  fundWalletUserPurchase(data): Observable<any> {
    return this.postRequest(AppUrl.FUND_WALLET_USER_PURCHASE(), data);
  }
}
