<section>
  <ng-template #content>
    <div id="modal">
      <div class="modal-header font-weight-bold">
        <h4 class="modal-title" *ngIf="productDetail">Product Details</h4>
        <button class="close btn-close-modal" (click)="modalService.dismissAll('')">
          <span aria-hidden="true" class="close-modal-cross">x</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row" *ngIf="productDetail">
          <div class="col-12">
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                <img src="https://eshopping.axicoayurveda.com/cmsadmin/product_images/{{productDetail.actual_image}}"
                     width="200" height="200"/>
              </div>
            </div>
            <div class="row mt-3 p-2 shadow-light">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                Product Name
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                {{productDetail?.product_name}}
              </div>
            </div>
            <div class="row p-2 shadow-light">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                Price
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                &#8377; {{productDetail?.price}}
              </div>
            </div>
            <div class="row p-2 shadow-light">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                BV's
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                {{productDetail?.points}}
              </div>
            </div>
            <div class="row p-2">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                Details
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                {{productDetail?.description}}
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="productStockInfo">
          <div class="col-12">
            <div class="row mt-1 p-2">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                {{productStockInfo?.info}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary color-white pull-right" (click)="this.modalService.dismissAll('')">
          Close
        </button>
      </div>
    </div>
  </ng-template>
  <div class="row mt-2 mb-2">
    <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
      <div class="content-header f_20 module-header ml-2 mt-18px">My Stock List</div>
    </div>
    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
      <button class="btn btn-primary pull-right color-white mt-2 color-white users-btn mr-2" (click)="showSearch = !showSearch">
        <i aria-hidden="true" class="fa fa-search mr-2"></i>Search
      </button>
    </div>
  </div>
  <div class="row mt-2 mb-2" *ngIf="showSearch">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <form class="form background-white ml-15px mr-15px" novalidate [formGroup]="searchForm">
        <div class="row">
          <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12">
            <div class="form-group ml-2 pt-3 pb-1 mt--5px">
              <input class="form-control" id="product_name" type="text" formControlName="product_name" placeholder="Product Name">
            </div>
          </div>
          <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12">
            <div class="form-group ml-2 pt-3 pb-1 mt--5px">
              <input class="form-control" id="price" type="text" formControlName="price" placeholder="Product MRP">
            </div>
          </div>
          <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12">
            <div class="form-group ml-2 pt-3 pb-1 mt--5px">
              <input class="form-control" id="product_dp" type="text" formControlName="product_dp" placeholder="Product DP">
            </div>
          </div>
          <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12">
            <div class="form-group ml-2 pt-3 pb-1 mt--5px">
              <input class="form-control" id="product_bv" type="text" formControlName="product_bv" placeholder="Product BV">
            </div>
          </div>
          <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12">
            <div class="form-group ml-2 pt-3 pb-1 mt--5px">
              <input class="form-control" id="quantity" type="text" formControlName="quantity" placeholder="Quantity">
            </div>
          </div>
          <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 text-center">
            <button class="btn btn-primary btn-block btn-raised color-white width-200px mt-16px" (click)="search()">Search</button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="row p-sm-2 d-none d-md-block overflow-x-scroll">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <table class="table table-list overflow-x-scroll">
        <thead>
        <tr>
          <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">S.No</h6></th>
          <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Product Name</h6></th>
          <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Product MRP</h6></th>
          <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Product DP</h6></th>
          <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Product BV</h6></th>
          <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Product Quantity</h6></th>
          <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Description</h6></th>
        </tr>
        </thead>
        <tbody *ngIf="myStockList && myStockList.length > 0; else noDataToShowWeb">
        <tr *ngFor="let myStock of myStockList; let i = index">
          <td class="text-center">
            <h6 class="mt-1">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}.</h6>
          </td>
          <td class="text-center"><h6 class="mt-1">{{myStock?.product_name}}</h6></td>
          <td class="text-center"><h6 class="mt-1">{{myStock?.price}}</h6></td>
          <td class="text-center"><h6 class="mt-1">{{myStock?.product_dp}}</h6></td>
          <td class="text-center"><h6 class="mt-1">{{myStock?.product_bv}}</h6></td>
          <td class="text-center"><h6 class="mt-1">{{myStock?.quantity}}</h6></td>
          <td class="text-center">
            <button class="btn btn-primary" (click)="view(content, myStock)">View Description</button>
          </td>
        </tr>
        </tbody>
        <ng-template #noDataToShowWeb>
          <tbody>
          <tr>
            <td colspan="7" class="text-center"><h6>No data to show</h6></td>
          </tr>
          </tbody>
        </ng-template>
      </table>
    </div>
  </div>

  <div class="row d-block d-md-none"
       *ngIf="myStockList && myStockList.length > 0; else noDataToShowMobile">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="card" *ngFor="let myStockList of myStockList; let i = index">
        <div class="card-header bottom-shadow-light pb-18px">
          <div class="row">
            <div class="col-12">
              <h4
                class="card-title text-uppercase card-ellipsis pb-3">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                . {{myStockList?.product_name}}</h4>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row p-2">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
              Product MRP - {{myStockList?.price}}
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
              Product DP - {{myStockList?.product_dp}}
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
              Product BP - {{myStockList?.product_bv}}
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
              Product Quantity - {{myStockList?.quantity}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #noDataToShowMobile>
    <div class="row d-block d-md-none">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card text-center">
          <h5 class="p-5">No data to show</h5>
        </div>
      </div>
    </div>
  </ng-template>

  <div class="row pagination-table">
    <ngb-pagination (pageChange)="onPageChange($event)"
                    [(page)]="pagination.page"
                    [boundaryLinks]="true"
                    [collectionSize]="pagination.total"
                    [maxSize]="3"
                    [pageSize]="pagination.perpage">
    </ngb-pagination>
  </div>
</section>
