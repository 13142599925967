import {Component, OnInit, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {Router, NavigationEnd} from '@angular/router';
import {filter} from 'rxjs/operators';
import {SnotifyService} from 'ng-snotify';
import {GlobalService} from './shared/services/global.service';
import {AuthService} from './shared/services/auth.service';
import {StorageService} from './shared/services/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  subscription: Subscription;

  constructor(private snotifyService: SnotifyService,
              private globalService: GlobalService,
              private authService: AuthService,
              private router: Router) {
    this.checkLogin();

    this.globalService.reloadSelf.subscribe((d) => {
      if (d) {
        this.reloadSelf();
        this.globalService.reloadSelf.next(false);
      }
    })
  }

  ngOnInit() {
    this.checkLogin();
    this.subscription = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => window.scrollTo(0, 0));
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  checkLogin() {
    if (StorageService.getItem('accessToken') && StorageService.getItem('self')) {
      this.globalService.setAccessToken(StorageService.getItem('accessToken'));
      this.globalService.setSelf(StorageService.getItem('self'));
      this.reloadSelf();
      // this.metaService.refreshMeta();
      // this.router.navigateByUrl('');
    } else {
      this.router.navigateByUrl('login');
    }
  }

  reloadSelf() {
    this.authService.self({all: true}).subscribe((data) => {
      // this.globalService.setAccessToken(data.token);
      if (data) {
        this.globalService.setSelf({
          'first_name': (data && data.first_name) ? data.first_name : '',
          'last_name': (data && data.last_name) ? data.last_name : '',
          'userId': (data && data.user_id) ? data.user_id : '',
          'username': (data && data.username) ? data.username : '',
          'email': (data && data.email) ? data.email : '',
          'image': (data && data.image) ? data.image : '',
          'userType': (data && data.user_type) ? data.user_type : '',
        });
      }
      // this.globalService.setSelf(userDetails);
      return;
    }, e => {
      StorageService.clearAll();
      this.router.navigateByUrl('/login');
      /*this.authService.logout().subscribe(data => {
        this.router.navigateByUrl('');
      });*/
    });
  }
}
