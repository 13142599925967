import {Component, OnInit} from '@angular/core';
import {PrivacyPoliciesStockistService} from '../../../shared/services/privacy-policies-stockist.service';

@Component({
  selector: 'app-privacy-policies',
  templateUrl: './privacy-policies.component.html',
  styleUrls: ['./privacy-policies.component.scss']
})
export class StockistPrivacyPoliciesComponent implements OnInit {
  submitting = false;
  privacyPolicies: any;

  constructor(public privacyPoliciesStockistService: PrivacyPoliciesStockistService) {
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    this.get();
  }

  get(params: any = {}) {
    this.submitting = true;
    this.privacyPoliciesStockistService.get(params).subscribe(data => {
      if (data) {
        this.privacyPolicies = data;
        this.triggerScroll();
      }
      this.submitting = false;
    }, error => {
      this.submitting = false;
    });
  }

  triggerScroll() {
    window.scrollTo(500, 500);
    window.scrollTo(500, 0);
  }
}
