<section>
  <div class="row mt-2 mb-2">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="content-header f_20 module-header ml-2 mt-18px">Referral Links</div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="card">
        <div class="row mt-3 mb-3">
          <div class="col-12 text-center">
            <span class="font-weight-bold mr-2">Left Referral Link:</span> <a [href]="referralLinks?.left_referral_link"
                                                                              target="_blank">{{referralLinks?.left_referral_link}}</a>
          </div>
          <div class="col-12 text-center mt-3">
            <button type="button" class="btn btn-social btn-facebook mr-2" (click)="navigateTo(referralLinks?.facebook_sharer_left)">
              <i class="fab fa-facebook-f"></i>Facebook
            </button>
            <button type="button" class="btn btn-social btn-whatsapp mr-2" (click)="navigateTo(referralLinks?.whatsapp_sharer_left)">
              <i class="fab fa-whatsapp"></i>Whatsapp
            </button>
            <button type="button" class="btn btn-social btn-twitter mr-2" (click)="navigateTo(referralLinks?.twitter_sharer_left)">
              <i class="fab fa-twitter"></i>Twitter
            </button>
          </div>
        </div>
        <div class="row mt-3 mb-3">
          <div class="col-12 text-center">
            <span class="font-weight-bold mr-2">Right Referral Link:</span> <a
            [href]="referralLinks?.right_referral_link" target="_blank">{{referralLinks?.right_referral_link}}</a>
          </div>
          <div class="col-12 text-center mt-3">
            <button type="button" class="btn btn-social btn-facebook mr-2" (click)="navigateTo(referralLinks?.facebook_sharer_right)">
              <i class="fab fa-facebook-f"></i>Facebook
            </button>
            <button type="button" class="btn btn-social btn-whatsapp mr-2" (click)="navigateTo(referralLinks?.whatsapp_sharer_right)">
              <i class="fab fa-whatsapp"></i>Whatsapp
            </button>
            <button type="button" class="btn btn-social btn-twitter mr-2" (click)="navigateTo(referralLinks?.twitter_sharer_right)">
              <i class="fab fa-twitter"></i>Twitter
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
