import {Component, OnInit} from '@angular/core';
import {MyStockListService} from '../../../shared/services/my-stock-list.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup} from '@angular/forms';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-my-stock-list',
  templateUrl: './my-stock-list.component.html',
  styleUrls: ['./my-stock-list.component.scss']
})

export class StockistMyStockListComponent implements OnInit {
  submitting = false;
  showSearch: any = false;
  myStockList = [];
  productDetail: any;
  searchForm: FormGroup;
  bsConfig: Partial<BsDatepickerConfig>;
  pagination = {
    page: 1,
    total: null,
    perpage: 15,
    pages: null
  };

  constructor(public myStockListService: MyStockListService,
              public modalService: NgbModal,
              private fb: FormBuilder) {
    this.bsConfig = Object.assign({}, {containerClass: 'theme-dark-blue', dateInputFormat: 'YYYY-MM-DD'}, {showWeekNumbers: false});
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    this.searchForm = this.fb.group({
      'product_name': [''],
      'price': [''],
      'product_dp': [''],
      'product_bv': [''],
      'quantity': ['']
    });
    this.get();
  }

  get(params: any = {}) {
    params.page = this.pagination.page;
    params.perpage = this.pagination.perpage;
    this.submitting = true;
    this.myStockList = [];
    this.myStockListService.get(params).subscribe(data => {
      if (data && data['data'] && data['data'].length > 0) {
        this.myStockList = data['data'];
        this.pagination = {
          page: data['pagination'].page,
          total: data['pagination'].total,
          perpage: data['pagination'].perpage,
          pages: data['pagination'].pages,
        };
        this.triggerScroll();
      } else {
        this.myStockList = [];
      }
      this.submitting = false;
    }, error => {
      this.submitting = false;
    });
  }

  view(content, data) {
    this.productDetail = data;
    this.productDetail['description'] = this.productDetail['description'].replace(/\&nbsp;/g, '').replace(/\&amp;/g, '');
    this.modalService.open(content, {'size': 'xl'});
  }

  triggerScroll() {
    window.scrollTo(500, 500);
    window.scrollTo(500, 0);
  }

  onPageChange(page) {
    this.pagination.page = page;
    this.get();
  }

  search() {
    this.pagination = {
      page: 1,
      total: null,
      perpage: 15,
      pages: null
    };
    let params = {
      'product_name': (this.searchForm && this.searchForm.value) ? this.searchForm.value.product_name : '',
      'price': (this.searchForm && this.searchForm.value) ? this.searchForm.value.price : '',
      'product_dp': (this.searchForm && this.searchForm.value) ? this.searchForm.value.product_dp : '',
      'product_bv': (this.searchForm && this.searchForm.value) ? this.searchForm.value.product_bv : '',
      'quantity': (this.searchForm && this.searchForm.value) ? this.searchForm.value.quantity : ''
    };
    this.get(params);
  }
}
