import {Component, OnInit} from '@angular/core';
import {ReferralLinksService} from '../../../shared/services/referral-links.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-referral-links',
  templateUrl: './referral-links.component.html',
  styleUrls: ['./referral-links.component.scss']
})
export class ReferralLinksComponent implements OnInit {
  submitting = false;
  referralLinks: any;

  constructor(public referralLinkService: ReferralLinksService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    this.get();
  }

  get(params: any = {}) {
    this.submitting = true;
    this.referralLinkService.get(params).subscribe(data => {
      if (data) {
        this.referralLinks = data;
        this.triggerScroll();
      }
      this.submitting = false;
    }, error => {
      this.submitting = false;
    });
  }

  navigateTo(url) {
    window.open(url, '_blank')
  }

  triggerScroll() {
    window.scrollTo(500, 500);
    window.scrollTo(500, 0);
  }
}
