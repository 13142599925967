import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {AppUrl} from '../constants/app-url';
import {UploadService} from './upload.service';

@Injectable({
  providedIn: 'root'
})
export class KycService extends BaseService {
  constructor(public http: HttpClient,
              public alertService: AlertService,
              public globalService: GlobalService,
              public fileUploadService: UploadService) {
    super(http, alertService, globalService);
  }

  getPan(data): Observable<any> {
    return this.getRequest(AppUrl.GET_PAN_DETAIL(), data);
  }

  getAddress(data): Observable<any> {
    return this.getRequest(AppUrl.GET_ADDRESS_DETAIL(), data);
  }

  getBank(data): Observable<any> {
    return this.getRequest(AppUrl.GET_BANK_DETAIL(), data);
  }

  userDocUpload(params, event, type): Observable<any> {
    if (type == 'PAN') {
      return this.fileUploadService.fileUpload(AppUrl.UPLOAD_KYC_PAN_WEB(), params, event)
    } else if (type == 'AADHAR') {
      return this.fileUploadService.fileUpload(AppUrl.UPLOAD_KYC_AADHAR_WEB(), params, event)
    } else if (type == 'BANK') {
      return this.fileUploadService.fileUpload(AppUrl.UPLOAD_KYC_BANK_WEB(), params, event)
    }
  }

  updatePanDetail(data): Observable<any> {
    return this.postRequest(AppUrl.UPDATE_PAN_DETAIL(), data);
  }

  updateAadharDetail(data): Observable<any> {
    return this.postRequest(AppUrl.UPDATE_AADHAR_DETAIL(), data);
  }

  updateBankDetail(data): Observable<any> {
    return this.postRequest(AppUrl.UPDATE_BANK_DETAIL(), data);
  }
}
