import {Component, OnInit} from '@angular/core';
import {StorageService} from '../../../shared/services/storage.service';
import {GlobalService} from '../../../shared/services/global.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
  selectedStockist: any;
  cartItems = [];
  productDetail: any;
  productStockInfo: any;
  totalData: any;

  constructor(public globalService: GlobalService,
              public modalService: NgbModal,
              private router: Router) {
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    this.selectedStockist = StorageService.getItem('selectedStockist');
    if (this.selectedStockist) {
      this.cartItems = StorageService.getItem('cartItems');
      this.setTaxesAndTotal();
    } else {
      StorageService.removeItem('cartItems');
      this.globalService.changeCartCount([]);
    }
  }

  setTaxesAndTotal() {
    if (this.cartItems && this.cartItems.length > 0) {
      let gstArr = [];
      let subTotalArr = [];
      let totalArr = [];
      this.cartItems.forEach(cart => {
        cart['singleItemTax'] = ((parseFloat(cart['price']) * (parseFloat(cart['tax']) / 100))).toFixed(2);
        cart['gst'] = ((parseFloat(cart['price']) * (parseFloat(cart['tax']) / 100)) * parseInt(cart['quantity'])).toFixed(2);
        cart['subTotal'] = (parseFloat(cart['price']) * parseInt(cart['quantity'])).toFixed(2);
        cart['total'] = (parseFloat(cart['subTotal']) + parseFloat(cart['gst'])).toFixed(2);
        gstArr.push(cart['gst']);
        subTotalArr.push(cart['subTotal'])
        totalArr.push(cart['total']);
      });
      this.totalData = {'gstTotal': gstArr.reduce((a, b) => parseFloat(a) + parseFloat(b), 0).toFixed(2)};
      this.totalData['subTotal'] = subTotalArr.reduce((a, b) => parseFloat(a) + parseFloat(b), 0).toFixed(2)
      this.totalData['total'] = totalArr.reduce((a, b) => parseFloat(a) + parseFloat(b), 0).toFixed(2);
      StorageService.setItem('cartItems', this.cartItems);
    }
  }

  details(content, data) {
    this.productStockInfo = undefined;
    this.productDetail = data;
    this.productDetail['description'] = this.productDetail['description'].replace(/\&nbsp;/g, '').replace(/\&amp;/g, '');
    this.modalService.open(content, {'size': 'xl'});
  }

  addItem(content, data) {
    if ((parseInt(data['quantity']) + 1) <= parseInt(data['qty'])) {
      data['quantity'] = (data['quantity'] ? parseInt(data['quantity']) : 0) + 1;
      this.setToLocalStorage(data);
    } else {
      this.productStockInfo = {'data': data};
      this.productStockInfo['info'] = `The stockist has only ${data['qty']} of these available. To see if product is available with another stockist select another stockist from eshop stockist select page and then proceed.`;
      this.productDetail = undefined;
      this.modalService.open(content, {'size': 'lg'});
    }
  }

  removeItem(data) {
    data['quantity'] = (data['quantity'] ? parseInt(data['quantity']) : 0) - 1;
    data['addedToCart'] = (parseInt(data['quantity']) > 0);
    this.setToLocalStorage(data);
  }

  removeAllItem(data) {
    data['quantity'] = 0;
    data['addedToCart'] = (parseInt(data['quantity']) > 0);
    this.setToLocalStorage(data);
  }

  setToLocalStorage(data) {
    let cartData = StorageService.getItem('cartItems');
    if (cartData && cartData.length > 0) {
      let dataExists = false;
      cartData.forEach(cart => {
        if (parseInt(data['product_id']) === parseInt(cart['product_id'])) {
          cart['quantity'] = data['quantity'];
          cart['singleItemTax'] = ((parseFloat(cart['price']) * (parseFloat(cart['tax']) / 100))).toFixed(2);
          cart['gst'] = ((parseFloat(cart['price']) * (parseFloat(cart['tax']) / 100)) * parseInt(data['quantity'])).toFixed(2);
          cart['subTotal'] = (parseFloat(cart['price']) * parseInt(data['quantity'])).toFixed(2);
          cart['total'] = (parseFloat(cart['subTotal']) + parseFloat(cart['gst'])).toFixed(2);
          dataExists = true;
        }
      });
      if (!data['quantity'] || data['quantity'] === 0) {
        let removeIndex = cartData.map(function (item) {
          return item.product_id;
        }).indexOf(data.product_id);
        cartData.splice(removeIndex, 1);
      }
      if (!dataExists) {
        cartData.push(data);
      }
      StorageService.setItem('cartItems', cartData);
    } else {
      StorageService.setItem('cartItems', [data]);
    }
    this.cartItems = StorageService.getItem('cartItems');
    this.globalService.changeCartCount(this.cartItems);
    this.setTaxesAndTotal();
  }

  navigateTo(url) {
    this.router.navigateByUrl(url)
  }
}
