import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertService} from '../../../shared/services/alert.service';
import {Location} from '@angular/common';
import {StorageService} from '../../../shared/services/storage.service';
import {ChangePasswordService} from '../../../shared/services/change-password.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  submitted: any = false;
  submitting: any = false;
  user: any;

  constructor(private router: Router,
              private fb: FormBuilder,
              public activatedRoute: ActivatedRoute,
              private alertService: AlertService,
              private _location: Location,
              private changePasswordService: ChangePasswordService) {
  }

  ngOnInit() {
    this.refresh();
  }

  refresh() {
    this.changePasswordForm = this.fb.group({
      'username': [{value: '', 'disabled': true}],
      'fullName': [{value: '', 'disabled': true}],
      'old_password': ['', Validators.required],
      'password': ['', Validators.required],
      'confirm_password': ['', Validators.required]
    });
    this.user = StorageService.getItem('self');
    this.changePasswordForm.patchValue({
      'username': (this.user && this.user['username']) ? this.user['username'] : '',
      'fullName': (this.user && this.user['first_name'] && this.user['last_name']) ? this.user['first_name'] + ' ' + this.user['last_name'] : '',
    });
  }

  back() {
    this._location.back();
  }

  update() {
    this.submitted = true;
    if (!this.changePasswordForm.valid) {
      return;
    }
    if (this.changePasswordForm.value.confirm_password !== this.changePasswordForm.value.password) {
      this.alertService.showErrors('New Password and Confirm Password didn\'t match');
      return;
    }
    if (this.submitted) {
      const params = {
        'old_password': (this.changePasswordForm.value && this.changePasswordForm.value.old_password) ? this.changePasswordForm.value.old_password : '',
        'password': (this.changePasswordForm.value && this.changePasswordForm.value.password) ? this.changePasswordForm.value.password : '',
      };
      this.submitting = true;
      this.changePasswordService.update(params).subscribe(data => {
        this.submitting = false;
        this.submitted = false;
        this.changePasswordForm.patchValue({
          'old_password': '',
          'password': '',
          'confirm_password': ''
        });
        this.triggerScroll();
      }, error => {
        this.submitting = false;
        this.submitted = false;
      });
    }
  }

  triggerScroll() {
    window.scrollTo(500, 500);
    window.scrollTo(500, 0);
  }
}
