import {Component, OnInit} from '@angular/core';
import {ProductService} from '../../../shared/services/product.service';
import {StorageService} from '../../../shared/services/storage.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AlertService} from '../../../shared/services/alert.service';
import {GlobalService} from '../../../shared/services/global.service';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {FormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})

export class ProductsComponent implements OnInit {
  products: any = [];
  selectedStockist: any = {};
  productDetail: any;
  productStockInfo: any;
  cartItems: any = [];
  showSearch: any = false;
  searchForm: FormGroup;
  tempProducts = [];

  constructor(public productService: ProductService,
              public modalService: NgbModal,
              public alertService: AlertService,
              public globalService: GlobalService,
              public router: Router,
              private _location: Location,
              private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.refresh();
    this.cartItems = StorageService.getItem('cartItems');
  }

  refresh() {
    this.searchForm = this.fb.group({
      'product_name': [''],
      /*'price': [''],
      'product_bv': ['']*/
    });
    this.selectedStockist = StorageService.getItem('selectedStockist');
    if (this.selectedStockist) {
      this.getProducts();
    } else {
      StorageService.removeItem('cartItems');
      this.globalService.changeCartCount([]);
      this.back();
    }
  }

  details(content, data) {
    this.productStockInfo = undefined;
    this.productDetail = data;
    this.productDetail['description'] = this.productDetail['description'].replace(/\&nbsp;/g, '').replace(/\&amp;/g, '');
    this.modalService.open(content, {'size': 'xl'});
  }

  getProducts() {
    this.productService.get({'puc': this.selectedStockist.user_id,}).subscribe(data => {
      this.products = data;
      this.tempProducts = data;
      if (this.products && this.cartItems && this.products.length > 0 && this.cartItems.length > 0) {
        this.products.forEach(product => {
          if (product) {
            this.cartItems.forEach(cart => {
              if (parseInt(product['product_id']) === parseInt(cart['product_id'])) {
                product['addedToCart'] = cart['addedToCart'];
                product['quantity'] = cart['quantity'];
              }
            });
          }
        });
      }
      this.triggerScroll();
    });
  }

  addToCart(content, data) {
    if (parseInt(data['qty']) > 0) {
      data['addedToCart'] = true;
      data['quantity'] = 1;
      this.setToLocalStorage(data);
    } else {
      this.productStockInfo = {'data': data};
      this.productStockInfo['info'] = 'Out of stock, this product is temporarily out of stock with this stockist. To see if product is available with another stockist select another stockist from eshop stockist select page and then proceed.';
      this.productDetail = undefined;
      this.modalService.open(content, {'size': 'lg'});
    }
  }

  addItem(content, data) {
    if ((parseInt(data['quantity']) + 1) <= parseInt(data['qty'])) {
      data['quantity'] = (data['quantity'] ? parseInt(data['quantity']) : 0) + 1;
      this.setToLocalStorage(data);
    } else {
      this.productStockInfo = {'data': data};
      this.productStockInfo['info'] = `The stockist has only ${data['qty']} of these available. To see if product is available with another stockist select another stockist from eshop stockist select page and then proceed.`;
      this.productDetail = undefined;
      this.modalService.open(content, {'size': 'lg'});
    }
  }

  removeItem(data) {
    data['quantity'] = (data['quantity'] ? parseInt(data['quantity']) : 0) - 1;
    data['addedToCart'] = (parseInt(data['quantity']) > 0);
    this.setToLocalStorage(data);
  }

  setToLocalStorage(data) {
    let cartData = StorageService.getItem('cartItems');
    if (cartData && cartData.length > 0) {
      let dataExists = false;
      cartData.forEach(cart => {
        if (parseInt(data['product_id']) === parseInt(cart['product_id'])) {
          cart['quantity'] = data['quantity'];
          dataExists = true;
        }
      });
      if (!data['quantity'] || data['quantity'] === 0) {
        let removeIndex = cartData.map(function (item) {
          return item.product_id;
        }).indexOf(data.product_id);
        cartData.splice(removeIndex, 1);
      }
      if (!dataExists) {
        cartData.push(data);
      }
      StorageService.setItem('cartItems', cartData);
    } else {
      StorageService.setItem('cartItems', [data]);
    }
    this.cartItems = StorageService.getItem('cartItems');
    this.globalService.changeCartCount(this.cartItems);
  }

  back() {
    this._location.back();
  }

  triggerScroll() {
    window.scrollTo(500, 500);
    window.scrollTo(500, 0);
  }

  search() {
    let results = [];
    let searchField = 'product_name';
    let searchVal = (this.searchForm && this.searchForm.value) ? this.searchForm.value.product_name : '';
    if (searchVal && searchVal != '') {
      this.products = this.tempProducts;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i][searchField].search(searchVal) !== -1) {
          results.push(this.products[i]);
        }
      }
      this.products = results;
    } else {
      this.clearProducts();
    }
  }

  clearProducts() {
    this.searchForm.patchValue({
      'product_name': (this.searchForm && this.searchForm.value.product_name) ? this.searchForm.value.product_name : ''
    });
    this.getProducts();
  }
}
