import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AlertService} from '../../../shared/services/alert.service';
import {GlobalService} from '../../../shared/services/global.service';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {StorageService} from '../../../shared/services/storage.service';
import {ProductStockistService} from '../../../shared/services/product-stockist.service';
import {FormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-purchase-products',
  templateUrl: './purchase-products.component.html',
  styleUrls: ['./purchase-products.component.scss']
})

export class StockistPurchaseProductsComponent implements OnInit {
  products: any = [];
  selectedStockist: any = {};
  selectedUser: any = {};
  productDetail: any;
  productStockInfo: any;
  cartItems: any = [];
  showSearch: any = false;
  searchForm: FormGroup;

  constructor(public productStockistService: ProductStockistService,
              public modalService: NgbModal,
              public alertService: AlertService,
              public globalService: GlobalService,
              public router: Router,
              private _location: Location,
              private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.refresh();
    this.selectedUser = StorageService.getItem('selectedUser');
    this.selectedStockist = StorageService.getItem('selectedStockist');

    if (this.selectedUser) {
      StorageService.removeItem('selectedUser');
    }

    if (this.selectedStockist) {
      StorageService.removeItem('selectedStockist');
    }

    this.cartItems = StorageService.getItem('cartItems');
  }

  refresh() {
    this.searchForm = this.fb.group({
      'product_name': [''],
      'price': [''],
      'product_bv': ['']
    });
    this.getProducts();
  }

  details(content, data) {
    this.productStockInfo = undefined;
    this.productDetail = data;
    this.productDetail['description'] = this.productDetail['description'].replace(/\&nbsp;/g, '').replace(/\&amp;/g, '');
    this.modalService.open(content, {'size': 'xl'});
  }

  getProducts(params: any = {}) {
    this.productStockistService.get(params).subscribe(data => {
      this.products = data;
      if (this.products && this.cartItems && this.products.length > 0 && this.cartItems.length > 0) {
        this.products.forEach(product => {
          if (product) {
            this.cartItems.forEach(cart => {
              if (parseInt(product['product_id']) === parseInt(cart['product_id'])) {
                product['addedToCart'] = cart['addedToCart'];
                product['qty'] = cart['qty'];
              }
            });
          }
        });
      }
      this.triggerScroll();
    });
  }

  addToCart(content, data) {
    if (parseInt(data['quantity']) > 0) {
      data['addedToCart'] = true;
      data['qty'] = 1;
      this.setToLocalStorage(data);
    } else {
      this.productStockInfo = {'data': data};
      this.productStockInfo['info'] = 'Out of stock, this product is temporarily out of stock with us.';
      this.productDetail = undefined;
      this.modalService.open(content, {'size': 'lg'});
    }
  }

  addItem(content, data) {
    if ((parseInt(data['qty']) + 1) <= parseInt(data['quantity'])) {
      data['qty'] = (data['qty'] ? parseInt(data['qty']) : 0) + 1;
      this.setToLocalStorage(data);
    } else {
      this.productStockInfo = {'data': data};
      this.productStockInfo['info'] = `The stockist has only ${data['quantity']} of these available with us..`;
      this.productDetail = undefined;
      this.modalService.open(content, {'size': 'lg'});
    }
  }

  removeItem(data) {
    data['qty'] = (data['qty'] ? parseInt(data['qty']) : 0) - 1;
    data['addedToCart'] = (parseInt(data['qty']) > 0);
    this.setToLocalStorage(data);
  }

  setToLocalStorage(data) {
    let cartData = StorageService.getItem('cartItems');
    if (cartData && cartData.length > 0) {
      let dataExists = false;
      cartData.forEach(cart => {
        if (parseInt(data['product_id']) === parseInt(cart['product_id'])) {
          cart['qty'] = data['qty'];
          dataExists = true;
        }
      });
      if (!data['qty'] || data['qty'] === 0) {
        let removeIndex = cartData.map(function (item) {
          return item.product_id;
        }).indexOf(data.product_id);
        cartData.splice(removeIndex, 1);
      }
      if (!dataExists) {
        cartData.push(data);
      }
      StorageService.setItem('cartItems', cartData);
    } else {
      StorageService.setItem('cartItems', [data]);
    }
    this.cartItems = StorageService.getItem('cartItems');
    this.globalService.changeCartCount(this.cartItems);
  }

  back() {
    this._location.back();
  }

  triggerScroll() {
    window.scrollTo(500, 500);
    window.scrollTo(500, 0);
  }

  search() {
    let params = {
      'product_name': (this.searchForm && this.searchForm.value) ? this.searchForm.value.product_name : '',
      'price': (this.searchForm && this.searchForm.value) ? this.searchForm.value.price: '',
      'product_bv': (this.searchForm && this.searchForm.value) ? this.searchForm.value.product_bv : ''
    };
    this.getProducts(params);
  }
}
