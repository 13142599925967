import {Component, OnInit} from '@angular/core';
import {OfficialAnnouncementsService} from '../../../shared/services/official-announcements.service';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';

@Component({
  selector: 'app-announcement-detail',
  templateUrl: './announcement-detail.component.html',
  styleUrls: ['./announcement-detail.component.scss']
})
export class AnnouncementDetailComponent implements OnInit {
  submitting = false;
  announcementDetail: any;
  total: any = 0;
  id: any;

  constructor(public officialAnnouncementsService: OfficialAnnouncementsService,
              private activatedRoute: ActivatedRoute,
              private _location: Location) {
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    this.id = this.activatedRoute.snapshot.params['id'];
    if (this.id) {
      this.detail(this.id);
    }
  }

  detail(id) {
    const params = {
      'id': id
    };
    this.officialAnnouncementsService.detail(params).subscribe(data => {
      if (data) {
        this.announcementDetail = data;
        this.triggerScroll();
      }
    }, error => {
      this.back()
    });
  }

  back() {
    this._location.back();
  }

  triggerScroll() {
    window.scrollTo(500, 500);
    window.scrollTo(500, 0);
  }
}
