import {Component, OnInit} from '@angular/core';
import {DashboardService} from '../../shared/services/dashboard.service';
import {OfficialAnnouncementsService} from '../../shared/services/official-announcements.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  dashboard: any;

  constructor(private dashboardService: DashboardService) {
  }

  ngOnInit() {
    this.refresh();
  }

  refresh() {
    this.updateUserRank();
  }

  updateUserRank() {
    this.dashboardService.updateRank().subscribe(data => {
      this.get();
    });
  }

  get() {
    this.dashboardService.get().subscribe(data => {
      this.dashboard = data;
      this.triggerScroll();
    }, error => {

    });
  }

  triggerScroll() {
    window.scrollTo(500, 500);
    window.scrollTo(500, 0);
  }
}
