import {Component, OnInit} from '@angular/core';
import {PackageUpgradeService} from '../../shared/services/package-upgrade.service';
import {EshopService} from '../../shared/services/eshop.service';
import {WalletService} from '../../shared/services/wallet.service';
import {AlertService} from '../../shared/services/alert.service';
import {UpgradePackageService} from '../../shared/services/upgrade-package.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-package-upgrade',
  templateUrl: './package-upgrade.component.html',
  styleUrls: ['./package-upgrade.component.scss']
})
export class PackageUpgradeComponent implements OnInit {
  userAvailablePackages: any = [];
  paymentMode: any = [
    {
      'name': 'Activation Wallet',
      'value': 'ACTIVATION_WALLET'
    },
    {
      'name': 'Online Payment',
      'value': 'ONLINE_PAYMENT'
    }
  ];
  stockists: any = [];
  stockistPackages: any = [];

  constructor(public packageUpgradeService: PackageUpgradeService,
              public eshopService: EshopService,
              public walletService: WalletService,
              public alertService: AlertService,
              public upgradePackage: UpgradePackageService,
              public router: Router) {
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    this.getUserAvailablePackages();
  }

  getUserAvailablePackages() {
    this.packageUpgradeService.get({}).subscribe(data => {
      this.userAvailablePackages = data;
      this.getStockists();
    });
  }

  getStockists() {
    this.eshopService.getStockist({}).subscribe(data => {
      this.stockists = data;
      this.triggerScroll();
    });
  }

  getStockistPackage(event, amount) {
    if (this.stockists && this.stockists.length > 0) {
      let selectedStockist: any = {};
      this.stockists.forEach(stock => {
        if (parseInt(stock.user_id) === parseInt(event.target.value)) {
          selectedStockist = stock;
        }
      });
      if (selectedStockist) {
        this.eshopService.getStockistPackages({
          'amount': amount,
          'stockistUserId': selectedStockist.user_id
        }).subscribe(data => {
          this.stockistPackages = data;
          this.triggerScroll(window.pageXOffset, window.pageYOffset);
        }, error => {
          this.triggerScroll(window.pageXOffset, window.pageYOffset);
        });
      }
    }
  }

  activate(packageId, paymentMode, productId, stockistId, password) {
    this.walletService.verifyPassword({'password': password}).subscribe(data => {
      if (data.status === 'success') {
        this.upgrade(packageId, paymentMode, productId, stockistId);
      } else {
        this.alertService.showErrors('Wrong Password');
      }
    });
  }

  upgrade(packageId, paymentMode, productId, stockistId) {
    let params = {
      'packageId': packageId,
      'paymentMode': paymentMode,
      'productId': productId,
      'stockistId': stockistId
    };
    this.upgradePackage.upgrade(params).subscribe(data => {
      if (paymentMode === 'ONLINE_PAYMENT') {
        if (data && data.gateWayUrl) {
          window.open(data.gateWayUrl, '_self');
        }
      } else if (paymentMode === 'ACTIVATION_WALLET') {
        this.alertService.showSuccess({'title': 'Scuccess', 'message': data.message});
        this.router.navigateByUrl('dashboard');
      }
    });
  }

  triggerScroll(x?, y?) {
    window.scrollTo(500, 500);
    window.scrollTo(500, 0);
    if (x || y) {
      window.scrollTo(x, y);
    }
  }
}
