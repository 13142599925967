import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {AppUrl} from '../constants/app-url';
import {UploadService} from './upload.service';

@Injectable({
  providedIn: 'root'
})
export class ManageProfileStockistService extends BaseService {
  constructor(public http: HttpClient,
              public alertService: AlertService,
              public globalService: GlobalService,
              public fileUploadService: UploadService) {
    super(http, alertService, globalService);
  }

  get(data): Observable<any> {
    return this.getRequest(AppUrl.GET_STOCKIST_DETAILS(), data);
  }

  getAccountDetail(data): Observable<any> {
    return this.getRequest(AppUrl.GET_STOCKIST_ACCOUNT_DETAILS(), data);
  }

  getProfileImage(data): Observable<any> {
    return this.getRequest(AppUrl.GET_STOCKIST_PROFILE_IMAGE(), data);
  }

  updatePersonalInfo(data): Observable<any> {
    return this.putRequest(AppUrl.UPDATE_STOCKIST_DETAIL(), data);
  }

  updateBankInfo(data): Observable<any> {
    return this.putRequest(AppUrl.UPDATE_STOCKIST_BANK_DETAIL(), data);
  }

  stockistImageUpload(params, event): Observable<any> {
    return this.fileUploadService.fileUpload(AppUrl.STOCKIST_IMAGE_UPLOAD(), params, event)
  }
}
