import {Component, OnInit} from '@angular/core';
import {GlobalService} from '../../../shared/services/global.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {StorageService} from '../../../shared/services/storage.service';
import {WalletService} from '../../../shared/services/wallet.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class StockistCartComponent implements OnInit {
  selectedUser: any;
  cartItems = [];
  productDetail: any;
  productStockInfo: any;
  totalData: any;
  proceedToBuy: any;
  inputTypePassword: any = 'password';
  walletBalance: any;
  passwordForm: FormGroup;

  constructor(public globalService: GlobalService,
              public modalService: NgbModal,
              private router: Router,
              private walletService: WalletService,
              private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    this.passwordForm = this.fb.group({
      'password': ['']
    });
    this.selectedUser = StorageService.getItem('selectedUser');
    this.cartItems = StorageService.getItem('cartItems');
    this.setTaxesAndTotal();
    this.fetchWalletBalance();
  }

  setTaxesAndTotal() {
    if (this.cartItems && this.cartItems.length > 0) {
      let gstArr = [];
      let subTotalArr = [];
      let totalArr = [];
      this.cartItems.forEach(cart => {
        cart['singleItemTax'] = cart['tax'];
        cart['gst'] = (parseFloat(cart['tax']) * parseInt(cart['qty'])).toFixed(2);
        cart['subTotal'] = (parseFloat(cart['price']) * parseInt(cart['qty'])).toFixed(2);
        cart['total'] = (parseFloat(cart['subTotal']) + parseFloat(cart['gst'])).toFixed(2);
        gstArr.push(cart['gst']);
        subTotalArr.push(cart['subTotal']);
        totalArr.push(cart['total']);
      });
      this.totalData = {'gstTotal': gstArr.reduce((a, b) => parseFloat(a) + parseFloat(b), 0).toFixed(2)};
      this.totalData['subTotal'] = subTotalArr.reduce((a, b) => parseFloat(a) + parseFloat(b), 0).toFixed(2)
      this.totalData['total'] = totalArr.reduce((a, b) => parseFloat(a) + parseFloat(b), 0).toFixed(2);
      StorageService.setItem('cartItems', this.cartItems);
    }
  }

  fetchWalletBalance() {
    this.walletService.getFundWalletAmount({}).subscribe(data => {
      if (data && data['wallet_balance']) {
        this.walletBalance = (data && data['wallet_balance']) ? data['wallet_balance'].replace(/,/g, '') : 0;
      }
    });
  }

  confirm() {
    let params = {
      'password': (this.passwordForm.value && this.passwordForm.value.password) ? this.passwordForm.value.password : ''
    };
    this.walletService.verifyStockistPassword(params).subscribe(data => {
      if (data && data.status === 'success') {
        this.fundWalletPurchase();
      }
    });
  }

  fundWalletPurchase() {
    let cartItems = StorageService.getItem('cartItems');
    if (cartItems && cartItems.length > 0) {
      let products = [];
      cartItems.forEach(item => {
        products.push({
          'product_id': item?.product_id,
          'price': item?.price,
          'points': item?.points,
          'tax': item?.singleItemTax,
          'quantity': item?.qty
        })
      });
      let params = {
        'products': products,
        'sub_total': this.totalData?.subTotal,
        'tax': this.totalData?.gstTotal,
        'total': this.totalData?.total,
      };
      this.walletService.fundWalletPurchase(params).subscribe(data => {
        let invoiceUrl = `https://eshopping.axicoayurveda.com/dashboard/${data.invoiceUrl}`;
        StorageService.setItem('invoiceUrl', invoiceUrl);
        this.globalService.changeCartCount([]);
        this.modalService.dismissAll('');
        this.router.navigateByUrl('/stockists/stock-management/shop-success');
      });
    }
  }

  details(content, data) {
    this.proceedToBuy = undefined;
    this.productStockInfo = undefined;
    this.productDetail = data;
    this.productDetail['description'] = this.productDetail['description'].replace(/\&nbsp;/g, '').replace(/\&amp;/g, '');
    this.modalService.open(content, {'size': 'xl'});
  }

  addItem(content, data) {
    if ((parseInt(data['qty']) + 1) <= parseInt(data['quantity'])) {
      data['qty'] = (data['qty'] ? parseInt(data['qty']) : 0) + 1;
      this.setToLocalStorage(data);
    } else {
      this.productStockInfo = {'data': data};
      this.productStockInfo['info'] = `Currently, there are only ${data['quantity']} of these available with us.`;
      this.productDetail = undefined;
      this.modalService.open(content, {'size': 'lg'});
    }
  }

  removeItem(data) {
    data['qty'] = (data['qty'] ? parseInt(data['qty']) : 0) - 1;
    data['addedToCart'] = (parseInt(data['qty']) > 0);
    this.setToLocalStorage(data);
  }

  removeAllItem(data) {
    data['qty'] = 0;
    data['addedToCart'] = (parseInt(data['qty']) > 0);
    this.setToLocalStorage(data);
  }

  setToLocalStorage(data) {
    let cartData = StorageService.getItem('cartItems');
    if (cartData && cartData.length > 0) {
      let dataExists = false;
      cartData.forEach(cart => {
        if (parseInt(data['product_id']) === parseInt(cart['product_id'])) {
          cart['qty'] = data['qty'];
          cart['singleItemTax'] = ((parseFloat(cart['price']) * (parseFloat(cart['tax']) / 100))).toFixed(2);
          cart['gst'] = ((parseFloat(cart['price']) * (parseFloat(cart['tax']) / 100)) * parseInt(data['qty'])).toFixed(2);
          cart['subTotal'] = (parseFloat(cart['price']) * parseInt(data['qty'])).toFixed(2);
          cart['total'] = (parseFloat(cart['subTotal']) + parseFloat(cart['gst'])).toFixed(2);
          dataExists = true;
        }
      });
      if (!data['qty'] || data['qty'] === 0) {
        let removeIndex = cartData.map(function (item) {
          return item.product_id;
        }).indexOf(data.product_id);
        cartData.splice(removeIndex, 1);
      }
      if (!dataExists) {
        cartData.push(data);
      }
      StorageService.setItem('cartItems', cartData);
    } else {
      StorageService.setItem('cartItems', [data]);
    }
    this.cartItems = StorageService.getItem('cartItems');
    this.globalService.changeCartCount(this.cartItems);
    this.setTaxesAndTotal();
  }

  buyNow(content) {
    this.productDetail = undefined;
    this.proceedToBuy = true;
    this.modalService.open(content, {'size': 'lg'});
  }

  navigateTo(url) {
    this.router.navigateByUrl(url)
  }
}
