import {Component, OnInit} from '@angular/core';
import {EwalletWithdrawalService} from '../../../shared/services/ewallet-withdrawal.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';

@Component({
  selector: 'app-ewallet-withdrawal',
  templateUrl: './ewallet-withdrawal.component.html',
  styleUrls: ['./ewallet-withdrawal.component.scss']
})
export class EwalletWithdrawalComponent implements OnInit {
  submitting = false;
  eWalletsWithdrawals = [];
  showSearch: any = false;
  searchForm: FormGroup;
  bsConfig: Partial<BsDatepickerConfig>;
  pagination = {
    page: 1,
    total: null,
    perpage: 15,
    pages: null
  };

  constructor(public eWalletsWithdrawalService: EwalletWithdrawalService,
              private fb: FormBuilder) {
    this.bsConfig = Object.assign({}, {containerClass: 'theme-dark-blue', dateInputFormat: 'YYYY-MM-DD'}, {showWeekNumbers: false});
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    this.searchForm = this.fb.group({
      'transaction_no': [''],
      'requested_amount': [''],
      'transaction_charge': [''],
      'request_date': [''],
      'response_date': ['']
    });
    this.get();
  }

  get(params: any = {}) {
    params.page = this.pagination.page;
    params.perpage = this.pagination.perpage;
    this.submitting = true;
    this.eWalletsWithdrawals = [];
    this.eWalletsWithdrawalService.get(params).subscribe(data => {
      if (data && data['data'] && data['data'].length > 0) {
        this.eWalletsWithdrawals = data['data'];
        this.pagination = {
          page: data['pagination'].page,
          total: data['pagination'].total,
          perpage: data['pagination'].perpage,
          pages: data['pagination'].pages,
        };
        this.triggerScroll();
      } else {
        this.eWalletsWithdrawals = [];
      }
      this.submitting = false;
    }, error => {
      this.submitting = false;
    });
  }

  triggerScroll() {
    window.scrollTo(500, 500);
    window.scrollTo(500, 0);
  }

  onPageChange(page) {
    this.pagination.page = page;
    this.get();
  }

  search() {
    this.pagination = {
      page: 1,
      total: null,
      perpage: 15,
      pages: null
    };
    let params = {
      'transaction_no': (this.searchForm && this.searchForm.value) ? this.searchForm.value.transaction_no : '',
      'requested_amount': (this.searchForm && this.searchForm.value) ? this.searchForm.value.requested_amount : '',
      'transaction_charge': (this.searchForm && this.searchForm.value) ? this.searchForm.value.transaction_charge : '',
      'request_date': (this.searchForm && this.searchForm.value) ? moment(this.searchForm.value.request_date).format('YYYY-MM-DD') : '',
      'response_date': (this.searchForm && this.searchForm.value) ? moment(this.searchForm.value.response_date).format('YYYY-MM-DD') : ''
    };
    this.get(params);
  }
}
