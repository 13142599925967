import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AlertService} from '../../../shared/services/alert.service';
import {Location} from '@angular/common';
import {EshopService} from '../../../shared/services/eshop.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {GlobalService} from '../../../shared/services/global.service';
import {StorageService} from '../../../shared/services/storage.service';
import {BillingOrdersService} from '../../../shared/services/billing-orders.service';

@Component({
  selector: 'app-billing-orders',
  templateUrl: './billing-orders.component.html',
  styleUrls: ['./billing-orders.component.scss']
})
export class StockistBillingOrdersComponent implements OnInit {
  stockists: any = [];
  selectedStockist: any;
  selectedUser: any;
  cartItems: any = [];
  billingOrdersForm: FormGroup;
  users: any = [];

  constructor(private router: Router,
              private fb: FormBuilder,
              private alertService: AlertService,
              private _location: Location,
              private eshopService: EshopService,
              public modalService: NgbModal,
              public globalService: GlobalService,
              public billingOrdersService: BillingOrdersService) {
  }

  ngOnInit() {
    this.refresh();
  }

  refresh() {
    this.selectedUser = StorageService.getItem('selectedUser');
    this.billingOrdersForm = this.fb.group({
      'search': ['']
    });
    this.cartItems = StorageService.getItem('cartItems');
    this.getStockist();
  }

  getStockist(params: any = {}) {
    this.eshopService.getStockist(params).subscribe(data => {
      this.stockists = data;
      this.selectedStockist = undefined;
      this.triggerScroll();
    });
  }

  getUsers(content) {
    this.billingOrdersService.getUsers({'search': this.billingOrdersForm.value.search}).subscribe(data => {
      if (data && data.length > 0) {
        this.users = data;
        this.modalService.open(content, {'size': 'lg'});
      }
      this.triggerScroll();
    });
    /*this.selectedStockist = (event && event.target.value) ? event.target.value : undefined;
    if (this.selectedStockist) {
      this.eshopService.getProducts({'puc': this.selectedStockist}).subscribe(data => {
        this.productCount = (data && data.length) ? data.length : 0;
        this.triggerScroll();
      });
    }*/
  }

  chooseUser(user) {
    this.selectedUser = user;
    StorageService.setItem('selectedUser', user);
    this.modalService.dismissAll('');
    this.navigateTo('stockists/billing-section/products');
  }

  proceed(content) {
    if (this.selectedStockist) {
      if (this.cartItems && this.cartItems.length > 0) {
        let alreadyStoredStockist = StorageService.getItem('selectedStockist');
        if (parseInt(alreadyStoredStockist.user_id) !== parseInt(this.selectedStockist)) {
          this.modalService.open(content);
        } else {
          this.showProducts();
        }
      } else {
        this.showProducts();
      }
    } else {
      this.alertService.showErrors('Something went wrong, please refresh and try again');
    }
  }

  showProducts() {
    if (this.stockists && this.stockists.length > 0) {
      this.stockists.forEach(stockist => {
        if (parseInt(stockist.user_id) === parseInt(this.selectedStockist)) {
          StorageService.setItem('selectedStockist', stockist);
        }
      });
    }
    this.router.navigateByUrl('/users/eshop/products')
  }

  yes() {
    StorageService.removeItem('cartItems');
    if (this.stockists && this.stockists.length > 0) {
      this.stockists.forEach(stockist => {
        if (parseInt(stockist.user_id) === parseInt(this.selectedStockist)) {
          StorageService.setItem('selectedStockist', stockist);
        }
      });
    }
    this.globalService.changeCartCount([]);
    this.modalService.dismissAll('');
    this.router.navigateByUrl('/users/eshop/products')
  }

  back() {
    this._location.back();
  }

  navigateTo(url) {
    this.router.navigateByUrl(url);
  }

  triggerScroll() {
    window.scrollTo(500, 500);
    window.scrollTo(500, 0);
  }
}
