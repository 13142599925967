<section>
  <div class="row">
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
      <div class="card gradient-purple-love">
        <div class="card-content">
          <div class="card-body py-0">
            <div class="media pb-1">
              <div class="media-body white text-left">
                <h5 class="white mb-0 text-capitalize">
                  <span *ngIf="dashboard && dashboard.name">Name - {{dashboard?.name.toLowerCase()}}</span>
                </h5>
                <h5 class="white mb-0 mt-2 text-capitalize">
                  <span *ngIf="dashboard && dashboard.sponsor">Sponsor - {{dashboard?.sponsor.toLowerCase()}}</span>
                </h5>
                <h5 class="white mb-0 mt-2 text-capitalize">
                  <span *ngIf="dashboard && dashboard.username">Username - {{dashboard?.username.toLowerCase()}}</span>
                </h5>
              </div>
              <div class="media-right white text-right">
                <i class="fas fa-info-circle font-large-1"></i>
              </div>
            </div>
          </div>
          <div class="row p-2">
            <div class="col-12 text-center">
              <small class="color-white">
                Information
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
      <div class="card gradient-ibiza-sunset">
        <div class="card-content">
          <div class="card-body py-0">
            <div class="media pb-1">
              <div class="media-body white text-left text-capitalize">
                <h3 class="white mb-0 mt-42px" *ngIf="dashboard && dashboard.package">
                  {{dashboard?.package.toLowerCase()}}
                </h3>
              </div>
              <div class="media-right white text-right">
                <i class="fas fa-star font-large-1"></i>
              </div>
            </div>
          </div>
          <div class="row p-2 mt-2">
            <div class="col-12 text-center">
              <small class="color-white">
                Package
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
      <div class="card gradient-mint">
        <div class="card-content">
          <div class="card-body py-0">
            <div class="media pb-1">
              <div class="media-body white text-left text-capitalize">
                <h3 class="white mb-0 mt-42px" *ngIf="dashboard && dashboard.rank">
                  {{dashboard?.rank.toLowerCase()}}
                </h3>
              </div>
              <div class="media-right white text-right">
                <i class="fas fa-certificate font-large-1"></i>
              </div>
            </div>
          </div>
          <div class="row p-2 mt-2">
            <div class="col-12 text-center">
              <small class="color-white">
                Rank
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
      <div class="card">
        <div class="card-content">
          <div class="card-body">
            <div class="media">
              <div class="media-body text-left" *ngIf="dashboard && dashboard.total_earning">
                <h3 class="mb-1 success">{{dashboard?.total_earning}}</h3>
                <span>TOTAL EARNING</span>
              </div>
              <div class="media-right align-self-center">
                <i class="fas fa-chart-line success font-large-2 float-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
      <div class="card">
        <div class="card-content">
          <div class="card-body">
            <div class="media">
              <div class="media-body text-left" *ngIf="dashboard && dashboard.hasOwnProperty('today_earning')">
                <h3 class="mb-1 success">{{dashboard?.today_earning}}</h3>
                <span>TODAY'S EARNING</span>
              </div>
              <div class="media-right align-self-center">
                <i class="fas fa-chart-line success font-large-2 float-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
      <div class="card">
        <div class="card-content">
          <div class="card-body">
            <div class="media">
              <div class="media-body text-left" *ngIf="dashboard && dashboard.hasOwnProperty('last_week_earning')">
                <h3 class="mb-1 success">{{dashboard?.last_week_earning}}</h3>
                <span>LAST WEEK’S EARNINGS</span>
              </div>
              <div class="media-right align-self-center">
                <i class="fas fa-chart-line success font-large-2 float-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
      <div class="card">
        <div class="card-content">
          <div class="card-body">
            <div class="media">
              <div class="media-body text-left" *ngIf="dashboard && dashboard.hasOwnProperty('this_week_earning')">
                <h3 class="mb-1 success">{{dashboard?.this_week_earning}}</h3>
                <span>THIS WEEK’S EARNINGS</span>
              </div>
              <div class="media-right align-self-center">
                <i class="fas fa-chart-line success font-large-2 float-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
      <div class="card">
        <div class="card-content">
          <div class="card-body">
            <div class="media">
              <div class="media-body text-left" *ngIf="dashboard && dashboard.hasOwnProperty('director_bonus')">
                <h3 class="mb-1 success">{{dashboard?.director_bonus}}</h3>
                <span>DIRECTOR'S BONUS</span>
              </div>
              <div class="media-right align-self-center">
                <i class="fas fa-chart-line success font-large-2 float-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
      <div class="card">
        <div class="card-content">
          <div class="card-body">
            <div class="media">
              <div class="media-body text-left" *ngIf="dashboard && dashboard.hasOwnProperty('pairing_bonus')">
                <h3 class="mb-1 success">{{dashboard?.pairing_bonus}}</h3>
                <span>PAIRING BONUS</span>
              </div>
              <div class="media-right align-self-center">
                <i class="fas fa-chart-line success font-large-2 float-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
      <div class="card">
        <div class="card-content">
          <div class="card-body">
            <div class="media">
              <div class="media-body text-left" *ngIf="dashboard && dashboard.hasOwnProperty('self_purchase_bonus')">
                <h3 class="mb-1 success">{{dashboard?.self_purchase_bonus}}</h3>
                <span>SELF REPURCHASE BONUS</span>
              </div>
              <div class="media-right align-self-center">
                <i class="fas fa-chart-line success font-large-2 float-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
      <div class="card">
        <div class="card-content">
          <div class="card-body">
            <div class="media">
              <div class="media-body text-left" *ngIf="dashboard && dashboard.hasOwnProperty('performance_bonus')">
                <h3 class="mb-1 success">{{dashboard?.performance_bonus}}</h3>
                <span>PERFORMANCE BONUS</span>
              </div>
              <div class="media-right align-self-center">
                <i class="fas fa-chart-line success font-large-2 float-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
      <div class="card">
        <div class="card-content">
          <div class="card-body">
            <div class="media">
              <div class="media-body text-left" *ngIf="dashboard && dashboard.hasOwnProperty('leadership_bonus')">
                <h3 class="mb-1 success">{{dashboard?.leadership_bonus}}</h3>
                <span>LEADERSHIP BONUS</span>
              </div>
              <div class="media-right align-self-center">
                <i class="fas fa-chart-line success font-large-2 float-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
      <div class="card">
        <div class="card-content">
          <div class="card-body">
            <div class="media">
              <div class="media-body text-left" *ngIf="dashboard && dashboard.hasOwnProperty('car_fund_bonus')">
                <h3 class="mb-1 success">{{dashboard?.car_fund_bonus}}</h3>
                <span>CAR FUND BONUS</span>
              </div>
              <div class="media-right align-self-center">
                <i class="fas fa-chart-line success font-large-2 float-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
      <div class="card">
        <div class="card-content">
          <div class="card-body">
            <div class="media">
              <div class="media-body text-left" *ngIf="dashboard && dashboard.hasOwnProperty('tour_fund_bonus')">
                <h3 class="mb-1 success">{{dashboard?.tour_fund_bonus}}</h3>
                <span>TOUR FUND BONUS</span>
              </div>
              <div class="media-right align-self-center">
                <i class="fas fa-chart-line success font-large-2 float-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
      <div class="card">
        <div class="card-content">
          <div class="card-body">
            <div class="media">
              <div class="media-body text-left" *ngIf="dashboard && dashboard.hasOwnProperty('house_fund_bonus')">
                <h3 class="mb-1 success">{{dashboard?.house_fund_bonus}}</h3>
                <span>HOUSE FUND BONUS</span>
              </div>
              <div class="media-right align-self-center">
                <i class="fas fa-chart-line success font-large-2 float-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
      <div class="card">
        <div class="card-content">
          <div class="card-body">
            <div class="media">
              <div class="media-body text-left" *ngIf="dashboard && dashboard.hasOwnProperty('income_wallet')">
                <h3 class="mb-1 success">{{dashboard?.income_wallet}}</h3>
                <span>INCOME WALLET</span>
              </div>
              <div class="media-right align-self-center">
                <i class="fas fa-chart-line success font-large-2 float-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
      <div class="card">
        <div class="card-content">
          <div class="card-body">
            <div class="media">
              <div class="media-body text-left" *ngIf="dashboard && dashboard.hasOwnProperty('activation_wallet')">
                <h3 class="mb-1 success">{{dashboard?.activation_wallet}}</h3>
                <span>ACTIVATION WALLET</span>
              </div>
              <div class="media-right align-self-center">
                <i class="fas fa-chart-line success font-large-2 float-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
      <div class="card">
        <div class="card-content">
          <div class="card-body">
            <div class="media">
              <div class="media-body text-left" *ngIf="dashboard && dashboard.hasOwnProperty('repurchase_slab')">
                <h3 class="mb-1 success">{{dashboard?.repurchase_slab}}</h3>
                <span>REPURCHASE SLAB(%)</span>
              </div>
              <div class="media-right align-self-center">
                <i class="fas fa-chart-line success font-large-2 float-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
      <div class="card">
        <div class="card-content">
          <div class="card-body">
            <div class="media">
              <div class="media-body text-left" *ngIf="dashboard && dashboard.hasOwnProperty('self_repurchase_bvs')">
                <h3 class="mb-1 success">{{dashboard?.self_repurchase_bvs}}</h3>
                <span>SELF REPURCHASE BV'S</span>
              </div>
              <div class="media-right align-self-center">
                <i class="fas fa-chart-line success font-large-2 float-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
      <div class="card">
        <div class="card-content">
          <div class="card-body">
            <div class="media">
              <div class="media-body text-left" *ngIf="dashboard && dashboard.hasOwnProperty('team_repurchase_bvs')">
                <h3 class="mb-1 success">{{dashboard?.team_repurchase_bvs}}</h3>
                <span>TEAM REPURCHASE BV'S</span>
              </div>
              <div class="media-right align-self-center">
                <i class="fas fa-chart-line success font-large-2 float-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
      <div class="card">
        <div class="card-content">
          <div class="card-body">
            <div class="media mt--21px">
              <div class="media-body text-left" *ngIf="dashboard && dashboard.hasOwnProperty('current_month_team_repurchase_bvs')">
                <h3 class="mb-1 success">{{dashboard?.current_month_team_repurchase_bvs}}</h3>
                <span>CURRENT MONTH TEAM REPURCHASE BV'S</span>
              </div>
              <div class="media-right align-self-center">
                <i class="fas fa-chart-line success font-large-2 float-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
      <div class="card">
        <div class="card-content">
          <div class="card-body">
            <div class="media mt--21px">
              <div class="media-body text-left" *ngIf="dashboard && dashboard.hasOwnProperty('current_month_self_repurchase_bvs')">
                <h3 class="mb-1 success">{{dashboard?.current_month_self_repurchase_bvs}}</h3>
                <span>CURRENT MONTH SELF REPURCHASE BV'S</span>
              </div>
              <div class="media-right align-self-center">
                <i class="fas fa-chart-line success font-large-2 float-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
      <div class="card">
        <div class="card-content">
          <div class="card-body">
            <div class="media">
              <div class="media-body text-left" *ngIf="dashboard && dashboard.hasOwnProperty('left_team_right_team')">
                <h3 class="mb-1 success">{{dashboard?.left_team_right_team}}</h3>
                <span>LEFT TEAM & RIGHT TEAM</span>
              </div>
              <div class="media-right align-self-center">
                <i class="fas fa-chart-line success font-large-2 float-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
      <div class="card">
        <div class="card-content">
          <div class="card-body">
            <div class="media">
              <div class="media-body text-left" *ngIf="dashboard && dashboard.hasOwnProperty('direct_sponsered_left_right')">
                <h3 class="mb-1 success">{{dashboard?.direct_sponsered_left_right}}</h3>
                <span>DIRECT SPONSORED LEFT & RIGHT</span>
              </div>
              <div class="media-right align-self-center">
                <i class="fas fa-chart-line success font-large-2 float-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
      <div class="card">
        <div class="card-content">
          <div class="card-body">
            <div class="media mt--21px">
              <div class="media-body text-left" *ngIf="dashboard && dashboard.hasOwnProperty('accumulated_total_left_pvs_right_bvs')">
                <h3 class="mb-1 success">{{dashboard?.accumulated_total_left_pvs_right_bvs}}</h3>
                <span>ACCUMULATED TOTAL LEFT PV'S & RIGHT PV'S</span>
              </div>
              <div class="media-right align-self-center">
                <i class="fas fa-chart-line success font-large-2 float-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
      <div class="card">
        <div class="card-content">
          <div class="card-body">
            <div class="media">
              <div class="media-body text-left" *ngIf="dashboard && dashboard.hasOwnProperty('pending_pvs_left_right_pvs')">
                <h3 class="mb-1 success">{{dashboard?.pending_pvs_left_right_pvs}}</h3>
                <span>PENDING PV'S LEFT & PV'S</span>
              </div>
              <div class="media-right align-self-center">
                <i class="fas fa-chart-line success font-large-2 float-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
