<section>
  <div class="row mt-2 mb-2">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="content-header f_20 module-header ml-2 mt-18px text-capitalize">Announcement - {{announcementDetail?.news_name.toLowerCase()}}</div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="card min-vh-50 text-center">
        <pre class="mt-3">{{announcementDetail?.description}}</pre>
        <div class="text-center pb-5 mt-2">
          <img src="{{announcementDetail?.promo}}" width="50%"/>
        </div>
      </div>
    </div>
  </div>
</section>
