import {Component, OnInit} from '@angular/core';
import {DeliveredInvoicesUserPackageService} from '../../../shared/services/delivered-invoices-user-package.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import * as moment from 'moment';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-delivered-invoices',
  templateUrl: './delivered-invoices.component.html',
  styleUrls: ['./delivered-invoices.component.scss']
})

export class StockistDeliveredInvoicesUserPackageComponent implements OnInit {
  submitting = false;
  deliveredInvoices = [];
  showSearch: any = false;
  searchForm: FormGroup;
  bsConfig: Partial<BsDatepickerConfig>;
  pagination = {
    page: 1,
    total: null,
    perpage: 15,
    pages: null
  };

  constructor(public deliveredInvoicesService: DeliveredInvoicesUserPackageService,
              private fb: FormBuilder) {
    this.bsConfig = Object.assign({}, {containerClass: 'theme-dark-blue', dateInputFormat: 'YYYY-MM-DD'}, {showWeekNumbers: false});
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    this.searchForm = this.fb.group({
      'user_id': [''],
      'invoice_no': [''],
      'total_amount': [''],
      'date': ['']
    });
    this.get();
  }

  get(params: any = {}) {
    params.page = this.pagination.page;
    params.perpage = this.pagination.perpage;
    this.submitting = true;
    this.deliveredInvoices = [];
    this.deliveredInvoicesService.get(params).subscribe(data => {
      if (data && data['data'] && data['data'].length > 0) {
        this.deliveredInvoices = data['data'];
        this.pagination = {
          page: data['pagination'].page,
          total: data['pagination'].total,
          perpage: data['pagination'].perpage,
          pages: data['pagination'].pages,
        };
        this.triggerScroll();
      } else {
        this.deliveredInvoices = [];
      }
      this.submitting = false;
    }, error => {
      this.submitting = false;
    });
  }

  navigateTo(data) {
    window.open(data.invoice_url, '_blank')
  }

  markInvoice(event) {
    console.log('event', event.target.value);
  }

  triggerScroll() {
    window.scrollTo(500, 500);
    window.scrollTo(500, 0);
  }

  onPageChange(page) {
    this.pagination.page = page;
    this.get();
  }

  search() {
    this.pagination = {
      page: 1,
      total: null,
      perpage: 15,
      pages: null
    };
    let params = {
      'user_id': (this.searchForm && this.searchForm.value) ? this.searchForm.value.user_id : '',
      'invoice_no': (this.searchForm && this.searchForm.value) ? this.searchForm.value.invoice_no : '',
      'total_amount': (this.searchForm && this.searchForm.value) ? this.searchForm.value.total_amount : '',
      'date': (this.searchForm && this.searchForm.value) ? moment(this.searchForm.value.date).format('YYYY-MM-DD') : ''
    };
    this.get(params);
  }
}
