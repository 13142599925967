import {Component, OnInit} from '@angular/core';
import {SelfRepurchaseBonusService} from '../../../shared/services/self-repurchase-bonus.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-self-repurchase-bonus',
  templateUrl: './self-repurchase-bonus.component.html',
  styleUrls: ['./self-repurchase-bonus.component.scss']
})

export class SelfRepurchaseBonusComponent implements OnInit {
  submitting = false;
  selfRepurchaseBonus = [];
  showSearch: any = false;
  searchForm: FormGroup;
  bsConfig: Partial<BsDatepickerConfig>;
  total: any = 0;
  pagination = {
    page: 1,
    total: null,
    perpage: 15,
    pages: null
  };

  constructor(public selfRepurchaseBonusService: SelfRepurchaseBonusService,
              private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    this.searchForm = this.fb.group({
      'transaction_no': [''],
      'commission': [''],
      'tds': [''],
      'total_purchase': [''],
      'slab': ['']
    });
    this.get();
  }

  get(params: any = {}) {
    params.page = this.pagination.page;
    params.perpage = this.pagination.perpage;
    this.submitting = true;
    this.selfRepurchaseBonus = [];
    this.selfRepurchaseBonusService.get(params).subscribe(data => {
      if (data && data['data'] && data['data'].length > 0) {
        this.selfRepurchaseBonus = data['data'];
        this.pagination = {
          page: data['pagination'].page,
          total: data['pagination'].total,
          perpage: data['pagination'].perpage,
          pages: data['pagination'].pages,
        };
        this.total = data.total_commission;
        this.triggerScroll();
      } else {
        this.selfRepurchaseBonus = [];
      }
      this.submitting = false;
    }, error => {
      this.submitting = false;
    });
  }

  triggerScroll() {
    window.scrollTo(500, 500);
    window.scrollTo(500, 0);
  }

  onPageChange(page) {
    this.pagination.page = page;
    this.get();
  }

  search() {
    this.pagination = {
      page: 1,
      total: null,
      perpage: 15,
      pages: null
    };
    let params = {
      'transaction_no': (this.searchForm && this.searchForm.value) ? this.searchForm.value.transaction_no : '',
      'commission': (this.searchForm && this.searchForm.value) ? this.searchForm.value.commission : '',
      'tds': (this.searchForm && this.searchForm.value) ? this.searchForm.value.tds : '',
      'total_purchase': (this.searchForm && this.searchForm.value) ? this.searchForm.value.total_purchase : '',
      'slab': (this.searchForm && this.searchForm.value) ? this.searchForm.value.slab : ''
    };
    this.get(params);
  }
}
