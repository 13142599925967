<section>
  <div class="row mt-2 mb-2">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="content-header f_20 module-header ml-2 mt-18px">View Ticket Responses</div>
    </div>
  </div>
  <div class="row p-sm-2 d-none d-md-block overflow-x-scroll">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <table class="table table-list overflow-x-scroll">
        <thead>
        <tr>
          <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">S.No</h6></th>
          <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Name</h6></th>
          <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Ticket No.</h6></th>
          <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Subject</h6></th>
          <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Category</h6></th>
          <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Request Date</h6></th>
          <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Status</h6></th>
          <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Query</h6></th>
          <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Response Date</h6></th>
          <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Response</h6></th>
        </tr>
        </thead>
        <tbody *ngIf="viewTicketResponses && viewTicketResponses.length > 0; else noDataToShowWeb">
        <tr *ngFor="let viewTicket of viewTicketResponses; let i = index">
          <td class="text-center">
            <h6 class="mt-1">{{i + 1}}.</h6>
          </td>
          <td class="text-center"><h6 class="mt-1">{{viewTicket?.name}}</h6></td>
          <td class="text-center"><h6 class="mt-1">{{viewTicket?.ticket_no}}</h6></td>
          <td class="text-center"><h6 class="mt-1">{{viewTicket?.subject}}</h6></td>
          <td class="text-center"><h6 class="mt-1">{{viewTicket?.category}}</h6></td>
          <td class="text-center"><h6 class="mt-1">{{viewTicket?.request_date}}</h6></td>
          <td class="text-center"><h6 class="mt-1">{{viewTicket?.status}}</h6></td>
          <td class="text-center"><h6 class="mt-1">{{viewTicket?.query}}</h6></td>
          <td class="text-center"><h6 class="mt-1">{{viewTicket?.response_date}}</h6></td>
          <td class="text-center"><h6 class="mt-1">{{viewTicket?.response}}</h6></td>
        </tr>
        </tbody>
        <ng-template #noDataToShowWeb>
          <tbody>
          <tr>
            <td colspan="10" class="text-center"><h6>No data to show</h6></td>
          </tr>
          </tbody>
        </ng-template>
      </table>
    </div>
  </div>
  <div class="row d-block d-md-none"
       *ngIf="viewTicketResponses && viewTicketResponses.length > 0; else noDataToShowMobile">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="card" *ngFor="let viewTicket of viewTicketResponses; let i = index">
        <div class="card-header bottom-shadow-light pb-18px">
          <div class="row">
            <div class="col-12">
              <h4
                class="card-title text-uppercase card-ellipsis pb-3">{{viewTicket?.name}}</h4>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row p-2">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
              Ticket No. - {{viewTicket?.ticket_no}}
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
              Subject - {{viewTicket?.subject}}
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
              Category - {{viewTicket?.category}}
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
              Request Date - {{viewTicket?.request_date}}
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
              Status - {{viewTicket?.status}}
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
              Query - {{viewTicket?.query}}
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
              Response Date - {{viewTicket?.response_date}}
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
              Response - {{viewTicket?.response}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #noDataToShowMobile>
    <div class="row d-block d-md-none">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card text-center">
          <h5 class="p-5">No data to show</h5>
        </div>
      </div>
    </div>
  </ng-template>
</section>
