<section>
  <div class="row mt-2 mb-2">
    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
      <div class="content-header f_20 module-header ml-2 mt-18px">Downline Members</div>
    </div>
    <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
      <button class="btn btn-primary pull-right color-white mt-2 color-white users-btn mr-2" (click)="showSearch = !showSearch">
        <i aria-hidden="true" class="fa fa-search mr-2"></i>Search
      </button>
    </div>
  </div>
  <div class="row mt-2 mb-2" *ngIf="showSearch">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <form class="form background-white ml-15px mr-15px" novalidate [formGroup]="searchForm">
        <div class="row">
          <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12">
            <div class="form-group ml-2 pt-3 pb-1 mt--5px">
              <input class="form-control" id="user_id" type="text" formControlName="user_id" placeholder="User Id">
            </div>
          </div>
          <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12">
            <div class="form-group ml-2 pt-3 pb-1 mt--5px">
              <input class="form-control" id="package" type="text" formControlName="package" placeholder="Package">
            </div>
          </div>
          <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12">
            <div class="form-group ml-2 pt-3 pb-1 mt--5px">
              <input class="form-control" id="level" type="text" formControlName="level" placeholder="Level">
            </div>
          </div>
          <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12">
            <div class="form-group ml-2 pt-3 pb-1 mt--5px">
              <input class="form-control" id="sponsor" type="text" formControlName="sponsor" placeholder="Sponsor">
            </div>
          </div>
          <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12">
            <div class="form-group ml-2 pt-3 pb-1 mt--5px">
              <input class="form-control" id="registration_date" formControlName="registration_date" placeholder="Registration Date" [bsConfig]="bsConfig" bsDatepicker>
            </div>
          </div>
          <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 text-center">
            <button class="btn btn-primary btn-block btn-raised color-white mt-16px ml--10px" (click)="search()">Search</button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="row p-sm-2 d-none d-md-block overflow-x-scroll">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <table class="table table-list overflow-x-scroll">
        <thead>
        <tr>
          <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">S.No</h6></th>
          <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">User Id</h6></th>
          <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Package</h6></th>
          <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Level</h6></th>
          <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Position</h6></th>
          <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Sponsor</h6></th>
          <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Rank</h6></th>
          <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Registration Date</h6></th>
        </tr>
        </thead>
        <tbody *ngIf="downlineMembers && downlineMembers.length > 0; else noDataToShowWeb">
        <tr *ngFor="let downlineMember of downlineMembers; let i = index">
          <td class="text-center">
            <h6 class="mt-1">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}.</h6>
          </td>
          <td class="text-center"><h6 class="mt-1">{{downlineMember?.user_id}}</h6></td>
          <td class="text-center"><h6 class="mt-1 text-capitalize">{{downlineMember?.package.toLowerCase()}}</h6></td>
          <td class="text-center"><h6 class="mt-1 text-capitalize">{{downlineMember?.level}}</h6></td>
          <td class="text-center"><h6 class="mt-1 text-capitalize">{{downlineMember?.position}}</h6></td>
          <td class="text-center"><h6 class="mt-1 text-capitalize">{{downlineMember?.sponsor}}</h6></td>
          <td class="text-center"><h6 class="mt-1 text-capitalize">{{downlineMember?.rank}}</h6></td>
          <td class="text-center"><h6 class="mt-1">{{downlineMember?.registration_date}}</h6></td>
        </tr>
        </tbody>
        <ng-template #noDataToShowWeb>
          <tbody>
          <tr>
            <td colspan="8" class="text-center"><h6>No data to show</h6></td>
          </tr>
          </tbody>
        </ng-template>
      </table>
    </div>
  </div>

  <div class="row d-block d-md-none" *ngIf="downlineMembers && downlineMembers.length > 0; else noDataToShowMobile">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="card" *ngFor="let directReferral of downlineMembers; let i = index">
        <div class="card-header bottom-shadow-light pb-18px">
          <div class="row">
            <div class="col-12">
              <h4 class="card-title text-uppercase card-ellipsis pb-3">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}. {{directReferral?.user_id}}</h4>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row p-2">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
              Package - {{directReferral?.package}}
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
              Level - {{directReferral?.level}}
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
              Position - {{directReferral?.position}}
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
              Sponsor - {{directReferral?.sponsor}}
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
              Rank - {{directReferral?.rank}}
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
              Registration Date - {{directReferral?.registration_date}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #noDataToShowMobile>
    <div class="row d-block d-md-none">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card text-center">
          <h5 class="p-5">No data to show</h5>
        </div>
      </div>
    </div>
  </ng-template>

  <div class="row pagination-table">
    <ngb-pagination (pageChange)="onPageChange($event)"
                    [(page)]="pagination.page"
                    [boundaryLinks]="true"
                    [collectionSize]="pagination.total"
                    [maxSize]="3"
                    [pageSize]="pagination.perpage">
    </ngb-pagination>
  </div>
</section>
