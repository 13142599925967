<!--Login Page Starts-->
<section id="login">
  <div class="row auth-height full-height-vh m-0">
    <div class="col-12 d-flex align-items-center justify-content-center">
      <div class="card overflow-hidden">
        <div class="card-content">
          <div class="card-body auth-img">
            <div class="row m-0">
              <div class="col-lg-6 d-none d-lg-flex justify-content-center align-items-center auth-img-bg p-3">
                <img src="assets/img/gallery/login.png" alt="" class="img-fluid" width="300" height="230">
              </div>
              <div class="col-lg-6 col-12 px-4 py-3">
                <div class="col-12 mt-2 mb-2 text-center">
                  <img src="assets/img/logo.png" style="width: 100px"/>
                </div>
                <div class="col-12 mt-2 mb-2">
                  <nav class="nav nav-pills nav-justified mt-3">
                    <a class="nav-item nav-link" [ngClass]="{'active': loginType === 'USER'}"
                       (click)="loginType = 'USER'">User</a>
                    <a class="nav-item nav-link" [ngClass]="{'active': loginType === 'STOCKIST'}"
                       (click)="loginType = 'STOCKIST'">Stockist</a>
                  </nav>
                </div>
                <div class="col-12">
                  <p>Welcome back - {{loginType.toLowerCase()}}, please login to your account.</p>
                  <form [formGroup]="loginUserForm">
                    <div class="form-group">
                      <input type="text" formControlName="username" class="form-control" placeholder="Username">
                    </div>
                    <div class="form-group">
                      <input type="password" formControlName="password" class="form-control" placeholder="Password">
                    </div>
                    <div class="d-sm-flex justify-content-between mb-3 font-small-2">
                      <div class="remember-me mb-2 mb-sm-0">
                        <div class="checkbox auth-checkbox">
                          <input type="checkbox" class="form-control" id="rememberMe">
                          <label for="rememberMe"><span
                            class="font-small-2 mb-3 font-weight-normal">Remember Me</span></label>
                        </div>
                      </div>
                      <a href="javascript:">Forgot Password?</a>
                    </div>
                    <div class="col-12 text-center">
                      <button class="btn btn-primary text-capitalize" (click)="loginUser(loginType)">{{loginType.toLowerCase()}} Login</button>
                    </div>
                    <!--<div class="d-flex justify-content-end flex-sm-row flex-column">
                      <a href="javascript:" class="btn bg-light-primary mb-2 mb-sm-0">Register</a>
                      <a class="btn btn-primary" (click)="loginUser()">Login</a>
                    </div>-->
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--Login Page Ends-->
