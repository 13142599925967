import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertService} from '../../../shared/services/alert.service';
import {Location} from '@angular/common';
import {ManageProfileService} from '../../../shared/services/manage-profile.service';
import {StateService} from '../../../shared/services/state.service';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-manage-profile',
  templateUrl: './manage-profile.component.html',
  styleUrls: ['./manage-profile.component.scss']
})
export class ManageProfileComponent implements OnInit {
  personalInformationForm: FormGroup;
  submitted: any = false;
  submitting: any = false;
  states: any = [];
  genders: any = [
    {
      'name': 'Male',
      'value': 'Male'
    },
    {
      'name': 'Female',
      'value': 'Female'
    }
  ];
  bsConfig: Partial<BsDatepickerConfig>;
  profileImage: any;
  onFileChanged: any;
  fileSelected: any;

  constructor(private router: Router,
              private fb: FormBuilder,
              public activatedRoute: ActivatedRoute,
              private alertService: AlertService,
              private _location: Location,
              private manageProfileService: ManageProfileService,
              private stateService: StateService) {
    this.bsConfig = Object.assign({}, {containerClass: 'theme-dark-blue', dateInputFormat: 'YYYY-MM-DD'}, {showWeekNumbers: false});
  }

  ngOnInit() {
    this.getProfileImage();
    this.getStates();
    this.refresh();
  }

  refresh() {
    this.personalInformationForm = this.fb.group({
      'first_name': [''],
      'last_name': [''],
      'user_id': [{value: '', 'disabled': true}],
      'username': [{value: '', 'disabled': true}],
      'email': [''],
      'dob': [''],
      'sex': [''],
      'telephone': [''],
      'whatsapp': [''],
      'telegram': [''],
      'address': [''],
      'city': [''],
      'state': [''],
      'aboutus': [''],
      'passport': [''],
      'gst_no': ['']
    });
    this.get();
  }

  getStates() {
    this.submitting = true;
    this.stateService.get({'country_id': 101}).subscribe(data => {
      this.states = data;
      this.submitting = false;
    }, error => {
      this.submitting = false;
    });
  }

  getProfileImage() {
    this.submitting = true;
    this.manageProfileService.getProfileImage({}).subscribe(data => {
      if (data && data.image) {
        this.profileImage = data.image;
      }
      this.submitting = false;
    }, error => {
      this.submitting = false;
    });
  }

  get(params: any = {}) {
    this.submitting = true;
    this.manageProfileService.get(params).subscribe(data => {
      this.patchValues(data);
      this.submitting = false;
    }, error => {
      this.submitting = false;
    });
  }

  patchValues(data) {
    this.personalInformationForm.patchValue({
      'first_name': (data && data.first_name) ? data.first_name : '',
      'last_name': (data && data.last_name) ? data.last_name : '',
      'user_id': (data && data.user_id) ? data.user_id : '',
      'username': (data && data.username) ? data.username : '',
      'email': (data && data.email) ? data.email : '',
      'dob': (data && data.dob) ? data.dob : '',
      'sex': (data && data.sex) ? data.sex : '',
      'telephone': (data && data.telephone) ? data.telephone : '',
      'whatsapp': (data && data.whatsapp) ? data.whatsapp : '',
      'telegram': (data && data.telegram) ? data.telegram : '',
      'address': (data && data.address) ? data.address : '',
      'city': (data && data.city) ? data.city : '',
      'state': (data && data.state) ? data.state : '',
      'aboutus': (data && data.aboutus) ? data.aboutus : '',
      'passport': (data && data.passport) ? data.passport : '',
      'gst_no': (data && data.gst_no) ? data.gst_no : ''
    });
  }

  back() {
    this._location.back();
  }

  update() {
    this.submitted = true;
    if (!this.personalInformationForm.valid) {
      return;
    }
    if (this.submitted) {
      const params = {
        'first_name': (this.personalInformationForm.value && this.personalInformationForm.value.first_name) ? this.personalInformationForm.value.first_name : '',
        'last_name': (this.personalInformationForm.value && this.personalInformationForm.value.last_name) ? this.personalInformationForm.value.last_name : '',
        'user_id': (this.personalInformationForm.value && this.personalInformationForm.value.user_id) ? this.personalInformationForm.value.user_id : '',
        'username': (this.personalInformationForm.value && this.personalInformationForm.value.username) ? this.personalInformationForm.value.username : '',
        'email': (this.personalInformationForm.value && this.personalInformationForm.value.email) ? this.personalInformationForm.value.email : '',
        'dob': (this.personalInformationForm.value && this.personalInformationForm.value.dob) ? this.personalInformationForm.value.dob : '',
        'sex': (this.personalInformationForm.value && this.personalInformationForm.value.sex) ? this.personalInformationForm.value.sex : '',
        'telephone': (this.personalInformationForm.value && this.personalInformationForm.value.telephone) ? this.personalInformationForm.value.telephone : '',
        'whatsapp': (this.personalInformationForm.value && this.personalInformationForm.value.whatsapp) ? this.personalInformationForm.value.whatsapp : '',
        'telegram': (this.personalInformationForm.value && this.personalInformationForm.value.telegram) ? this.personalInformationForm.value.telegram : '',
        'address': (this.personalInformationForm.value && this.personalInformationForm.value.address) ? this.personalInformationForm.value.address : '',
        'city': (this.personalInformationForm.value && this.personalInformationForm.value.city) ? this.personalInformationForm.value.city : '',
        'state': (this.personalInformationForm.value && this.personalInformationForm.value.state) ? this.personalInformationForm.value.state : '',
        'aboutus': (this.personalInformationForm.value && this.personalInformationForm.value.aboutus) ? this.personalInformationForm.value.aboutus : '',
        'passport': (this.personalInformationForm.value && this.personalInformationForm.value.passport) ? this.personalInformationForm.value.passport : '',
        'gst_no': (this.personalInformationForm.value && this.personalInformationForm.value.gst_no) ? this.personalInformationForm.value.gst_no : ''
      };
      this.submitting = true;
      this.manageProfileService.update(params).subscribe(data => {
        this.submitting = false;
        this.submitted = false;
        this.triggerScroll();
      }, error => {
        this.submitting = false;
        this.submitted = false;
      });
    }
  }

  triggerScroll() {
    window.scrollTo(500, 500);
    window.scrollTo(500, 0);
  }

  onFileChange(e) {
    this.onFileChanged = e;
    this.fileSelected = true;
    this.uploadImage();
  }

  uploadImage() {
    this.submitting = true;
    this.manageProfileService.userImageUpload({'fileType': 'NORMAL'}, this.onFileChanged).subscribe(data => {
      this.submitting = false;
      this.submitted = false;
      this.getProfileImage();
    }, error => {
      this.submitting = false;
      this.submitted = false;
    });
  }
}
