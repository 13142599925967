<section>
  <div class="row" *ngIf="invoiceUrl">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="card pt-5 pb-5">
        <div class="card-body mt-1">
          <div class="row mb-2">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center pt-5 pb-5">
              <i class="fas fa-check-circle f-50px text-success mb-2"></i>
              <h3>Success</h3>
              <h5>Yay! You've successfully placed your order. Your products will be delivered soon.</h5>
              <h5>Thank you for shopping with us !</h5>
              <button class="btn btn-outline-success mt-3" (click)="navigateTo()">View Invoice</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
