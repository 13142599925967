import {Component, OnInit} from '@angular/core';
import {MyPackageInvoicesService} from 'app/shared/services/my-package-invoices.service';

@Component({
  selector: 'app-my-package-invoices',
  templateUrl: './my-package-invoices.component.html',
  styleUrls: ['./my-package-invoices.component.scss']
})
export class MyPackageInvoicesComponent implements OnInit {
  submitting = false;
  myPackageInvoices = [];
  total: any = 0;
  pagination = {
    page: 1,
    total: null,
    perpage: 15,
    pages: null
  };

  constructor(public myPackageInvoicesService: MyPackageInvoicesService) {
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    this.get();
  }

  get(params: any = {}) {
    params.page = this.pagination.page;
    params.perpage = this.pagination.perpage;
    this.submitting = true;
    this.myPackageInvoices = [];
    this.myPackageInvoicesService.get(params).subscribe(data => {
      if (data && data['data'] && data['data'].length > 0) {
        this.myPackageInvoices = data['data'];
        this.pagination = {
          page: data['pagination'].page,
          total: data['pagination'].total,
          perpage: data['pagination'].perpage,
          pages: data['pagination'].pages,
        };
        this.total = data.total_commission;
        this.triggerScroll();
      } else {
        this.myPackageInvoices = [];
      }
      this.submitting = false;
    }, error => {
      this.submitting = false;
    });
  }

  triggerScroll() {
    window.scrollTo(500, 500);
    window.scrollTo(500, 0);
  }

  view(data) {
    window.open(data['receipt_url'], '_blank');
  }

  onPageChange(page) {
    this.pagination.page = page;
    this.get();
  }
}
