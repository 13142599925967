<section>
  <div class="row mt-2 mb-2">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="content-header f_20 module-header ml-2 mt-18px">Transaction History</div>
    </div>
  </div>
  <div class="row p-sm-2 d-none d-md-block overflow-x-scroll">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <table class="table table-list overflow-x-scroll">
        <thead>
        <tr>
          <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">S.No</h6></th>
          <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Transaction No</h6></th>
          <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Sender Username</h6></th>
          <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Sender Id</h6></th>
          <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Credit</h6></th>
          <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Debit</h6></th>
          <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Remark</h6></th>
          <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Date</h6></th>
          <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Status</h6></th>
        </tr>
        </thead>
        <tbody *ngIf="myWalletTransactionHistory && myWalletTransactionHistory.length > 0; else noDataToShowWeb">
        <tr *ngFor="let myWalletTransaction of myWalletTransactionHistory; let i = index">
          <td class="text-center">
            <h6 class="mt-1">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}.</h6>
          </td>
          <td class="text-center"><h6 class="mt-1">{{myWalletTransaction?.transaction_no}}</h6></td>
          <td class="text-center"><h6 class="mt-1">{{myWalletTransaction?.username}}</h6></td>
          <td class="text-center"><h6 class="mt-1">{{myWalletTransaction?.sender_id}}</h6></td>
          <td class="text-center"><h6 class="mt-1">{{myWalletTransaction?.credit_amt}}</h6></td>
          <td class="text-center"><h6 class="mt-1">{{myWalletTransaction?.debit_amt}}</h6></td>
          <td class="text-center"><h6 class="mt-1">{{myWalletTransaction?.remark}}</h6></td>
          <td class="text-center"><h6 class="mt-1">{{myWalletTransaction?.date}}</h6></td>
          <td class="text-center"><h6 class="mt-1">{{myWalletTransaction?.status}}</h6></td>
        </tr>
        </tbody>
        <ng-template #noDataToShowWeb>
          <tbody>
          <tr>
            <td colspan="9" class="text-center"><h6>No data to show</h6></td>
          </tr>
          </tbody>
        </ng-template>
      </table>
    </div>
  </div>

  <div class="row d-block d-md-none" *ngIf="myWalletTransactionHistory && myWalletTransactionHistory.length > 0; else noDataToShowMobile">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="card" *ngFor="let myWalletTransaction of myWalletTransactionHistory; let i = index">
        <div class="card-header bottom-shadow-light pb-18px">
          <div class="row">
            <div class="col-12">
              <h4 class="card-title text-uppercase card-ellipsis pb-3">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}. {{myWalletTransaction?.transaction_no}}</h4>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row p-2">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
              Sender Username - {{myWalletTransaction?.username}}
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
              Sender Id - {{myWalletTransaction?.sender_id}}
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
              Credit - {{myWalletTransaction?.credit_amt}}
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
              Debit - {{myWalletTransaction?.debit_amt}}
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
              Remark - {{myWalletTransaction?.remark}}
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
              Date - {{myWalletTransaction?.remark}}
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
              Status - {{myWalletTransaction?.status}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #noDataToShowMobile>
    <div class="row d-block d-md-none">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card text-center">
          <h5 class="p-5">No data to show</h5>
        </div>
      </div>
    </div>
  </ng-template>

  <div class="row pagination-table">
    <ngb-pagination (pageChange)="onPageChange($event)"
                    [(page)]="pagination.page"
                    [boundaryLinks]="true"
                    [collectionSize]="pagination.total"
                    [maxSize]="3"
                    [pageSize]="pagination.perpage">
    </ngb-pagination>
  </div>
</section>
